import React from "react"
import PropTypes from "prop-types"
import "./forge-sha256.min.js";
class ProofOfWork extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      input: "",
      output: "",
      r: "",
      t: "",
      h: "",
      num_zeros: 6,
      status: 0,
      solution: "",
      time: 0
    };

    this.parse_input = this.parse_input.bind(this);
    this.work = this.work.bind(this);

  }

  componentDidMount(){
    this.parse_input();
  }

  parse_input(){
    console.log("PoW input:");
    console.log(this.props.input);
    if (this.props.input !== undefined && this.props.input.includes('.')){
      const [r, t, h] = this.props.input.split('.');
      console.log([r,t,h]);
      this.setState({
        input: this.props.input,
        num_zeros: Number(this.props.num_zeros),
        r: r,
        t: t,
        h: h
      }, () => {
        if (this.props.go){
          this.work();
        }
      });
    }
  }

  work(){
    this.setState({
      status: 1
    });
    setTimeout(() => {
      let w = 0, x;
      const start = performance.now();
      const hex_zeros = Math.floor(this.state.num_zeros / 4.0);
      const rem_zeros = this.state.num_zeros % 4;
      const cmp = Array(hex_zeros).fill('0').join('');
      /*console.log("hex_zeros = "+String(hex_zeros));
      console.log("rem_zeros = "+String(rem_zeros));
      console.log("cmp = "+String(cmp));*/
      do {
        /*if (w % 10000 === 0 && w != 0){
          console.log("w = "+String(w));
          console.log("x = "+x);
          console.log(x.slice(0,hex_zeros));
          console.log(cmp);
          console.log(parseInt(x[hex_zeros],16));
          console.log(parseInt(x[hex_zeros],16) >> (4 - rem_zeros));
        }*/
        w++;
        x = forge_sha256([this.state.h,String(w)].join('.'));
      } while (!( x.slice(0,hex_zeros) === cmp && parseInt(x[hex_zeros],16) >> (4 - rem_zeros) === 0 ));
      const end = performance.now();
      this.setState({
        status: 2,
        time: (end - start),
        solution: [this.state.r,this.state.t,w,this.state.h].join('.')
      }, () => {
        console.log("Obtained solution");
        console.log([this.state.r,this.state.t,w,this.state.h].join('.'));
        console.log(x);
        this.props.on_solve(this.state.solution);
      })
      //console.log("Solved! "+[this.state.r,this.state.t,w,this.state.h].join('.'));
    },250);
    //this.props.on_solve([this.state.r,this.state.t,w,this.state.h].join('.'))
  }

  componentDidUpdate(prev_props){
    if (prev_props.input !== this.props.input || prev_props.go !== this.props.go){
      this.parse_input();
    }
  }

  render () {
    return (
      <div style={{width: '260px', backgroundColor: '#efefef', border: 'solid', borderWidth: '1px', borderColor: 'grey', borderRadius: '4px', padding: '8px'}}>
        <div style={{color: 'grey', fontSize: '11px'}}>
          Proof-of-Work Engine
        </div>
        {[
          <div>
            <table><tbody>
              <tr>
                <td style={{width: '30px', textAlign: 'center', fontSize: '20px'}}>
                  <i className="fa-regular fa-circle"></i>
                </td>
                <td style={{fontSize: '14px', color: 'grey', verticalAlign: 'middle'}}>
                  Awaiting challenge.
                </td>
              </tr>
            </tbody></table>
          </div>,
          <div>
            <table><tbody>
              <tr>
                <td style={{width: '30px', textAlign: 'center', fontSize: '20px'}}>
                  <i className="fa-solid fa-gears"></i>
                </td>
                <td style={{fontSize: '14px', color: 'grey', verticalAlign: 'middle'}}>
                  Working...
                </td>
              </tr>
            </tbody></table>
          </div>,
          <div>
            <table><tbody>
              <tr>
                <td style={{width: '30px', textAlign: 'center', fontSize: '20px'}}>
                  <i className="fa-solid fa-check"></i>
                </td>
                <td style={{fontSize: '14px', color: 'grey', verticalAlign: 'middle'}}>
                  Solved challenge in {(this.state.time / 1000.0).toFixed(2)}s
                </td>
              </tr>
            </tbody></table>
          </div>
        ][this.state.status]}
      </div>
    );
  }
}

export default ProofOfWork

