import React from "react"
import PropTypes from "prop-types"
class PayorAccountSecurity extends React.Component {
  render () {
    return (
      <div>
        Hello! PayorAccountSecurity
      </div>
    );
  }
}

export default PayorAccountSecurity
