import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
import PayorCreate from "./PayorCreate.js"
import LoginPayor from "./LoginPayor.js"
import Overlay from "./Overlay.js"
class NewCompany extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      typed_name:"",
      typed_name_status: 0,
      typed_name_error: "",
      have_abn: true,
      typed_abn:"",
      typed_abn_status: 0,
      typed_abn_error: "",
      abn_type: 0, // 0 - ABN
      abn_result: null,
      typed_handle: "",
      typed_handle_status: 0,
      typed_handle_error: "",
      typed_user_name: "",
      typed_user_name_status: 0,
      typed_user_name_error: "",
      typed_email: "",
      typed_email_status: 0,
      typed_email_error: "",
      sending: false,
      general_error: false,
      general_error_message: null,

      show_payorcreate: false,
      show_payorlogin: false
    };
    
    this.on_type_name = this.on_type_name.bind(this);
    this.on_blur_name = this.on_blur_name.bind(this);
    this.on_type_abn = this.on_type_abn.bind(this);
    this.on_paste_abn = this.on_paste_abn.bind(this);
    this.on_key_down_abn = this.on_key_down_abn.bind(this);
    this.on_blur_abn = this.on_blur_abn.bind(this);
    this.display_abn = this.display_abn.bind(this);
    this.check_abn = this.check_abn.bind(this);
    this.on_type_handle = this.on_type_handle.bind(this);
    this.on_blur_handle = this.on_blur_handle.bind(this);
    this.on_type_user_name = this.on_type_user_name.bind(this);
    this.on_blur_user_name = this.on_blur_user_name.bind(this);
    this.on_type_email = this.on_type_email.bind(this);
    this.on_blur_email = this.on_blur_email.bind(this);
    this.create_company = this.create_company.bind(this);

    this.NAME_LENGTH_LIMIT = 48;
    this.MIN_NAME_LENGTH = 5;
    this.USER_NAME_LENGTH_LIMIT = 32;
    this.MIN_USER_NAME_LENGTH = 5;
    this.HANDLE_LENGTH_LIMIT = 16;
    this.MIN_HANDLE_LENGTH = 4;

    this.STATUS_COLOURS = ['black','red','green']; // normal, error, good

  }

  componentDidMount(){
    //window.addEventListener('paste', pasteText);
  }

  on_type_name(e){
    const typed = e.target.value;
    if ("QWERTYUIOPASDFGHJKLZXCVBNM1234567890- ".includes(typed.slice(-1).toUpperCase()) && typed.length <= this.NAME_LENGTH_LIMIT){
      this.setState({
        typed_name: typed,
        typed_name_status: typed.length > 5 
      });
    }
  }

  on_blur_name(){
    console.log("on blur name");
    if (this.state.typed_name.length < this.MIN_NAME_LENGTH || this.state.typed_name.length > this.NAME_LENGTH_LIMIT){
      this.setState({
        typed_name_status: 3,
        typed_name_error: "Name must be between "+String(this.MIN_NAME_LENGTH)+" and "+String(this.NAME_LENGTH_LIMIT)+" characters."
      });
    } else {
      this.setState({
        typed_name_status: 2
      });
    }
  }

  on_blur_abn(){
    if (this.state.typed_abn.length === 11){
      if (this.state.abn_result.AbnStatus === 'Active'){
        this.setState({
          typed_abn_status: 2
        });
      } else {
        this.setState({
          typed_abn_status: 1
        });
      }
    } else {
      this.setState({
        typed_abn_status: 1,
        typed_abn_error: "An ABN is 11 numerical characters."
      });
    }
  }

  check_abn(abn){
    console.log("Checking "+String(abn));
    fetch("/abnlookup?abn="+abn, {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log("Check ABN");
      console.log(json);
      if (json.pinged){
        let res = JSON.parse(json.response.slice(9,json.response.length - 1));
        console.log("RESULT()");
        console.log(res);
        this.setState({
          abn_result: res,
          typed_abn_status: res.AbnStatus === 'Active' ? 2 : 3,
          typed_abn_error: ""
        });
      } else if (!json.success) {
        this.setState({
          abn_result: null,
          typed_abn_status: 3,
          typed_abn_error: json.message
        });
      }
    });
  }

  on_paste_abn(e){
    if (e.preventDefault()){
      e.preventDefault();
    }
    const text = (e.clipboardData || window.clipboardData).getData('Text').replace(/ /g,'');
    if (text.length <= 11 && /^\d+$/.test(text)){
      this.setState({
        typed_abn: text,
        abn_result: null,
        typed_abn_status: 0
      });
    }
    if (text.length === 11){
      this.check_abn(text);
    }
  }

  on_key_down_abn(e){
    console.log('key down');
    if (e.ctrlKey||e.metaKey) {
      console.log("Prevented");
      return false;
    }
    const x = e.key;
    const typed = this.state.typed_abn;
    this.handle_abn_text(x, typed);
  }

  handle_abn_text(x, typed){
    if ("1234567890".includes(x) && typed.length < 11){
      this.setState({
        typed_abn: typed + x,
        abn_result: null,
        typed_abn_status: 1
      });
      if (typed.length === 10){
        this.check_abn(typed+x);
      }
    } else if (x === 'Backspace'){
      this.setState({
        typed_abn: typed.length === 1 ? "" : typed.slice(0,typed.length-1),
        abn_result: null,
        typed_abn_status: 1
      });
    }
  }

  on_type_abn(e){
    const x = e.target.value.replace(/ /g,'');
    console.log('on_type_abn');
    console.log(x);
    if ("1234567890".includes(x.slice(-1)) && x.length <= [11][this.state.abn_type]){
      console.log("get "+x);
      this.setState({
        typed_abn: x,
        typed_abn_status: 0
      });
    }
  }

  display_abn(x){
    if (this.state.abn_type === 0){
      return x.split('').map((a,i) => [1,4,7].includes(i) ? a+' ' : a).join('');
      //return x;//x.slice(0,2)+' '+x.slice(2,5)+' '+x.slice(5,8)+' '+x.slice(8,11);
    }
  }

  on_type_user_name(e){
    if ("1234567890QWERTYUIOPASDFGHJKLZXCVBNM-qwertyuiopasdfghjklzxcvbnm ".includes(e.target.value.slice(-1)) && e.target.value.length < this.USER_NAME_LENGTH_LIMIT){
      this.setState({
        typed_user_name: e.target.value
      });
    }
  }

  on_type_handle(e){
    const typed = e.target.value.replace('@','');
    if ("1234567890QWERTYUIOPASDFGHJKLZXCVBNM_qwertyuiopasdfghjklzxcvbnm".includes(typed.slice(-1)) && typed.length < this.HANDLE_LENGTH_LIMIT){
      this.setState({
        typed_handle: typed
      });
    }
  }

  on_blur_handle(){
    console.log("blur handle");
    if (this.state.typed_handle.length < this.MIN_HANDLE_LENGTH){
      this.setState({
        typed_handle_status: 3,
        typed_handle_error: "Your handle must be at least  "+String(this.MIN_HANDLE_LENGTH)+" characters."
      });
      return null;
    }
    this.setState({
      typed_handle_status: 1
    });
    fetch("/checkhandle?handle="+this.state.typed_handle, {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        typed_handle_status: json.available ? 2 : 3,
        typed_handle_error: json.available ? "" : "This handle is not available."
      })
    });
  }

  on_blur_user_name(){
    if (this.state.typed_user_name.length < this.MIN_USER_NAME_LENGTH || this.state.typed_user_name.length > this.USER_NAME_LENGTH_LIMIT){
      this.setState({
        typed_user_name_status: 3,
        typed_user_name_error: "Must be between "+String(this.MIN_USER_NAME_LENGTH) + " and " + String(this.USER_NAME_LENGTH_LIMIT) + " characters."
      });
    } else {
      this.setState({
        typed_user_name_status: 2
      });
    }
  }

  on_type_email(e){
    if (!e.target.value.includes(' ')){
      this.setState({
        typed_email: e.target.value,
        typed_email_status: 1
      });
      //this.on_blur_email();
    }
  }

  on_blur_email(){
    if (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.typed_email)){
      fetch("/api/checkemail?email="+this.state.typed_email, {
        method: 'GET',
        credentials: 'include'
      }).then( (response) => {
        if (response.ok){
          return response.json();
        }
        throw new Error('Request fail');
      }).then(json => {
        console.log("checked email");
        console.log(json);
        if (json.available){
          this.setState({
            typed_email_status: 2
          });
        } else {
          this.setState({
            typed_email_status: 3,
            typed_email_error: "That email is not available to use"
          });
        }
      });
    } else {
      this.setState({
        typed_email_status: 3,
        typed_email_error: "Please type a valid email."
      });
    }
  }

  create_company(){
    // /company/create
    this.setState({
      sending: true
    });
    const post_body = {
      company: {
        name: this.state.typed_name,
        abn: this.state.typed_abn,
        handle: this.state.typed_handle
      },
      user: {
        name: this.state.typed_user_name,
        email: this.state.typed_email
      }
    };
    fetch("/company/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(post_body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log("JSON response:");
      console.log(json);
      if (json.success){
        window.location.href = "/confirm?email="+this.state.typed_email;
      } else {
        if (json.message === undefined){
          this.setState({
            general_error: true,
            general_error_message: null
          });
        } else {
          this.setState({
            general_error: true,
            general_error_message: json.message
          });
        }
      }
    });
  }

  render () {
    console.log("state");
    console.log(this.state);
    return (
      <div className="form-section">
        <button onClick={() => this.setState({show_payorcreate: true})}>
          Show PayorCreate
        </button>
        <button onClick={() => this.setState({show_payorlogin: true})}>
          Show PayorLogin
        </button>
        
        {this.state.show_payorcreate ? 
          <Overlay on_cancel={() => this.setState({show_payorcreate: false})} title="Create Payor Account">
            <PayorCreate auth_token={this.props.auth_token} />
          </Overlay> : null}
        {this.state.show_payorlogin ? 
          <Overlay on_cancel={() => this.setState({show_payorlogin: false})}>
            <div style={{height: '400px'}}>
              <LoginPayor auth_token={this.props.auth_token} />
            </div>
          </Overlay> : null}

        <div style={{width: '400px', margin: 'auto'}}>
        <div className="form-intra-section">
          <h3>
            Your company name
          </h3>
          <div className="myinput-wrapper-centre">
            <MyInput type="text" label="Company name" onChange={this.on_type_name} value={this.state.typed_name} status={this.state.typed_name_status} onBlur={this.on_blur_name} note={this.state.typed_name_status === 3 ? this.state.typed_name_error : null} />
          </div>
        </div>

        <div className="form-intra-section">
          <h3>
            Your ABN
          </h3>
          <div>
            <table style={{marginBottom: '15px'}}><tbody>
              <tr>
                <td style={{width: '40px'}}>
                  <div type="radio" className={!this.state.have_abn ? "standard-radio-checked" : "standard-radio"} onClick={() => this.setState({have_abn: false})} style={{margin: 'auto'}}>
                    <div />
                  </div>
                </td>
                <td style={{fontSize: '14px', color: 'grey', padding: '5px'}}>
                  I don't have an ABN
                </td>
              </tr>
              <tr>
                <td style={{width: '40px'}}>
                  <div type="radio" className={this.state.have_abn ? "standard-radio-checked" : "standard-radio"} onClick={() => this.setState({have_abn: true})} style={{margin: 'auto'}}>
                    <div />
                  </div>
                </td>
                <td style={{fontSize: '14px', color: 'grey', padding: '5px'}}>
                  I have an ABN
                </td>
              </tr>
            </tbody></table>
          </div>
          <div style={{textAlign: 'center'}}>
            {this.state.have_abn ? 
            <div className="myinput-wrapper-centre">
              <MyInput type="text" onKeyDown={this.on_key_down_abn} value={this.display_abn(this.state.typed_abn)} onPaste={this.on_paste_abn} status={this.state.typed_abn_status} onBlur={this.on_blur_abn} label="Australian Business Number" note={this.state.typed_abn_status === 2 ? (this.state.abn_result.BusinessName.length === 0 ? this.state.abn_result.EntityName : this.state.abn_result.BusinessName[0]) : [null,null,null,this.state.typed_abn_error][this.state.typed_abn_status]} />
            </div> : null}
          </div>
        </div>

        <div className="form-intra-section">
          <h3>
            Your handle
          </h3>
          <div className="myinput-wrapper-centre">
            <MyInput type="text" onChange={this.on_type_handle} value={'@'+this.state.typed_handle} onBlur={this.on_blur_handle} status={this.state.typed_handle_status} note={[null,null,'@'+this.state.typed_handle+' is available',this.state.typed_handle_error][this.state.typed_handle_status]} onFocus={() => this.setState({typed_handle_status: 1})} label="A unique term to identify your organisation" />
          </div>
        </div>

        <div className="form-intra-section">
          <h3>
            Tell us about you
          </h3>
          <p className="subtle-p">
            You will be the administrator of the account.
          </p>
          <div className="myinput-wrapper-centre">
            <MyInput onChange={this.on_type_user_name} value={this.state.typed_user_name} status={this.state.typed_user_name_status} note={this.state.typed_user_name_status === 3 ? this.state.typed_user_name_error : null} onBlur={this.on_blur_user_name} label="Your name" />
          </div>
        </div>

        <div className="form-intra-section">
          <h3>
            Your email
          </h3>
          <div className="myinput-wrapper-centre">
            <MyInput onChange={this.on_type_email} value={this.state.typed_email} status={this.state.typed_email_status} note={this.state.typed_email_status === 3 ? this.state.typed_email_error : null} onBlur={this.on_blur_email} label="You will need to confirm your account" />
          </div>
        </div>

        {[this.state.typed_name_status,this.state.typed_abn_status,this.state.typed_email_status,this.state.typed_handle_status,this.state.typed_user_name_status].reduce((s, a) => s + a, 0) === 10 ? 
          <div>
            {this.state.sending ? 
              <div>
                Loading...
              </div> : 
              <button onClick={this.create_company} className="std-button">
                Continue
              </button>}
          </div> : null}
        </div>
      </div>
    );
  }
}

export default NewCompany
/*
{this.state.abn_result === null ? null : 
            <div>
              {this.state.abn_result.AbnStatus === 'Active' ? 
                <div>
                  <h4>
                    {this.state.abn_result.BusinessName.length === 0 ? this.state.abn_result.EntityName : this.state.abn_result.BusinessName[0]}
                  </h4>
                  <p>
                    {this.state.abn_result.EntityTypeName}
                  </p>
                </div> : 
                <div>
                  <p>
                    Sorry, but no active ABN could be found for that number.
                  </p>
            </div>}
            </div>}
*/