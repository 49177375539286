import React from "react"
import PropTypes from "prop-types"
class AccountSchemeBilling extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      loaded: false,
      messages: [],
      show_upgrade: false,
      upgrade_selected_index: -1,
      scheme: null
    };

    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  componentDidMount(){
    if (![null,undefined].includes(this.props.data)){
      this.setState({
        data: this.props.data,
        loaded: true,
        scheme: JSON.parse(this.props.data.available_plans.filter((p) => p.id === this.props.data.current_bill.billingplan_id)[0].text_scheme)
      });
    } else {
      this.setState({
        loaded: false
      });
    }
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  componentDidUpdate(prev_props){
    if (prev_props.data !== this.props.data){
      this.componentDidMount();
    }
  }

  render () {
    console.log("Scheme Billing State");
    console.log(this.state);
    //const usage = !this.state.loaded ? [] : this.state.scheme.max_transactions.map((v,i) => (Math.min(v < 0 ? 999999999999 : v - (i === 0 ? 0 : this.state.scheme.max_transactions.slice(0,i).reduce((e,p) => e+p))) ,Math.max(this.state.data.current_bill.transactions - (i === 0 ? 0 : this.state.scheme.max_transactions.slice(0,i).reduce((e,p) => e+p)),0)));
    if (!this.state.loaded){
      return null;
    }
    // ** const usage = this.state.scheme.max_transactions.map((v,i) => this.state.data.current_bill.transactions - (i === 0 ? 0 : this.state.scheme.max_transactions[i-1]/*this.state.scheme.max_transactions.slice(0,i).reduce((t,p) => t+p)*/)).map((v,i) => this.state.scheme.max_transactions[i] < 0 ? Math.max(0,v) : Math.max(0, Math.min(v, this.state.scheme.max_transactions[i] - (i === 0 ? 0 : this.state.scheme.max_transactions[i-1]))));
    /*let nn = this.state.data.current_bill.transactions;
    const usage = this.state.scheme.max_transactions.map((v,i) => {
      if (nn > 0){
        
      } else {
        return 0;
      }
    });*/
    console.log('usage');
    //console.log(usage);
    return (this.state.loaded ? 
      <div>
        <button onClick={() => {
          let data = this.state.data;
          data.current_bill.transactions += 1;
          this.setState({data: data});
        }}>
          Increase by 1
        </button>
        <button onClick={() => {
          let data = this.state.data;
          data.current_bill.transactions += 10;
          this.setState({data: data});
        }}>
          Increase by 10
        </button>
        <button onClick={() => {
          let data = this.state.data;
          data.current_bill.transactions += 100;
          this.setState({data: data});
        }}>
          Increase by 100
        </button>
        <button onClick={() => {
          let data = this.state.data;
          data.current_bill.transactions += 1000;
          this.setState({data: data});
        }}>
          Increase by 1000
        </button>
        Tx: {this.state.data.current_bill.transactions}
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <table><tbody>
          <tr>
            <td>
              <h3>
                Outstanding Bills
              </h3>
            </td>
          </tr>
        </tbody></table>
        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <h3>
                Current Billing Period<br />
                <span style={{color: 'grey'}}>{String(new Date(this.state.data.current_bill.start)).slice(4,15)} - {String(new Date(this.state.data.current_bill.end)).slice(4,15)}</span>
              </h3>
            </td>
            <td style={{textAlign: 'right'}}>
              <h3>
                ${this.amount_to_formatted_string(this.state.data.current_bill.total)}<br />
                <span style={{fontSize: '14px', color: 'grey', fontWeight: 'normal'}}>As at {String(new Date(this.state.data.current_bill.time_now)).slice(4,24)}</span>
              </h3>
            </td>
          </tr>
        </tbody></table>

        {!this.state.data.billing_enabled ?
        <div className={"flash flash-info"}>
          <table><tbody>
            <tr>
              <td style={{fontSize: '18px'}}>
                <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON['info']}></i>
              </td>
              <td>
                You are restricted in certain actions because Billing is not enabled. To enable Billing, you will need to add at least one Card in the Cards section. <a href="/" target="_blank">Click here</a> to learn how Billing works.
              </td>
            </tr>
          </tbody></table>
        </div> : null}

        <div className="billing-tally">
          <div style={{fontSize: '15px', fontFamily: 'Roboto Mono', textDecoration: 'underline'}}>
            Bill # {this.state.data.current_bill.code}
          </div>
          <table><tbody>
            <tr>
              <td>
                Item
              </td>
              <td>
                Price
              </td>
              <td>
                Qty
              </td>
              <td>
                Total
              </td>
            </tr>
            <tr>
              <td>
                Plan base amount
              </td>
              <td>
                {this.amount_to_formatted_string(this.state.data.current_bill.billingplan.base_amount)}
              </td>
              <td>
                1
              </td>
              <td>
                {this.amount_to_formatted_string(this.state.data.current_bill.billingplan.base_amount)}
              </td>
            </tr>
            {/*usage.map(
              (u, i) =>
              <tr>
                <td>
                  Transact. {i === 0 ? 0 : this.state.scheme.max_transactions[i-1]} - {this.state.scheme.max_transactions[i] < 0 ? "" : this.state.scheme.max_transactions[i]}
                </td>
                <td>
                  {this.amount_to_formatted_string(this.state.scheme.rate[i])}
                </td>
                <td>
                  {u}
                </td>
                <td>
                  {this.amount_to_formatted_string(u * this.state.scheme.rate[i])}
                </td>
              </tr>
            )*/}
            <tr>
              <td>
                Total
              </td>
              <td>
              </td>
              <td>
              </td>
              <td style={{borderTop: 'solid', borderTopWidth: '1px', borderTopColor: 'grey'}}>
                {/*this.amount_to_formatted_string(this.state.data.current_bill.total)*/}
                {/*this.amount_to_formatted_string(usage.map((v,i) => v*this.state.scheme.rate[i]).reduce((v,p) => v+p))*/}
              </td>
            </tr>
          </tbody></table>
        </div>
        
      </div> : null);
  }
}

export default AccountSchemeBilling
