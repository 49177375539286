import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"
import MyInput from "./MyInput.js"
class WebhooksManager extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      init_loaded: false,
      webhooks: [],
      webhook: null,
      show_webhook: false,
      webhook_index: -1,
      edit: false,
      edit_index: 0,
      show_new_webhook: false,
      new_webhook_index: 0,
      object_name_index: 0,
      event_index: 0,
      typed_target: "https://",
      typed_target_status: 0,
      typed_target_message: "",
      loading: false,
      messages: [],
      show_delete: false,
      typed_delete: ""
    };

    this.get_webhooks = this.get_webhooks.bind(this);
    this.on_type_target = this.on_type_target.bind(this);
    this.on_blur_target = this.on_blur_target.bind(this);
    this.create_webhook = this.create_webhook.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.change_webhook = this.change_webhook.bind(this);
    this.save_webhook = this.save_webhook.bind(this);
    this.delete_webhook = this.delete_webhook.bind(this);

    this.OBJECTS = [{
      name: 'invoice',
      title: 'Invoice'
    },{
      name: 'fund',
      title: 'Fund'
    },{
      name: 'customer',
      title: 'Customer'
    }];
    this.EVENTS = [{
      value: 'payment',
      title: 'On payment made'
    }];
    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    this.STATUSES = {'-1':'Suspended','0':'Initiated','1':'Active'};

  }

  componentDidMount(){
    this.get_webhooks();
  }

  get_webhooks(){
    // /api/webhooks/index
    fetch("/api/webhooks/index", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        webhooks: json.webhooks,
        init_loaded: true
      });
    });
  }

  on_blur_target(e){
    if (/^(https?:\/\/)[^\s/$.?#].[^\s]*$/.test(this.state.typed_target)){
      this.setState({
        typed_target_status: 2,
        typed_target_message: ""
      });
    } else {
      this.setState({
        typed_target_status: 3,
        typed_target_message: "Not a valid URL (starting with http:// or https://)"
      })
    }
  }

  on_type_target(e){
    const typed = e.target.value;
    if (/^[a-zA-Z0-9._~:/?#[@\]!$&'()*+,;=%-]+$/.test(typed)){
      this.setState({
        typed_target: typed
      });
    } else if (typed.length === 0){
      this.setState({
        typed_target: "https://"
      });
    }
  }

  create_webhook(){
    if (this.state.loading){
      return null;
    }
    this.setState({
      loading: true
    });
    // /api/webhook/create
    fetch("/api/webhook/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        webhook: {
          event: this.EVENTS[this.state.event_index].value,
          object_name: this.OBJECTS[this.state.object_name_index].name,
          target: this.state.typed_target
        }
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to successfully create Webhook.",'danger');
      this.setState({
        loading: false,
        show_new_webhook: false,
        new_webhook_index: 0
      })
      throw new Error('Request fail');
    }).then(json => {
      let webhooks = this.state.webhooks;
      webhooks.push(json.webhook);
      this.setState({
        loading: false,
        webhooks: webhooks,
        show_new_webhook: false,
        new_webhook_index: 0
      });
      this.add_message("Webhook successfully created.",'success')
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  change_webhook(key, val){
    let webhook = this.state.webhook;
    webhook[key] = val;
    this.setState({
      webhook: webhook
    });
  }

  save_webhook(){
    this.setState({
      loading: true
    });
    // /api/webhook/update
    fetch("/api/webhook/"+this.state.webhook.code+"/update", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        webhook: this.state.webhook
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to successfully update Webhook.",'danger');
      this.setState({
        loading: false,
        edit: false,
        edit_index: -1,
        show_webhook: false,
        loading: false
      });
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.add_message("Successfully updated Webhook.",'success');
        let webhooks = this.state.webhooks;
        webhooks[this.state.webhook_index] = this.state.webhook;
        this.setState({
          webhooks: webhooks,
          edit: false,
          edit_index: -1,
          show_webhook: false,
          loading: false
        });
      } else {
        this.add_message("Encountered error in updating Webhook.",'danger');
        this.setState({
          edit: false,
          edit_index: -1,
          show_webhook: false,
          loading: false
        });
      }
    });
  }

  delete_webhook(){
    this.setState({
      loading: true
    });
    fetch("/api/webhook/"+this.state.webhooks[this.state.webhook_index].code+"/delete", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to delete Webhook.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        let webhooks = [];
        this.state.webhooks.forEach((w,i) => w.code != this.state.webhooks[this.state.webhook_index].code ? webhooks.push(w) : null);
        this.setState({
          webhooks: webhooks,
          show_delete: false,
          show_webhook: false,
          loading: false,
          typed_delete: ""
        });
        this.add_message("Webhook successfully deleted.",'success');
      } else {
        this.add_message("Server encountered error in deleting Webhook.",'danger');
      }
    });
  }


  render () {
    console.log(this.state);
    return (this.state.init_loaded ? 
      <div className="form-section">

        {this.state.show_delete ? 
          <Overlay on_cancel={() => this.setState({show_delete: false})} title="Delete Webhook?" width="500px">
            <div>
              <p style={{fontSize: '15px', color: 'grey'}}>
                Are you sure you want to delete <b>{this.state.webhooks[this.state.webhook_index].code}</b>?
              </p>
              <p style={{fontSize: '15px', color: 'grey'}}>
                To delete, type <b>{this.state.webhooks[this.state.webhook_index].code}</b> below:
              </p>
              <MyInput onChange={(e) => this.setState({typed_delete: e.target.value})} value={this.state.typed_delete} onFocus={null} onBlur={null} status={1} note={null} label="Type the Webhook code" />
              <button className="std-button" onClick={() => this.setState({show_delete: false, show_webhook: true})}>
                Cancel
              </button>
              {this.state.typed_delete === this.state.webhooks[this.state.webhook_index].code ? 
                <button className="std-button" style={{backgroundColor: 'red'}} onClick={this.delete_webhook}>
                  {this.state.loading ? "Deleting..." : "Delete"}
                </button> : null}
            </div>
          </Overlay> : null}

        {this.state.show_webhook ? 
          <Overlay on_cancel={() => this.setState({show_webhook: false, webhook_index: -1})} title={"Webhook "+this.state.webhooks[this.state.webhook_index].code} width="500px">
            {this.state.edit ? 
            <div>
              {[
                <div>
                  <h4>
                    Edit status
                  </h4>
                  <div className={this.state.webhook.status === 1 ? "selection-box-selected" : "selection-box"} onClick={() => this.change_webhook('status',1)}>
                    <table><tbody>
                      <tr>
                        <td>
                          <div>
                            <div />
                          </div>
                        </td>
                        <td>
                          <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                            Active
                          </div>
                          {/*<p style={{color: "grey", fontSize: "14px"}}>
                            Payor(s) will not be able to obtain a unique reference.
                          </p>*/}
                        </td>
                      </tr>
                    </tbody></table>
                  </div>
                  <div className={this.state.webhook.status === -1 ? "selection-box-selected" : "selection-box"} onClick={() => this.change_webhook('status',-1)}>
                    <table><tbody>
                      <tr>
                        <td>
                          <div>
                            <div />
                          </div>
                        </td>
                        <td>
                          <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                            Suspended
                          </div>
                          {/*<p style={{color: "grey", fontSize: "14px"}}>
                            Payor(s) will not be able to obtain a unique reference.
                          </p>*/}
                        </td>
                      </tr>
                    </tbody></table>
                  </div>
                  <button className="std-button" onClick={this.save_webhook}>
                    {this.state.loading ? "Saving..." : "Save"}
                  </button>
                  <button className="std-button" onClick={() => this.setState({edit: false, typed_target: ""})}>
                    Cancel
                  </button>
                </div>,

                <div>
                  <h4>
                    Target
                  </h4>
                  <MyInput onChange={this.on_type_target} value={this.state.typed_target} onFocus={() => this.setState({typed_target_status: 1})} onBlur={this.on_blur_target} status={this.state.typed_target_status} note={this.state.typed_target_status === 3 ? this.state.typed_target_message : null} label={"Target URL"} />
                  <button className="std-button" onClick={() => {
                    let webhook = this.state.webhook;
                    webhook.target = this.state.typed_target;
                    this.setState({
                      webhook: webhook
                    }, () => {
                      this.save_webhook();
                    });
                  }}>
                    {this.state.loading ? "Saving..." : "Save"}
                  </button>
                  <button className="std-button" onClick={() => this.setState({edit: false, typed_target: ""})}>
                    Cancel
                  </button>
                </div>,

                <div>
                  <h4>
                    Object
                  </h4>
                  {this.OBJECTS.map(
                    (obj, index) =>
                    <div className={obj.name === this.state.webhook.object_name ? "selection-box-selected" : "selection-box"} onClick={() => this.change_webhook('object_name',obj.name)} key={index}>
                      <table><tbody>
                        <tr>
                          <td>
                            <div>
                              <div />
                            </div>
                          </td>
                          <td>
                            <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                              {obj.title}
                            </div>
                            {/*<p style={{color: "grey", fontSize: "14px"}}>
                              Payor(s) will not be able to obtain a unique reference.
                            </p>*/}
                          </td>
                        </tr>
                      </tbody></table>
                    </div>
                  )}
                  <button className="std-button" onClick={this.save_webhook}>
                    {this.state.loading ? "Saving..." : "Save"}
                  </button>
                  <button className="std-button" onClick={() => this.setState({edit: false})}>
                    Cancel
                  </button>
                </div>,

                <div>
                  <h4>
                    Event
                  </h4>
                  {this.EVENTS.map(
                    (event, index) => 
                      <div className={this.state.webhook.event === event.value ? "selection-box-selected" : "selection-box"} onClick={() => this.change_webhook('event',event.value)} key={index}>
                        <table><tbody>
                          <tr>
                            <td>
                              <div>
                                <div />
                              </div>
                            </td>
                            <td>
                              <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                                {event.title}
                              </div>
                              {/*<p style={{color: "grey", fontSize: "14px"}}>
                                Payor(s) will not be able to obtain a unique reference.
                              </p>*/}
                            </td>
                          </tr>
                        </tbody></table>
                      </div>
                  )}
                  <button className="std-button" onClick={this.save_webhook}>
                    {this.state.loading ? "Saving..." : "Save"}
                  </button>
                  <button className="std-button" onClick={() => this.setState({edit: false})}>
                    Cancel
                  </button>
                </div>
              ][this.state.edit_index]}
            </div> : 
            <div>
              <table className="webhook-show-table"><tbody>
                <tr>
                  <td style={{verticalAlign: 'top'}}>
                    Status
                  </td>
                  <td>
                    {this.STATUSES[String(this.state.webhooks[this.state.webhook_index].status)]}<br />
                    <button onClick={() => this.setState({edit: true, edit_index: 0, webhook: this.state.webhooks[this.state.webhook_index]})} className="button-blue">
                      Edit
                    </button>
                  </td>
                </tr>
                <tr>
                  <td style={{verticalAlign: 'top'}}>
                    Target
                  </td>
                  <td>
                    {this.state.webhooks[this.state.webhook_index].target}<br />
                    <button onClick={() => this.setState({edit: true, edit_index: 1, webhook: this.state.webhooks[this.state.webhook_index], typed_target: this.state.webhooks[this.state.webhook_index].target, typed_target_status: 0})} className="button-blue">
                      Edit
                    </button>
                  </td>
                </tr>
                <tr>
                  <td style={{verticalAlign: 'top'}}>
                    Object
                  </td>
                  <td>
                    {this.state.webhooks[this.state.webhook_index].object_name}<br />
                    <button onClick={() => this.setState({edit: true, edit_index: 2, webhook: this.state.webhooks[this.state.webhook_index], typed_target: this.state.webhooks[this.state.webhook_index].target, typed_target_status: 0})} className="button-blue">
                      Edit
                    </button>
                  </td>
                </tr>
                <tr>
                  <td style={{vericalAlign: 'top'}}>
                    Event
                  </td>
                  <td>
                    {this.state.webhooks[this.state.webhook_index].event}<br />
                    <button onClick={() => this.setState({edit: true, edit_index: 3, webhook: this.state.webhooks[this.state.webhook_index], typed_target: this.state.webhooks[this.state.webhook_index].target, typed_target_status: 0})} className="button-blue">
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody></table>
              <button className="std-button" style={{backgroundColor: 'red'}} onClick={() => this.setState({show_delete: true, show_webhook: false})}>
                Delete
              </button>
            </div>}
          </Overlay> : null}


        {this.state.show_new_webhook ? 
          <Overlay on_cancel={() => this.setState({show_new_webhook: false, new_webhook_index: 0})} title="New Webhook" width="500px">
            <div style={{height: '500px'}}>
            {[<div>
              <h4>
                Object
              </h4>
              <p style={{fontSize: '14px', color: 'grey'}}>
                Which object do you want to add the Webhook to?
              </p>
              {this.OBJECTS.map(
                (obj, index) =>
                <div className={this.state.object_name_index === index ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({object_name_index: index})} key={index}>
                  <table><tbody>
                    <tr>
                      <td>
                        <div>
                          <div />
                        </div>
                      </td>
                      <td>
                        <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                          {obj.title}
                        </div>
                        {/*<p style={{color: "grey", fontSize: "14px"}}>
                          Payor(s) will not be able to obtain a unique reference.
                        </p>*/}
                      </td>
                    </tr>
                  </tbody></table>
                </div>
              )}
              <div style={{textAlign: 'center'}}>
                <button className="std-button" onClick={() => this.setState({new_webhook_index: 1})}>
                  Continue
                </button>
              </div>
            </div>,
            <div>
              <h4>
                Event
              </h4>
              <p style={{fontSize: '14px', color: 'grey'}}>
                What event do you want to fire the Webhook on?
              </p>
              {this.EVENTS.map(
                (event, index) => 
                  <div className={this.state.event_index === index ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({event_index: index})} key={index}>
                    <table><tbody>
                      <tr>
                        <td>
                          <div>
                            <div />
                          </div>
                        </td>
                        <td>
                          <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                            {event.title}
                          </div>
                          {/*<p style={{color: "grey", fontSize: "14px"}}>
                            Payor(s) will not be able to obtain a unique reference.
                          </p>*/}
                        </td>
                      </tr>
                    </tbody></table>
                  </div>
              )}
              <div style={{textAlign: 'center'}}>
                <button className="std-button" onClick={() => this.setState({new_webhook_index: 2})}>
                  Continue
                </button>
              </div>
            </div>,
            <div>
              <h4>
                Target URL
              </h4>
              <p style={{fontSize: '14px', color: 'grey'}}>
                What URL do you want to send the payload on a Webhook trigger?
              </p>
              <table style={{margin: 'auto'}}><tbody>
                <tr>
                  <td style={{verticalAlign: 'bottom'}}>
                    <div style={{width: '60px', padding: '14px', border: 'solid', borderWidth: '2px', borderColor: '#040dba', borderRadius: '6px', fontSize: '16px', textAlign: 'center', color: '#040dba', fontFamily: 'Roboto Mono', marginBottom: '7px'}}>
                      POST
                    </div>
                  </td>
                  <td>
                    <MyInput onChange={this.on_type_target} value={this.state.typed_target} onFocus={() => this.setState({typed_target_status: 1})} onBlur={this.on_blur_target} status={this.state.typed_target_status} note={this.state.typed_target_status === 3 ? this.state.typed_target_message : null} label={"Target URL"} />
                  </td>
                </tr>
              </tbody></table>
              {this.state.typed_target_status === 2 ? 
                <div style={{textAlign: 'center'}}>
                  <button className="std-button" onClick={this.create_webhook}>
                    {this.state.loading ? "Creating..." : "Create"}
                  </button>
                </div> : null}
              </div>][this.state.new_webhook_index]}
            </div>
          </Overlay> : null}
        
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <h2>
          Webhooks
        </h2>
        {this.state.webhooks.map(
          (webhook, index) =>
          <div className="webhook-list-card" key={index} onClick={() => this.setState({webhook_index: index, show_webhook: true})}>
            <table><tbody>
              <tr>
                <td>
                  Code
                </td>
                <td>
                  {webhook.code}
                </td>
              </tr>
              <tr>
                <td>
                  Target URL
                </td>
                <td>
                  {webhook.target}
                </td>
              </tr>
              <tr>
                <td>
                  Status
                </td>
                <td>
                  {this.STATUSES[String(webhook.status)]}
                </td>
              </tr>
              <tr>
                <td>
                  Object
                </td>
                <td>
                  {webhook.object_name}
                </td>
              </tr>
              <tr>
                <td>
                  Event
                </td>
                <td>
                  {webhook.event}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}
        {this.state.webhooks.length === 0 ?
          <p style={{fontSize: '15px', color: 'grey'}}>
            You have no Webhooks at this stage.
          </p> : null}
        
          <div className="webhook-list-card" style={{textAlign: 'center', fontSize: '13px', color: 'grey', margin: 'auto', marginTop:'3px'}} onClick={() => this.setState({show_new_webhook: true, typed_target: "", typed_target_status: 0})}>
            <i className="fa-solid fa-plus"></i> Add Webhook
          </div>

      </div> : null
    );
  }
}

export default WebhooksManager
