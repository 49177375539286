import React from "react"
import PropTypes from "prop-types"
class CalendarDate extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      month: "",
      day: ""
    };
  }

  componentDidMount(){
    let d = new Date(this.props.value);
    this.setState({
      month: ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'][d.getMonth()],
      day: (d.getDate() < 10 ? '0' : '') + d.getDate()
    })
  }

  render () {
    return (
      <div style={{width: '52px', margin: 'auto', borderRadius: '4px', border: 'solid', borderWidth: '1px', borderColor: '#c9c9c9'}}>
        <div style={{backgroundColor: 'red', textAlign: 'center', padding: '2px', fontSize: '10px', fontWeight: 'bold', color: '#fff'}}>
          {this.state.month}
        </div>
        <div style={{padding: '6px', fontSize: '18px', fontWeight: 'bold', textAlign: 'center'}}>
          {this.state.day}
        </div>
      </div>
    );
  }
}

export default CalendarDate
