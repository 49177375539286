import React from "react"
import PropTypes from "prop-types"
class Toggle extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      checked: this.props.init_value === undefined ? false : this.props.init_value
		};

    this.on_toggle = this.on_toggle.bind(this);

  }

  on_toggle(){
    if (this.props.on_toggle != undefined){
      this.props.on_toggle(!this.state.checked);
    }
    this.setState({
      checked: !this.state.checked
    });
  }

  render () {
    return (
      <div style={{width: '46px', backgroundColor: (this.state.checked ? '#32bfb8' : '#c9c9c9'), height: '20px', borderRadius: '25px', cursor: 'pointer'/*, border: 'solid', borderColor: 'grey', borderWidth: '2px'*/}} onClick={this.on_toggle} className="toggle-div">
        <div style={{width: '16px', height: '16px', border: 'solid', borderWidth: '2px', borderColor: 'black', backgroundColor: '#fff', borderRadius: '12px', marginLeft: (this.state.checked ? '30px' : '-4px'), cursor: 'pointer'}} onClick={this.on_toggle} className="toggle-div">
        </div>
      </div>
    );
  }
}

export default Toggle

/*
<div style={{width: '60px', backgroundColor: (this.state.checked ? '#32bfb8' : '#c9c9c9'), height: '25px', borderRadius: '25px', cursor: 'pointer', paddingTop: '5px'}} onClick={this.on_toggle} className="toggle-div">
<div style={{width: '16px', height: '16px', border: 'solid', borderWidth: '2px', borderColor: 'black', backgroundColor: '#fff', borderRadius: '12px', marginLeft: (this.state.checked ? '34px' : '6px'), cursor: 'pointer'}} className="toggle-div">
</div>
</div>
*/
