import React from "react"
import PropTypes from "prop-types"
import * as zxcvbn from "./zxcvbn.js"

class ConfirmEmailToken extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      typed_token: "",
      typed_token_status: 0,
      typed_token_error: "",
      stage: 1,
      secondary_token: "",
      token_type: Number(this.props.token_type),
      typed_password: "",
      password_score: -1,
      password_error: false,
      password_error_message: "",
      password_evaluation: null,
      typed_password_confirmation: "",
      password_confirmation_error: false,
      password_confirmation_error_message: ""
    };

    this.on_type_token = this.on_type_token.bind(this);
    this.check_token = this.check_token.bind(this);
    this.on_type_password = this.on_type_password.bind(this);
    this.on_blur_password = this.on_blur_password.bind(this);
    this.on_type_password_confirmation = this.on_type_password_confirmation.bind(this);
    this.on_blur_password_confirmation = this.on_blur_password_confirmation.bind(this);
    this.on_key_down_field = this.on_key_down_field.bind(this);
    this.confirm_password = this.confirm_password.bind(this);

    this.TOKEN_LENGTH = 6;
    this.STATUS_COLOURS = ['black','red','green']; // normal, error, good
    this.MIN_PASSWORD_LENGTH = 6;
    this.MAX_PASSWORD_LENGTH = 128;
    this.PASSWORD_STRENGTH_DESCRIPTIONS = ['Very weak','Weak','Fair','Good','Excellent'];

  }

  componentDidMount(){
    if (!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.props.email)){
      window.location.href = "/"; // or something else
      //console.log("aaa");
    }
  }

  on_type_token(e){
    const t = e.target.value.replace('-','').toUpperCase();
    //console.log("on_type_token()");
    //console.log(t);
    if ("QWERTYUIOPASDFGHJKLZXCVBNM1234567890".includes(t.slice(-1)) && t.length <= this.TOKEN_LENGTH && this.state.stage === 1){
      this.setState({
        typed_token: t
      });
      if (t.length === this.TOKEN_LENGTH){
        this.check_token(t);
      }
    }
  }

  check_token(token){
    console.log("Check token");
    //confirmtoken
    fetch("/confirmtoken", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        email: this.props.email,
        token: token
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log("Token checked");
      console.log(json);
      if (json.success){
        this.setState({
          typed_token_status: 2,
          secondary_token: json.token,
          stage: 2
        }, () => {
          document.getElementById('password-field').focus();
        });
      } else {
        console.log(json);
        this.setState({
          typed_token_status: 1,
          typed_token_error: json.message === undefined ? null : json.message
        });
      }
    });
  }

  on_type_password(e){
    // Handle if 'Enter' is pushed
    const typed = e.target.value;
    if (typed.length < this.MAX_PASSWORD_LENGTH){
      const pw_eval = zxcvbn(typed);
      console.log(pw_eval);
      this.setState({
        typed_password: typed,
        password_score: typed.length < this.MIN_PASSWORD_LENGTH ? -1 : pw_eval.score,
        password_error: false,
        password_error_message: "",
        password_evaluation: pw_eval
      });
    }
  }

  on_blur_password(){
    if (this.state.password_score === -1 || this.state.typed_password.length > this.MAX_PASSWORD_LENGTH){
      this.setState({
        password_error_message: "Password must be between "+String(this.MIN_PASSWORD_LENGTH)+" and "+String(this.MAX_PASSWORD_LENGTH)+" characters.",
        password_error: true
      });
    }
  }

  on_type_password_confirmation(e){
    const typed = e.target.value;
    if (typed.length < this.MAX_PASSWORD_LENGTH){
      this.setState({
        typed_password_confirmation: typed,
        password_confirmation_error: false,
        password_confirmation_error_message: ""
      });
    }
  }

  on_blur_password_confirmation(){
    if (this.state.typed_password != this.state.typed_password_confirmation){
      this.setState({
        password_confirmation_error: true,
        password_confirmation_error_message: "Passwords don't match."
      });
    }
  }

  confirm_password(){
    fetch("/confirmpassword", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        password: this.state.typed_password,
        email: this.props.email,
        token: this.state.secondary_token
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      if (json.success){
        window.location.href = "/";
      }
    });
  }

  on_key_down_field(e){
    if (e.keyCode === 13){
      if (this.state.typed_password != this.state.typed_password_confirmation){
        this.on_blur_password_confirmation();
      } else {
        this.confirm_password();
      }
    }
  }

  render () {
    console.log("Hello");
    console.log(this.state);
    return ([1,3].includes(this.state.token_type) ? 
      <div>
        <h2>
          Confirm Email Token
        </h2>
        <div>
          <input onChange={this.on_type_token} value={this.state.typed_token} style={{borderColor: this.STATUS_COLOURS[this.state.typed_token_status]}} />
        </div>
        {this.state.stage === 2 ? 
          <div>
            <p>
              Now select a password
            </p>
            {this.state.password_score >= 0 ? 
            <div>
              <div>
                {this.PASSWORD_STRENGTH_DESCRIPTIONS[this.state.password_score]}
              </div>
              <div>
                Approximate time to crack in case of hack: {this.state.password_evaluation === null ? null : this.state.password_evaluation.crack_times_display.offline_slow_hashing_1e4_per_second}
              </div>
              <div>
                Approximate number of guesses required to crack: {this.state.password_evaluation === null ? null : (this.state.password_evaluation.guesses < 1000000 ? this.state.password_evaluation.guesses : <span>{String(this.state.password_evaluation.guesses)[0]}x10<sup>{Math.floor(this.state.password_evaluation.guesses_log10)}</sup></span>)}
              </div>
            </div> : null}
            <div>
              {this.state.password_error ? 
                <div style={{color:'red'}}>
                  {this.state.password_error_message}
                </div> : null}
              <input onChange={this.on_type_password} value={this.state.typed_password} onBlur={this.on_blur_password} placeholder="Password" id="password-field" />
            </div>
            <div>
              {this.state.password_confirmation_error ? 
                <div style={{color: 'red'}}>
                  {this.state.password_confirmation_error_message}
                </div> : null}
              <input onChange={this.on_type_password_confirmation} value={this.state.typed_password_confirmation} onBlur={this.on_blur_password_confirmation} onKeyDown={this.on_key_down_field} placeholder="Confirm password" />
            </div>
          </div> : null}
          {this.state.password_error || this.state.password_confirmation_error ? null : 
            <div>
              <button onClick={this.confirm_password}>
                Confirm
              </button>
            </div>}
      </div> : 
      <div>
        <p>
          Sorry, this account is not awaiting a token confirmation. If you ordered one you will need to send it again.
        </p>
        <button>
          Send again
        </button>
      </div>
    );
  }
}

export default ConfirmEmailToken
