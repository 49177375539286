import React from "react"
import PropTypes from "prop-types"
class MbOverlay extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      show: true,
      opacity: 0
		};

    this.on_click_general = this.on_click_general.bind(this);

  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({
        opacity: 0.5
      });
    }, 100);
  }

  on_click_general(e){
    if (e.target.className === "popup-black-background"){
      this.props.on_cancel();
    }
  }

  render () {
    return ( this.state.show ?
      <div style={{zIndex: 98, backgroundColor: "rgb(0, 0, 0, "+String(this.state.opacity)+")", width: "100%", height: "100%", position: "fixed", top: "0px", left: "0px", transitionProperty: "background-color", transitionDuration: "0.4s"}} onClick={this.on_click_general} className="popup-black-background">
        <div style={{backgroundColor: "#fff", margin: "auto", color: "black", width: this.props.width === undefined ? "90%" : this.props.width, marginTop: "3em", padding: "1.5em", borderRadius: "0.7em"}}>
          <table style={{width: '100%'}}><tbody>
            <tr>
              {this.props.title === undefined || this.props.title === null ? null : 
                <td>
                  <h2>
                    {this.props.title}
                  </h2>
                </td>}
              <td style={{textAlign: 'right', verticalAlign: 'middle', fontSize: "1.4em"}}>
                <span onClick={() => this.props.on_cancel()} style={{cursor: 'pointer'}}>
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </td>
            </tr>
          </tbody></table>
          <div>
            {this.props.children}
          </div>
        </div>
      </div>
    : null);
  }
}

export default MbOverlay
