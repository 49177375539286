import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"
class AccountTextSchemeBilling extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      loaded: false,
      messages: [],
      show_upgrade: false,
      upgrade_selected_index: -1,
      scheme: null,
      show_bill: null
    };

    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    this.MONTHS = ['','January','February','March','April','May','June','July','August','September','October','November','December'];

  }

  componentDidMount(){
    if (![null,undefined].includes(this.props.data)){
      this.setState({
        data: this.props.data,
        loaded: true,
        scheme: JSON.parse(this.props.data.available_plans.filter((p) => p.id === this.props.data.current_bill.billingplan_id)[0].text_scheme)
      });
    } else {
      this.setState({
        loaded: false
      });
    }
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }
  
  render () {
    console.log("ATSB state");
    console.log(this.state);
    if (!this.state.loaded){
      return null;
    }
    return (
      <div>
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        {this.state.show_bill === null ? null : 
          <Overlay on_cancel={() => this.setState({show_bill: null})} title={"Bill starting "+this.MONTHS[this.state.show_bill.month_index_start]+" "+String(this.state.show_bill.year_start)}>
            <div>
              <table className="overlay-bill-table"><tbody>
                <tr>
                  <td>
                    Code
                  </td>
                  <td>
                    {this.state.show_bill.code}
                  </td>
                </tr>
                <tr>
                  <td>
                    Plan code
                  </td>
                  <td>
                    {this.state.show_bill.billingplan.code}
                  </td>
                </tr>
                <tr>
                  <td>
                    Bill status
                  </td>
                  <td>
                    {['UNINITIATED', 'OPEN', 'ISSUED', 'ERROR', 'PAID'][this.state.show_bill.status]}
                  </td>
                </tr>
                <tr>
                  <td>
                    Bill period start
                  </td>
                  <td>
                    {String(new Date(this.state.show_bill.start)).slice(4,15)}
                  </td>
                </tr>
                <tr>
                  <td>
                    Bill period end
                  </td>
                  <td>
                    {String(new Date(this.state.show_bill.end)).slice(4,15)}
                  </td>
                </tr>
                <tr>
                  <td>
                    Total
                  </td>
                  <td>
                    ${this.amount_to_formatted_string(this.state.show_bill.total)}
                  </td>
                </tr>
                <tr>
                  <td>
                    Transactions recorded
                  </td>
                  <td>
                    {this.state.show_bill.transactions}
                  </td>
                </tr>
                <tr>
                  <td>
                    Objects created
                  </td>
                  <td>
                    {this.state.show_bill.objects_created}
                  </td>
                </tr>
              </tbody></table>
              {this.state.show_bill.total > 0 ? 
                <button className="std-button" onClick={() => location.href = this.state.show_bill.invoice_code}>
                  See Invoice
                </button> : null}
            </div>
          </Overlay>}

        {/*[0,1,2,3,4].map(
          (pow, index) =>
          <button onClick={() => {
            let data = this.state.data;
            data.current_bill.transactions += (10**pow);
            this.setState({
              data: data
            });
          }} key={index}>
            {10**pow}
          </button>
        )*/}
        
        
        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <h3>
                Current Billing Period<br />
                <span style={{color: 'grey'}}>{String(new Date(this.state.data.current_bill.start)).slice(4,15)} - {String(new Date(this.state.data.current_bill.end)).slice(4,15)}</span>
              </h3>
            </td>
            <td style={{textAlign: 'right'}}>
              <h3>
                ${this.amount_to_formatted_string(this.state.data.current_bill.total)}<br />
                <span style={{fontSize: '14px', color: 'grey', fontWeight: 'normal'}}>As at {String(new Date(this.state.data.current_bill.time_now)).slice(4,24)}</span>
              </h3>
            </td>
          </tr>
        </tbody></table>

        <div className="billing-tally">
          <div style={{fontSize: '15px', fontFamily: 'Roboto Mono', textDecoration: 'underline'}}>
            Bill # {this.state.data.current_bill.code}
          </div>
          <table><tbody>
            <tr>
              <td>
                Range
              </td>
              <td>
                Price
              </td>
              <td>
                Qty
              </td>
              <td>
                Total
              </td>
            </tr>
            {this.state.scheme.map(
              (tier, index) =>{
                const upto = this.state.scheme.slice(0,index).reduce((a,c) => a + c.max, 0);
                return (
                  <tr key={index}>
                    <td style={{textAlign: 'center'}}>
                      {upto}{index === this.state.scheme.length - 1 ? "+" : "-"+String(tier.max + upto)}
                    </td>
                    <td>
                      {this.amount_to_formatted_string(tier.rate)}
                    </td>
                    <td>
                      {Math.min(Math.max(this.state.data.current_bill.transactions - upto, 0), tier.max)}
                    </td>
                    <td>
                      {this.amount_to_formatted_string(Math.floor(tier.rate * Math.min(Math.max(this.state.data.current_bill.transactions - upto, 0), tier.max)))}
                    </td>
                  </tr>)
              }
            )}
            <tr>
              <td>
                TOTAL
              </td>
              <td>
              </td>
              <td style={{borderTop: "solid", borderTopWidth: "1px", borderTopColor: "black"}}>
                {this.state.data.current_bill.transactions}
              </td>
              <td style={{borderTop: "solid", borderTopWidth: "1px", borderTopColor: "black"}}>
                {/*this.state.scheme.map((c,i) => c.rate*Math.min(Math.max(c.max-this.state.scheme.slice(0,i).reduce((a2,c2) => a2+c2.max,0),0),c.max)).reduce((a,c) => c+a)*/}
                ${this.amount_to_formatted_string(Math.floor(this.state.scheme.map((s,i) => s.rate*Math.max(Math.min(this.state.data.current_bill.transactions - this.state.scheme.slice(0,i).reduce((a,c) => a+c.max,0),s.max),0)).reduce((a2,c2) => a2+c2)))}
              </td>
            </tr>
          </tbody></table>

        </div>

        <table><tbody>
          <tr>
            <td>
              <h3>
                Outstanding Bills
              </h3>
            </td>
          </tr>
        </tbody></table>
        <table className="account-broad-table"><tbody>
          <tr>
            <td>
              Bill code
            </td>
            <td>
              Period
            </td>
            <td>
              Status
            </td>
            <td>
              Amount
            </td>
          </tr>
          {this.state.data.outstanding_bills.length > 0 ? 
            this.state.data.outstanding_bills.map(
              (bill, index) =>
              <tr key={index}>
                <td>
                  <div style={{color: 'grey', cursor: 'pointer', textDecoration: 'underline'}} onClick={() => this.setState({show_bill: bill})}>{bill.code}</div>
                </td>
                <td>
                  {String(new Date(bill.start)).slice(4,15)} - {String(new Date(bill.end)).slice(4,15)}
                </td>
                <td>
                  {['UNINITIATED', 'OPEN', 'ISSUED', 'ERROR', 'PAID'][bill.status]}
                </td>
                <td>
                  ${this.amount_to_formatted_string(bill.total)}
                </td>
              </tr>
            ) : 
            null}
        </tbody></table>
        {this.state.data.outstanding_bills.length === 0 ? 
          <div style={{fontSize: '14px', color: 'grey', textAlign: 'center'}}>
            You have no outstanding bills.
          </div> : null}

        <table><tbody>
          <tr>
            <td>
              <h3>
                Previous Bills
              </h3>
            </td>
          </tr>
        </tbody></table>
        <table className="account-broad-table"><tbody>
          {this.state.data.previous_bills.length > 0 ? 
            <tr>
              <td>
                Bill code
              </td>
              <td>
                Period
              </td>
              <td>
                Status
              </td>
              <td>
                Amount
              </td>
            </tr> : null}
          {this.state.data.previous_bills.length > 0 ? 
            this.state.data.previous_bills.map(
              (bill, index) =>
              <tr key={index}>
                <td>
                  <div style={{color: 'grey', cursor: 'pointer', textDecoration: 'underline'}} onClick={() => this.setState({show_bill: bill})}>{bill.code}</div>
                </td>
                <td>
                  {String(new Date(bill.start)).slice(4,15)} - {String(new Date(bill.end)).slice(4,15)}
                </td>
                <td>
                  {['UNINITIATED', 'OPEN', 'ISSUED', 'ERROR', 'PAID'][bill.status]}
                </td>
                <td>
                  ${this.amount_to_formatted_string(bill.total)}
                </td>
              </tr>
            ) : 
            null}
        </tbody></table>
        {this.state.data.previous_bills.length === 0 ? 
          <div style={{fontSize: '14px', color: 'grey', textAlign: 'center'}}>
            You have no previous bills.
          </div> : null}
        
      </div>
    );
  }
}

export default AccountTextSchemeBilling
