import React from "react"
import PropTypes from "prop-types"
class Test2 extends React.Component {
  render () {
    return (
      <div>
        Test 2 component!
      </div>
    );
  }
}

export default Test2
