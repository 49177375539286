import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
import Checkbox from "./Checkbox.js"
import Overlay from "./Overlay.js"

class EditFund extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      typed_title: "New Fund",
      typed_title_status: 0,
      typed_title_error: null,
      typed_amount: "$1.00",
      displayed_amount: "$1.00",
      amount: 100,
      typed_amount_status: 0,
      typed_amount_error: null,
      typed_description: "",
      has_goal: true,
      start_date: (new Date(Date.now())).toISOString().slice(0,10),
      start_date_status: 0,
      start_date_error: null,
      has_end_date: true,
      end_date: (new Date(Date.now() + 30*86400000)).toISOString().slice(0,10),
      end_date_status: 0,
      end_date_error: null,
      ref_preference: 1,
      show_confirmation: false,
      accounts: JSON.parse(this.props.accounts),
      selected_account_index: 0,
      has_passkey: false,
      passkey: "",
      passkey_status: 0,
      passkey_error: "",
      random_passkey_length: 6,
      goal_is_visible: false,
      amount_is_visible: false,
      payments_are_visible: false,
      hide_at_completion: false,
      show_payid: true,
      updating: false,
      messages: []
    };

    this.on_type_amount = this.on_type_amount.bind(this);
    this.on_focus_amount = this.on_focus_amount.bind(this);
    this.on_blur_amount = this.on_blur_amount.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.on_type_title = this.on_type_title.bind(this);
    this.on_blur_title = this.on_blur_title.bind(this);
    this.is_valid_amount_input = this.is_valid_amount_input.bind(this);
    this.on_type_description = this.on_type_description.bind(this);
    this.on_change_start_date = this.on_change_start_date.bind(this);
    this.on_blur_start_date = this.on_blur_start_date.bind(this);
    this.on_change_end_date = this.on_change_end_date.bind(this);
    this.on_blur_end_date = this.on_blur_end_date.bind(this);
    this.can_proceed = this.can_proceed.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.submit = this.submit.bind(this);

    this.MINIMUM_TITLE_LENGTH = 5;
    this.MAXIMUM_TITLE_LENGTH = 30;
    this.MINIMUM_AMOUNT = 100;
    this.MAXIMUM_AMOUNT = 1000000000;
    this.MAXIMUM_DESCRIPTION_LENGTH = 500;
    this.MINIMUM_PASSKEY_LENGTH = 4;
    this.MAXIMUM_PASSKEY_LENGTH = 16;
    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  componentDidMount(){
    this.retrieve_details();
  }

  retrieve_details(){
    fetch("/api/fund/"+this.props.fund_code+"/admin", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log("json");
      console.log(json);
      this.setState({

        typed_title: json.fund.name,
        //typed_title_status: 0,
        //typed_title_error: null,
        typed_amount: '$'+this.amount_to_formatted_string(json.fund.goal),
        displayed_amount: '$'+this.amount_to_formatted_string(json.fund.goal),
        amount: json.fund.goal,
        //typed_amount_status: 0,
        //typed_amount_error: null,
        typed_description: json.fund.description,
        has_goal: json.fund.has_goal,
        start_date: json.fund.has_goal ? json.fund.start_date.split('T')[0] : this.state.start_date,
        //start_date_status: 0,
        //start_date_error: null,
        has_end_date: json.fund.has_end_date,
        end_date: json.fund.has_end_date ? json.fund.end_date.split('T')[0] : this.state.end_date,
        //end_date_status: 0,
        //end_date_error: null,
        ref_preference: json.fund.ref_preference,
        selected_account_index: this.state.accounts.map((a,i) => a.id === json.fund.account_id ? i : -1).filter((v) => v >= 0)[0] || 0,
        //show_confirmation: false

        /* put these info fund.info in Rails */
        has_passkey: json.fund.has_passkey,
        passkey: json.fund.passkey,
        /*passkey_status: 0,
        passkey_error: "",
        random_passkey_length: 6,*/
        goal_is_visible: json.fund.goal_is_visible,
        amount_is_visible: json.fund.amount_is_visible,
        payments_are_visible: json.fund.payments_are_visible,
        hide_at_completion: json.fund.hide_at_completion,
        show_payid: json.fund.show_payid
        
        /*typed_title: json.invoice.title,
        amount: json.invoice.amount,
        typed_amount: '$'+this.amount_to_formatted_string(json.invoice.amount),
        displayed_amount: '$'+this.amount_to_formatted_string(json.invoice.amount),
        typed_description: json.invoice.description,
        billing_name: json.invoice.billing_name,
        billing_email: json.invoice.billing_email,
        include_email: json.invoice.billing_email !== null,
        date_accrued_at: this.date_rails_to_html(json.invoice.date_accrued_at.slice(5,16)),
        has_due_date: json.invoice.due_at !== null,
        due_at: json.invoice.due_at === null ? null : this.date_rails_to_html(json.invoice.due_at.slice(5,16)),
        custom_identifier_1: json.invoice.custom_identifier_1,
        custom_identifier_2: json.invoice.custom_identifier_2,
        init_loaded: true,
        remember_viewers: json.invoice.remember_viewers,
        passkey: json.invoice.passkey,
        ref_preference: json.invoice.ref_preference,

        typed_title_status: 2,
        typed_amount_status: 2,
        billing_name_status: 2,
        billing_email_status: 2,
        due_at_status: 2,
        date_accrued_status: 2,
        late_fee_rate_status: 2,
        grace_period_status: 2*/
      });
    });
  }

  on_type_amount(e){
    const typed = e.target.value;
    /*if (typed === '.' || /^\d{0,3}(?:\.\d{1,2})?$|^\d{1,3}(?:\.\d{0,1})?$|^\d{1}(?:\.\d{0,2})?$|^\.\d{1,3}$/.test(typed) || typed.length === 0){
      this.setState({
        typed_amount: typed
      });
    }*/
    if (typed === '.' || this.is_valid_amount_input(typed) || typed.length === 0){
      this.setState({
        typed_amount: typed
      });
    }
  }

  on_focus_amount(){
    this.setState({
      typed_amount: this.state.typed_amount.replaceAll('$','').replaceAll(',',''),
      typed_amount_status: 1
    }, () => {
      document.getElementById('amount-input').select();
    });
    setTimeout(() => {
      document.getElementById('amount-input').select();
    }, 50);
  }

  on_blur_amount(){
    let amount = this.state.typed_amount.split('.').map((x,i) => i === 0 ? Number(x) * 100 : Number(x[0])*10+(x[1]===undefined ? 0 : Number(x[1]))).reduce((p,a) => p+a, 0);
    if (this.state.typed_amount === '.'){
      amount = 0;
    }
    const str = String(amount / 100);
    console.log("Amount: "+amount);
    this.setState({
      //typed_amount: '$' + (Number(amount) < 100 ? '0.'+(Number(amount.slice(-2)) < 10 ? '0' : '')+String(amount) : amount.slice(0, amount.length-2)+'.'+(Number(amount.slice(-2)) < 10 ? '0' : '')+Number(amount.slice(-2))),
      typed_amount: '$' + this.amount_to_formatted_string(amount),
      displayed_amount: '$' + this.amount_to_formatted_string(amount),
      amount: amount,
      typed_amount_status: amount < this.MINIMUM_AMOUNT || amount > this.MAXIMUM_AMOUNT ? 3 : 2,
      typed_amount_error: amount < this.MINIMUM_AMOUNT || amount > this.MAXIMUM_AMOUNT ? "The amount must be between $"+(this.MINIMUM_AMOUNT/100)+" and $"+(this.MAXIMUM_AMOUNT/100000000)+" million." : null
    });
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  on_type_title(e){
    const typed = e.target.value;
    if (typed.length < this.MAXIMUM_TITLE_LENGTH){
      this.setState({
        typed_title: typed
      });
    }
  }

  on_blur_title(){
    const typed = this.state.typed_title.trim();
    if (typed.length < this.MINIMUM_TITLE_LENGTH || typed.length > this.MAXIMUM_TITLE_LENGTH){
      this.setState({
        typed_title: typed,
        typed_title_status: 3,
        typed_title_error: "Title must be between "+String(this.MINIMUM_TITLE_LENGTH)+" and "+String(this.MAXIMUM_TITLE_LENGTH)+" characters."
      });
    } else {
      this.setState({
        typed_title: typed,
        typed_title_status: 2,
        typed_title_error: null
      });
    }
  }

  is_valid_amount_input(input) {
    console.log("testing "+input);
    if (input === "." || input === "") {
        return true;
    }
    const parts = input.split(".");
    if (parts.length === 1){
      return !isNaN(parts[0]);
    } else if (parts.length > 2){
      return false;
    }
    return !isNaN(parts[0]) && !isNaN(parts[1]) && parts[1].length <= 2;
  }

  on_type_description(e){
    const typed = e.target.value;
    if (typed.length <= this.MAXIMUM_DESCRIPTION_LENGTH){
      this.setState({
        typed_description: typed
      });
    }
  }

  on_change_start_date(e){
    this.setState({
      start_date: e.target.value
    });
  }

  on_blur_start_date(){
    const diff = Date.now() - (new Date(this.state.start_date)) - (new Date()).getTimezoneOffset()*60000;
    const diff2 = (new Date(this.state.end_date)) - (new Date(this.state.start_date));
    if (diff > 86400000){
      this.setState({
        start_date_status: 3,
        start_date_error: "Due date cannot be in the past."
      });
    } else if (diff2 < 0){
      this.setState({
        end_date_status: 3,
        end_date_error: "Start date must precede end date."
      });
    } else {
      this.setState({
        start_date_status: 2,
        start_date_error: null
      });
    }
  }


  on_change_end_date(e){
    this.setState({
      end_date: e.target.value
    });
  }

  on_blur_end_date(){
    const diff = Date.now() - (new Date(this.state.end_date)) - (new Date()).getTimezoneOffset()*60000;
    const diff2 = (new Date(this.state.end_date)) - (new Date(this.state.start_date));
    if (diff > 86400000){
      this.setState({
        end_date_status: 3,
        end_date_error: "Due date cannot be in the past."
      });
    } else if (diff2 < 0) {
      this.setState({
        end_date_status: 3,
        end_date_error: "Start date must precede end date."
      });
    } else {
      this.setState({
        end_date_status: 2,
        end_date_error: null
      });
    }
  }

  can_proceed(){
    return [0,2].includes(this.state.typed_title_status) &&
      (!this.state.has_goal || [0,2].includes(this.state.typed_amount_status)) &&
      [0,2].includes(this.state.start_date_status) &&
      (!this.state.has_end_date || [0,2].includes(this.state.end_date_status));
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  submit(){
    /*let body = {
      fund: {
        name: this.state.typed_title,
        description: this.state.typed_description,
        goal: this.state.has_goal ? this.state.amount : null,
        start_date: this.state.start_date + " 00:00:00 +"+String(Math.round((-100.0*(new Date()).getTimezoneOffset() / 60.0))),
        has_end_date: this.state.has_end_date,
        ref_preference: this.state.ref_preference
      }
    }
    if (this.state.has_end_date){
      body['fund']['end_date'] = this.state.end_date + " 00:00:00 +" + String(Math.round((-100.0*(new Date()).getTimezoneOffset() / 60.0)));
    }*/
    this.setState({
      updating: true
    });
    let body = {
      fund: {
        name: this.state.typed_title,
        description: this.state.typed_description,
        goal: this.state.has_goal ? this.state.amount : null,
        start_date: this.state.start_date + " 00:00:00 +"+String(Math.round((-100.0*(new Date()).getTimezoneOffset() / 60.0))),
        has_end_date: this.state.has_end_date,
        ref_preference: this.state.ref_preference,
        privacy_status: this.state.has_passkey ? 1 : 0,
        passkey_ciphertext: this.state.passkey,
        hide_at_completion: this.state.hide_at_completion,
        goal_is_visible: this.state.goal_is_visible,
        amount_is_visible: this.state.amount_is_visible,
        payments_are_visible: this.state.payments_are_visible,
        account_id: this.state.accounts[this.state.selected_account_index].id,
        show_payid: this.state.show_payid
      },
    }
    if (this.state.has_end_date){
      body['fund']['end_date'] = this.state.end_date + " 00:00:00 +" + String(Math.round((-100.0*(new Date()).getTimezoneOffset() / 60.0)));
    }
    fetch("/api/fund/"+this.props.fund_code+"/edit", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Error encountered when trying to update Fund",'danger');
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.setState({updating: false});
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.setState({
        updating: false
      });
      if (json.success){
        this.add_message("Successfully updated Fund.",'success');
      } else {
        this.add_message("Failed to update Fund.",'danger');
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }


  render () {
    console.log(this.state);
    return (
      <div className="form-section">

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        {this.state.show_confirmation ? 
          <Overlay on_cancel={() => this.setState({show_confirmation: false})}>
            <div>
              <h3 style={{color: 'grey'}}>
                Confirm <span style={{color: 'black'}}>{this.state.typed_title}</span>
              </h3>
              <table id="confirmation-table"><tbody>
                {this.state.has_goal ? 
                  <tr>
                    <td>
                      Goal
                    </td>
                    <td>
                      <b>{this.state.displayed_amount}</b>
                    </td>
                  </tr> : null}
                <tr>
                  <td>
                    Description
                  </td>
                  <td>
                    {this.state.typed_description.length > 100 ? this.state.typed_description.slice(0,100)+"..." : this.state.typed_description}
                  </td>
                </tr>
                <tr>
                  <td>
                    Title
                  </td>
                  <td>
                    <b>{this.state.typed_title}</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    Start date
                  </td>
                  <td>
                    {this.state.start_date}
                  </td>
                </tr>
                {this.state.has_end_date ? 
                  <tr>
                    <td>
                      End date
                    </td>
                    <td>
                      {this.state.end_date}
                    </td>
                  </tr> : null}
                <tr>
                  <td>
                    Ref Preference
                  </td>
                  <td>
                    {["Single reference code",
                      "Allow email-unique references",
                      "Require email-unique references"][this.state.ref_preference]}
                  </td>
                </tr>
              </tbody></table>
              <button className="std-button" onClick={() => this.submit()}>
                Confirm
              </button>
              <button className="std-button" onClick={() => this.setState({show_confirmation: false})}>
                Cancel
              </button>
            </div>
          </Overlay> : null}
        
        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
            <h2>
              {this.state.typed_title}
            </h2>
            </td>
            <td style={{textAlign: 'right'}}>
              <h2 style={{color: 'grey'}}>
                {this.state.has_goal ? this.state.displayed_amount : null}
              </h2>
            </td>
          </tr>
        </tbody></table>

        <div className="form-intra-section">
          <div className="myinput-wrapper-centre">
            <MyInput onChange={this.on_type_title} value={this.state.typed_title} onBlur={this.on_blur_title} status={this.state.typed_title_status} note={this.state.typed_title_status === 3 ? this.state.typed_amount_error : null} label="Title of the Fund" />
          </div><br />
          {/*<div style={{margin: 'auto', width: '260px', margin: 'auto', marginBottom: '10px'}}>
            <table><tbody>
              <tr>
                <td>
                  <Checkbox on_toggle={(v) => this.setState({has_goal: v})} init_value={this.state.has_goal} />
                </td>
                <td style={{fontSize: '16px', paddingLeft: '10px'}}>
                  Has goal
                </td>
              </tr>
            </tbody></table>
          </div>
          {this.state.has_goal ? <div className="myinput-wrapper-centre">
            <MyInput onChange={this.on_type_amount} value={this.state.typed_amount} onFocus={this.on_focus_amount} onBlur={this.on_blur_amount} status={this.state.typed_amount_status} note={this.state.typed_amount_status === 3 ? this.state.typed_amount_error : null} label="Goal you wish to raise" id="amount-input" style={{textAlign: 'right'}} />
          </div> : null}<br />*/}
          <div className="myinput-wrapper-centre">
            <div style={{color: 'grey', fontSize: '13px', marginBottom: '4px', textAlign: 'left'}}>
              Description
            </div>
            <textarea onChange={this.on_type_description} value={this.state.typed_description} className="standard-textarea" />
            <div style={{color: 'grey', fontSize: '13px', marginBottom: '4px', textAlign: 'left'}}>
              {this.MAXIMUM_DESCRIPTION_LENGTH - this.state.typed_description.length}
            </div>
          </div>
        </div>

        <div className="form-intra-section">
          <h3>
            Goal
          </h3>
          <table className="toggle-table"><tbody>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({has_goal: v})} init_value={this.state.has_goal} />
              </td>
              <td>
                <h4>
                  Has Goal
                </h4>
                <p>
                  This is the monetary amount you want to achieve. If this is an ongoing process or there is otherwise no need to limit the funds coming in, untick this box.
                </p>
              </td>
              <td>
                {this.state.has_goal ? 
                  <div>
                    <MyInput onChange={this.on_type_amount} value={this.state.typed_amount} onFocus={this.on_focus_amount} onBlur={this.on_blur_amount} status={this.state.typed_amount_status} note={this.state.typed_amount_status === 3 ? this.state.typed_amount_error : null} label="Goal you wish to raise" id="amount-input" style={{textAlign: 'right'}} />
                  </div> : null}
              </td>
            </tr>
          </tbody></table>
        </div>

        <div>
          <h3>
            Deposit Account
          </h3>
          <div className={"flash flash-warning"}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON['warning']}></i>
                </td>
                <td>
                  It is unadvisable to change the deposit account after creating the Fund, especially if account details have already been shared. Monies deposited into the old account will not be accounted to this Fund.
                </td>
              </tr>
            </tbody></table>
          </div>
          <p style={{color: 'grey', fontSize: '14px'}}>
            This is the account that the payor is told to deposit the funds into.
          </p>
          {this.state.accounts.map(
            (account, index) =>
            <div className={this.state.selected_account_index === index ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({selected_account_index: index})} key={index}>
              <table><tbody>
                <tr>
                  <td>
                    <div>
                      <div />
                    </div>
                  </td>
                  <td>
                    <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                      {account.name}
                    </div>
                    <table style={{color: 'grey', fontSize: '16px', fontFamily: 'Roboto Mono'}}><tbody>
                      <tr>
                        <td style={{}}>
                          BSB:{account.bsb}
                        </td>
                        <td style={{}}>
                          Acct:{account.account_number}
                        </td>
                      </tr>
                    </tbody></table>
                  </td>
                </tr>
              </tbody></table>
            </div>
          )}
        </div>

        <div className="form-intra-section">
          <h3>
            Dates
          </h3>
          <table className="toggle-table"><tbody>
            <tr>
              <td>
                
              </td>
              <td>
                <h4>
                  Start Date
                </h4>
                <p>
                  Date when you will start collecting contributions.
                </p>
              </td>
              <td>
                <div>
                  <MyInput type="date" onChange={this.on_change_start_date} value={this.state.start_date} status={this.state.start_date_status} onBlur={this.on_blur_start_date} label="Start date" note={this.state.start_date_error} />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({has_end_date: v})} init_value={this.state.has_end_date} />
              </td>
              <td>
                <h4>
                  Has End Date
                </h4>
                <p>
                  Date when contributions will cease being collected.
                </p>
              </td>
              <td>
                {this.state.has_end_date ? 
                  <div>
                    <MyInput type="date" onChange={this.on_change_end_date} value={this.state.end_date} status={this.state.end_date_status} onBlur={this.on_blur_end_date} label="End date" note={this.state.end_date_error} />
                  </div> : null}
              </td>
            </tr>
          </tbody></table>
        </div>

        <div>
          <h3>
            Privacy and Security
          </h3>
          <table className="toggle-table-general"><tbody>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({has_passkey: v})} init_value={this.state.has_passkey} />
              </td>
              <td>
                <h3>
                  Has passkey
                </h3>
                <p>
                  A passkey in addition to a URL is required to view the invoice.
                </p>
              </td>
            </tr>
            {this.state.has_passkey ? <tr>
              <td></td>
              <td>
                  <div>
                    <MyInput onChange={this.on_type_passkey} value={this.state.passkey} status={this.state.passkey_status} id="passkey-input" onFocus={this.on_focus_passkey} onBlur={this.on_blur_passkey} label="Passkey" note={this.state.passkey_status === 3 ? this.state.passkey_error : null} />
                    <table><tbody>
                      <tr>
                        <td>
                          <button className="std-button" onClick={this.random_passkey} style={{marginTop: '10px'}}>
                            Random
                          </button>
                        </td>
                        <td style={{paddingTop: '15px'}}>
                          <div>
                            <input type="range" min="4" max="16" onChange={(e) => this.setState({random_passkey_length: Number(e.target.value)})} value={String(this.state.random_passkey_length)} style={{width: '100%', margin: 'auto'}} />
                          </div>
                          <div style={{textAlign: 'center', fontSize: '13px', color: 'grey'}}>
                            {this.state.random_passkey_length} characters
                          </div>
                        </td>
                      </tr>
                    </tbody></table>
                  </div>
                {/*this.state.has_passkey ? 
                <div>
                  <table><tbody>
                    <tr>
                      <td style={{padding: '5px', width: '30px'}}>
                        <div className={freq === this.state.late_fee_period_days ? "standard-radio-checked" : "standard-radio"} onClick={() => this.setState({late_fee_period_days: freq})}>
                          <div />
                        </div>
                      </td>
                      <td style={{fontSize: '14px', color: 'grey', padding: '5px', verticalAlign: 'center'}}>
                        <MyInput onChange={this.on_type_passkey} />
                      </td>
                    </tr>
                  </tbody></table>
                </div> : null*/}
              </td>
            </tr> : null}
            {/*this.state.has_passkey ? 
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({remember_viewers: v})} init_value={this.state.remember_viewers} />
              </td>
              <td>
                <h4>
                  Remember viewer
                </h4>
                <p>
                  Remember a viewer's device so that they don't need to enter the passkey again. If you require the highest level of security, ensure this is unselected.
                </p>
              </td>
              <td>
                
              </td>
            </tr> : null*/}
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({hide_at_completion: v})} init_value={this.state.hide_at_completion} />
              </td>
              <td>
                <h3>Hide at completion</h3>
                <p>
                  Stop the visibility of the invoice once it has been paid or otherwise completed.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({show_payid: v})} init_value={this.state.show_payid} />
              </td>
              <td>
                <h3>Show PayID</h3>
                <p>
                  Show PayID as a payment method.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({goal_is_visible: v})} init_value={this.state.goal_is_visible} />
              </td>
              <td>
                <h3>Goal is visible</h3>
                <p>
                  Allow people who can see the fund to be able to see the goal.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({amount_is_visible: v})} init_value={this.state.amount_is_visible} />
              </td>
              <td>
                <h3>Amount is visible</h3>
                <p>
                  Allow people who can see the fund to be able to see the amount that has been raised.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({payments_are_visible: v})} init_value={this.state.payments_are_visible} />
              </td>
              <td>
                <h3>Payments are visible</h3>
                <p>
                  Allow people who can see the fund to be able to see superficial information about the payments that have been made to this fund. They will not be able to see who made the payment.
                </p>
              </td>
            </tr>
          </tbody></table>
        </div>

        <div style={{marginTop: '40px'}}>
          <h3>
            Customer Reference Preferences
          </h3>
          <p style={{color: 'grey', fontSize: '14px'}}>
            This will determine whether a single reference is generated, or one for each email instance provided by a customer.
          </p>

          <div className={this.state.ref_preference === 0 ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({ref_preference: 0})}>
            <table><tbody>
              <tr>
                <td>
                  <div>
                    <div />
                  </div>
                </td>
                <td>
                  <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                    Single Reference Code
                  </div>
                  <p style={{color: "grey", fontSize: "14px"}}>
                    Payor(s) will not be able to obtain a unique reference.
                  </p>
                </td>
              </tr>
            </tbody></table>
          </div>
          <div className={this.state.ref_preference === 1 ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({ref_preference: 1})}>
            <table><tbody>
              <tr>
                <td>
                  <div>
                    <div />
                  </div>
                </td>
                <td>
                  <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                    Allow email-unique references
                  </div>
                  <p style={{color: "grey", fontSize: "14px"}}>
                    Payors can register an email and obtain a unique reference for payment.
                  </p>
                </td>
              </tr>
            </tbody></table>
          </div>
          <div className={this.state.ref_preference === 2 ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({ref_preference: 2})}>
            <table><tbody>
              <tr>
                <td>
                  <div>
                    <div />
                  </div>
                </td>
                <td>
                  <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                    Require email-unique references
                  </div>
                  <p style={{color: "grey", fontSize: "14px"}}>
                    Payors must register an email and obtain a unique reference for payment.
                  </p>
                </td>
              </tr>
            </tbody></table>
          </div>

          {/*<table className="toggle-table"><tbody>
            <tr>
              <td>

              </td>
              <td>
                <h4>
                  Customer Reference
                </h4>
                <p>
                  This will determine whether a single reference is generated, or one for each email instance provided by a customer.
                </p>
              </td>
              <td>
                <table><tbody>
                  <tr>
                    <td style={{padding: '5px', width: '30px'}}>
                      <div className={this.state.ref_preference === 0 ? "standard-radio-checked" : "standard-radio"} onClick={() => this.setState({ref_preference: 0})}>
                        <div />
                      </div>
                    </td>
                    <td style={{fontSize: '14px', color: 'grey', padding: '5px', verticalAlign: 'center'}}>
                      <b>Single reference code</b>
                      <p>
                        Payor(s) will not be able to obtain a unique reference.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{padding: '5px', width: '30px'}}>
                      <div className={this.state.ref_preference === 1 ? "standard-radio-checked" : "standard-radio"} onClick={() => this.setState({ref_preference: 1})}>
                        <div />
                      </div>
                    </td>
                    <td style={{fontSize: '14px', color: 'grey', padding: '5px', verticalAlign: 'center'}}>
                      <b>Allow email-unique references</b>
                      <p>
                        Payors can register an email and obtain a unique reference for payment.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{padding: '5px', width: '30px'}}>
                      <div className={this.state.ref_preference === 2 ? "standard-radio-checked" : "standard-radio"} onClick={() => this.setState({ref_preference: 2})}>
                        <div />
                      </div>
                    </td>
                    <td style={{fontSize: '14px', color: 'grey', padding: '5px', verticalAlign: 'center'}}>
                      <b>Require email-unique references</b>
                      <p>
                        Payors must register an email and obtain a unique reference for payment.
                      </p>
                    </td>
                  </tr>
                </tbody></table>
              </td>
            </tr>
                </tbody></table>*/}
        </div>

        {this.can_proceed() ? 
          <button onClick={this.submit} className="std-button">
            {this.state.updating ? "Updating" : "Update"}
          </button> : null}

      </div>
    );
  }
}

export default EditFund
