import React from "react"
import PropTypes from "prop-types"
class LeftPanelPayor extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      tabs: {
        'top':{
          name: null,
          selected: false,
          url: null
        },
        'home':{
          name: 'Home',
          selected: false,
          url: '/',
          icon: 'house'
        },
        'account':{
          name:'Account',
          selected: false,
          icon: 'circle-user',
          url: '/myaccount'
        },
        'payments':{
          name: 'PAYMENTS',
          selected: false,
          url: null
        },
        'series':{
          name: 'Bill Series',
          selected: false,
          icon: 'link',
          url: '/p/series/index' // *
        },
        'disputes':{
          name: 'Disputes',
          selected: false,
          icon: 'gavel',
          url: '/p/disputes/index' // *
        },
        'objects':{
          name:'OBJECTS',
          selected: false
        },
        'invoices':{
          name:'Invoices',
          selected: false,
          url: '/invoices/index', // *
          icon: 'file-invoice'
        },
        'customers':{
          name:'Customers',
          selected: false,
          icon: 'users',
          url: '/customers/index' // *
        },
        'funds':{
          name:'Funds',
          selected: false,
          icon: 'filter-circle-dollar',
          url: '/funds/index' // *
        }
      },
      tree: [
        {
          code: 'top',
          children: [{
            code: 'home',
            children: []
          },{
            code: 'account',
            children: []
          }]
        },{
          code: 'payments',
          children: [{
            code: 'series',
            children: []
          },{
            code: 'disputes',
            children: []
          }]
        },{
          code: 'objects',
          children: [{
            code: 'invoices',
            children: []
          },{
            code: 'funds',
            children: []
          },{
            code: 'customers',
            children: []
          }]
        }
      ],
      urls:{
        '/':['home'],
        '/myaccount':['account']
      }
    };

    this.MAIN_COLOUR = '#040dba';
    this.SECONDARY_COLOUR = '#32bfb8';//'#26d463';
    this.URL_KEYWORDS = ['invoice','invoices','new','edit','admin','index','search','funds','fund','upload','customer','customers','myaccount','bankaccounts','consents','series','ser','disputes'];

  }

  componentDidMount(){
    let tabs = this.state.tabs;
    const mod_pathname = window.location.pathname.split('/').map((x, i) => i === 0 ? '' : (!this.URL_KEYWORDS.includes(x) ? ':param' : x)).join('/'); // previously the test was x.length > 15
    console.log('mod_pathname');
    console.log(mod_pathname);
    let selected_tabs = this.state.urls[mod_pathname];
    if (selected_tabs === undefined){
      // lift state up to Header.js to remove this and... change header colour?
    } else {
      selected_tabs.forEach((tab) => tabs[tab].selected = true);
    }
    /*tabs['developer'].selected = true;
    tabs['apikeys'].selected = true;*/
    this.setState({
      tabs: tabs
    });
  }

  render () {
    return (
      <div style={{marginTop: '20px'}}>
        {this.state.tree.map(
          (tab1, index1) =>
          <div key={index1}>
            <div style={{color: '#7a7fe6', fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px', marginTop: '12px', cursor: 'default', marginBottom: '3px'}}>
              {this.state.tabs[tab1.code] === undefined ? null : this.state.tabs[tab1.code].name}
            </div>
            {tab1.children.map(
              (tab2, index2) =>
              <div key={index2}>
                <div style={{color: this.state.tabs[tab2.code].selected ? '#fff' : '#fff', /*backgroundColor: this.state.tabs[tab2.code].selected ? this.SECONDARY_COLOUR : this.MAIN_COLOUR,*/ padding: '6px', fontSize: '13px', margin: '4px', marginTop: '0px', borderRadius: '5px', fontWeight: 'bold', cursor: 'pointer'}} className={this.state.tabs[tab2.code].selected ? "left-panel-item-selected" : "left-panel-item"} onClick={() => this.state.tabs[tab2.code].url === undefined ? null : (location.href = this.state.tabs[tab2.code].url)}>
                  <table><tbody>
                    <tr>
                      <td style={{width: '26px', textAlign: 'center', fontSize: '16px'}}>
                        {this.state.tabs[tab2.code] === undefined ? null : (this.state.tabs[tab2.code].icon === undefined ? null : <i className={"fa-solid fa-"+this.state.tabs[tab2.code].icon}></i>)}
                      </td>
                      <td>
                        {this.state.tabs[tab2.code] === undefined ? null : this.state.tabs[tab2.code].name} {tab2.name}
                      </td>
                    </tr>
                  </tbody></table>
                </div>
                {this.state.tabs[tab2.code].selected ? tab2.children.map(
                  (tab3, index3) => 
                  <div style={{color: this.state.tabs[tab3.code].selected ? '#fff' : '#fff', /*backgroundColor: this.state.tabs[tab3.code].selected ? '#fff' : this.MAIN_COLOUR,*/ padding: '5px', paddingLeft: '8px', fontSize: '12px', margin: '2px', marginRight:'4px', marginLeft:'20px', marginTop: '0px', borderRadius: '5px', cursor: 'pointer'}} key={index3} className={this.state.tabs[tab3.code].selected ? "left-panel-item-selected" : "left-panel-item"} onClick={() => this.state.tabs[tab3.code].url === undefined ? null : (location.href = this.state.tabs[tab3.code].url)}>
                    {this.state.tabs[tab3.code] === undefined ? null : this.state.tabs[tab3.code].name}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default LeftPanelPayor
