import React from "react"
import PropTypes from "prop-types"
import Toggle from "./Toggle.js"
import Overlay from "./Overlay.js"
import MyInput from "./MyInput.js"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement, // doughtnut
} from 'chart.js';
import { Line } from 'react-chartjs-2';

class AdminFund extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      messages: [],
      fund: null,
      selected_transfer_index: -1,
      init_loaded: false,
      payment_history: {
        show: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: false,
              text: 'Payments',
            }
          },
          scales: {
            yAxis1: {
              title: {
                display: true,
                text: '$ payment'
              },
              suggestedMin: 0,
              suggestedMax: 10
            }
          }
        },
        data: {
          labels: [],
          datasets: [
            {
              label: 'Payment',
              data: [],
              borderColor: '#32bfb8',
              backgroundColor: '#efefef',
              yAxisID: 'yAxis1'
            }
          ]
        }
      },
      show_complete_screen: false,
      show_status_screen: false,
      selected_status: 0,
      loading: false,
      visibility_changed: false,
      show_delete: false,
      typed_delete_code: "",
      typed_delete_code_status: 0,
      typed_delete_code_message: "",
      typed_tag: ""
    };

    this.retrieve_details = this.retrieve_details.bind(this);
    this.time_values_to_chart_data = this.time_values_to_chart_data.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.unix_to_formatted_date = this.unix_to_formatted_date.bind(this);
    this.unix_to_formatted_time = this.unix_to_formatted_time.bind(this);
    this.on_type_tag = this.on_type_tag.bind(this);
    this.on_key_down_tag = this.on_key_down_tag.bind(this);
    this.update_tags = this.update_tags.bind(this);
    this.remove_tag = this.remove_tag.bind(this);
    this.admin_update = this.admin_update.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.click_to_copy = this.click_to_copy.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    this.FUND_STATUS_TO_WORD = {'0':'INITIATE','1':'OPEN','2':'COMPLETE','3':'SUSPEND','4':'COMPLETE'};
    this.FUND_STATUS_TO_TITLE = {'0':'Fund Initiated','1':'Fund Collecting Payments','2':'Fund Completed Naturally','3':'Fund Suspended by User','4':'Fund Completed by Force'};
    this.FUND_STATUS_TO_DESCRIPTION = {'0':'Fund has been initiated but has not been opened yet to contributions.',
      '1':'Fund is open to collecting payments for anyone with the link and credentials (if applicable).',
      '2':'Fund is completed, either because it has reached its goal or has reached its end date',
      '3':'Fund has been suspended by you and is not visible to customers.',
      '4':'Fund has been completed by your request, irrespective of whether it has reached the goal or end date.'};
    this.FUND_STATUS_TO_COLOUR = {'0':'#040dba','1':'#040dba','2':'#32bfb8','3':'#e6a330','4':'#32bfb8'};
    this.FUND_STATUS_TO_ADMIN_ACTION = {'1':'OPEN','3':'SUSPEND','4':'SET_AS_COMPLETE'};
    
  }

  componentDidMount(){
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend,
      ArcElement
    );
    this.retrieve_details();
  }

  retrieve_details(){
    // /api/fund/:code
    fetch("/api/fund/"+this.props.fund_code+"/admin", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      
      throw new Error('Request fail');
    }).then(json => {
      console.log(json.fund);
      this.setState({
        fund: json.fund,
        selected_status: json.fund === undefined ? this.state.selected_status : json.fund.status,
        init_loaded: true
      });
      let pc_data = this.time_values_to_chart_data(json.fund.transfers.map((t,i) => ({time:t.transferred_at,value:t.amount / 100})), json.fund.start_date_int, Math.round(Date.now() / 1000));
      let pc_state = this.state.payment_history;
      pc_state.show = true;
      pc_state.data.labels = pc_data.labels;
      pc_state.data.datasets[0].data = pc_data.data;
      this.setState({
        payment_history: pc_state,
        init_loaded: true
      });
    });
  }

  time_values_to_chart_data(values, earliest_unix, latest_unix){
    //let prelabels = transfers.map((t,i) => t.transferred_at / 86400000);
    console.log("Values");
    console.log(values);
    console.log("Earliest UNIX");
    console.log(earliest_unix);
    console.log("Latest UNIX");
    console.log(latest_unix);
    let prelabels = Array(Math.round((latest_unix - earliest_unix) / 86400)).fill(0).map((x,i) => i);
    let unixlabels = prelabels.map((l,i) => l*86400 + earliest_unix);
    let labels = unixlabels.map((l,i) => (new Date(l * 1000)).toDateString().slice(4,10));//prelabels.map((l,i) => (new Date((l*86400 + earliest_unix) * 1000)).toDateString().slice(4,10));
    console.log(earliest_unix);
    console.log(prelabels);
    console.log(unixlabels);
    console.log(labels);
    let time2val = {};
    let i;
    for (i = 0 ; i < values.length ; i++){
      if (time2val[String(values[i].time - values[i].time % 86400)] === undefined){
        time2val[String(values[i].time - values[i].time % 86400)] = values[i].value;
      } else {
        time2val[String(values[i].time - values[i].time % 86400)] += values[i].value;
      }
    }
    let data = unixlabels.map((t,i) => time2val[String(t)] === undefined ? 0 : time2val[String(t)]);
    let data_cumul = Array(data.length).fill(0);
    for (i = 0 ; i < data_cumul.length ; i++){
      data_cumul[i] = (i === 0 ? 0 : data_cumul[i-1]) + data[i];
    }
    console.log('data');
    console.log(data_cumul);
    return {
      labels: labels,
      data: data,
      data_cumul: data_cumul
    };
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  unix_to_formatted_date(unix){
    return (new Date(unix * 1000)).toDateString();
  }

  unix_to_formatted_time(unix){
    return (new Date(unix * 1000)).toTimeString().slice(0,17);
  }

  on_type_tag(e){
    this.setState({
      typed_tag: e.target.value
    });
  }

  on_key_down_tag(e){
    if (e.key === 'Enter' && !this.state.fund.tags.includes(this.state.typed_tag)){
      this.update_tags([this.state.typed_tag],[]);
    }
  }

  update_tags(add_terms, remove_terms){
    fetch("/api/fund/"+this.state.fund.code+"/tag", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        add: add_terms,
        remove: remove_terms
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      let fund = this.state.fund;
      fund.tags.filter((e) => !remove_terms.includes(e));
      fund.tags = this.state.fund.tags.concat(add_terms);
      this.setState({
        fund: fund,
        typed_tag: ""
      });
    });
  }

  remove_tag(index){
    this.update_tags([], [this.state.fund.tags[index]]);
    let fund = this.state.fund;
    fund.tags.splice(index, 1);
  }

  admin_update(action){
    this.setState({
      loading: true
    });
    // /api/invoice/:code/admin
    fetch("/api/fund/"+this.props.fund_code+"/admin", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        'action_name': action
      })
    }).then( (response) => {
      this.setState({
        loading: false
      });
      if (response.ok){
        return response.json();
      }
      this.add_message("An error was encountered", 'danger');
      this.setState({
        show_complete_screen: false
      });
      throw new Error('Request fail');
    }).then(json => {
      console.log("JSON");
      console.log(json);
      console.log("Action");
      console.log(action);
      if (json.success){
        if (action === 'SET_AS_COMPLETE'){
          console.log('a');
          this.add_message("Successfully set as completed", 'success');
        } else if (action === 'DELETE'){
          console.log("Successfully deleted.");
          location.href = "/redirect2?redirect_to=%2Finvoices%2Findex&flash_type=success&flash_message=Invoice%20successfully%20deleted."
        } else if (action === 'VISIBLE'){
          this.add_message("Invoice set as visible", 'success');
        } else if (action === 'INVISIBLE'){
          this.add_message("Invoice set as invisible", 'success');
        } else if (action === 'OPEN'){
          this.add_message("Fund is visible and can collect payments",'success');
        } else if (action === 'SUSPEND'){
          this.add_message("Fund has been suspended. It is only visible to you, and cannot collect payments.",'success');
        }
      } else {
        this.add_message("An error was encountered", 'danger');
      }
      this.setState({
        show_complete_screen: false,
        show_status_screen: false,
        fund: json.fund
      });
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  click_to_copy(e, text){
    navigator.clipboard.writeText(text);
    console.log(e);
    e.target.innerHTML = "COPIED!"
    setTimeout(() => {
      e.target.innerHTML = "COPY";
    }, 1000);
  }

  render () {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Payments',
        }
      },
      scales: {
        yAxis1: {
          title: {
            display: true,
            text: '$ payment to Invoice'
          }
        }
      }
    };
    
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Payment',
          data: [102, 34, 22, 58, 103, 75, 72],
          borderColor: '#32bfb8',
          backgroundColor: '#efefef',
          yAxisID: 'yAxis1'
        }
      ]
    };

    console.log(this.state);

    return ( this.state.init_loaded ? 
      <div className="form-section">
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        {this.state.show_complete_screen ? 
          <Overlay on_cancel={() => this.setState({show_complete_screen: false})} title="Set this Fund as completed">
            <div>
              <p style={{fontSize: '14px', color: 'grey'}}>
                Doing this will mean that those who see the Fund page will no longer be able to see a payment option.
              </p>
              <p style={{fontSize: '14px', color: 'grey'}}>
                This will not affect amounts that have already been paid.
              </p>
              <table style={{width: '100%', fontSize: '16px', marginBottom: '10px', fontWeight: 'bold'}}><tbody>
                <tr>
                  <td>
                    Final amount
                  </td>
                  <td style={{textAlign: 'right'}}>
                    ${this.amount_to_formatted_string(this.state.fund.amount)}
                  </td>
                </tr>
              </tbody></table>
              <button className="std-button" onClick={() => this.admin_update('SET_AS_COMPLETE')}>
                {this.state.loading ? 'Setting' : 'Set'} as completed
              </button>
            </div>
          </Overlay> : null}
        
        {this.state.show_status_screen ? 
          <Overlay on_cancel={() => this.setState({show_status_screen: false})} title="Change Fund Status" width="460px">
            <div>

              {[1,3,4].map(
                (status, index) =>
                <div className={this.state.selected_status === status ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({selected_status: status})}>
                  <table><tbody>
                    <tr>
                      <td>
                        <div>
                          <div />
                        </div>
                      </td>
                      <td>
                        <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                          {this.FUND_STATUS_TO_TITLE[String(status)]}
                        </div>
                        <p style={{color: "grey", fontSize: "14px"}}>
                          {this.FUND_STATUS_TO_DESCRIPTION[String(status)]}
                        </p>
                      </td>
                    </tr>
                  </tbody></table>
                </div>
              )}
              <button className="std-button" onClick={() => this.admin_update(this.FUND_STATUS_TO_ADMIN_ACTION[this.state.selected_status])}>
                {this.state.loading ? "Saving..." : "Save"}
              </button>

            </div>
          </Overlay> : null}

        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <h2>
                <span style={{color:'grey'}}>Fund </span>{this.state.fund.name}
              </h2>
            </td>
            <td style={{textAlign: 'right'}}>
              <h2 style={{color: 'grey'}}>
                ${this.amount_to_formatted_string(this.state.fund.goal)}
              </h2>
            </td>
          </tr>
        </tbody></table>

        <table className="admin-top-table"><tbody>
          <tr>
            <td>
              <div className="grey-cap-title">
                STATUS
              </div>
              {this.state.fund.has_goal ? 
                <div style={{backgroundColor: this.FUND_STATUS_TO_COLOUR[this.state.fund.status], color: '#fff', padding: '3px', fontSize: '13px', borderRadius: '4px', width: '80px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', margin: 'auto'}}>
                  {this.FUND_STATUS_TO_WORD[this.state.fund.status]}
                </div> : null}
              {/*<div style={{backgroundColor: (this.state.fund.has_goal && this.state.fund.amount >= this.state.fund.goal) || this.state.fund.status > 3 ? '#32bfb8' : '#040dba', color: '#fff', padding: '3px', fontSize: '13px', borderRadius: '4px', width: '80px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', margin: 'auto'}}>
                  {(this.state.fund.has_goal && this.state.fund.amount >= this.state.fund.goal) || this.state.fund.status > 3 ? 'COMPLETE' : 'OPEN'}
                </div> */}
            </td>
            <td>
              <div className="grey-cap-title">
                AMOUNT PAID
              </div>
              <div style={{fontSize: '18px', fontWeight: 'bold', textAlign: 'center'}}>
                ${this.amount_to_formatted_string(this.state.fund.amount)}
              </div>
            </td>
            <td>
              <div className="grey-cap-title">
                START DATE
              </div>
              <div style={{fontSize: '18px', fontWeight: 'bold', textAlign: 'center'}}>
                {this.unix_to_formatted_date(this.state.fund.start_date_int).slice(4,20)}
              </div>
            </td>
          </tr>
        </tbody></table>

        <table style={{marginTop: '20px'}}><tbody>
          <tr>
            <td style={{width: '180px', verticalAlign: 'top'}}>
              <h4>
                <span style={{fontFamily: 'Roboto Mono', color: 'grey'}}>Fund # {this.state.fund.code}</span>
              </h4>
              {this.state.fund.status === 3 ? 
                <div style={{fontSize: '13px',margin: '10px'}}>
                  Fund is suspended.
                </div> : 
                <table><tbody>
                  {[0,1,2].map(
                    (status, index) =>
                    <tr className={this.state.fund.status >= status ? "admin-progression-complete" : "admin-progression-incomplete"} key={index}>
                      <td>
                        {index != 0 ? <span style={{fontWeight: 'normal'}}><i className="fa-solid fa-arrow-down"></i><br /></span> : null}{this.state.fund.status >= status ? <i className="fa-regular fa-circle-check"></i> : <i className="fa-regular fa-circle"></i>}
                      </td>
                      <td>
                        {['Issued','Collecting','Completed'][index]}
                      </td>
                    </tr>)}
                </tbody></table>}
              <div style={{marginTop: '10px', textAlign: 'center'}}>
                <table><tbody>
                  <tr>
                    <td>
                      <button className="button-blue" onClick={() => location.href = '/fund/'+this.props.fund_code}>
                        See Fund
                      </button>
                    </td>
                    {this.state.fund.status < 2 ? 
                      <td>
                        <button className="button-blue" onClick={() => location.href = '/fund/'+this.props.fund_code+'/edit'}>
                          Edit Fund
                        </button>
                      </td> : null}
                  </tr>
                </tbody></table>
              </div>
            </td>
            <td style={{verticalAlign: 'top'}}>
              <div style={{width: '400px', height: '180px'}}>
                {this.state.payment_history.show ? 
                  <Line options={this.state.payment_history.options} data={this.state.payment_history.data} /> : null}
              </div>
            </td>
          </tr>
        </tbody></table>

        <table style={{marginTop: '20px'}}><tbody>
          <tr>
            <td style={{width: '300px', verticalAlign: 'top', textAlign: 'center'}}>
              <div className="grey-cap-title">
                SHORT URL
              </div>
              <div style={{fontWeight: 'bold', fontSize: '18px', fontFamily: 'Roboto Mono'}}>
                invc.me/{this.state.fund.short_url}
              </div>
              <div>
                <span onClick={(e) => this.click_to_copy(e, 'invc.me/'+this.state.fund.short_url)} className="copy-span">COPY</span>
                {/*this.state.fund.privacy_status === 0 ? null : <span onClick={this.toggle_url_passkey_inclusion} className="copy-span">W/{this.state.invoice.short_url.includes('?pk') ? 'O' : null} PASSKEY</span>*/}
              </div>
            </td>
            <td style={{width: '250px', verticalAlign: 'top', textAlign: 'center'}}>
              <div className="grey-cap-title">
                STATUS
              </div>
              <div>
                <span style={{fontSize: '15px',fontWeight:'bold'}}>{this.FUND_STATUS_TO_TITLE[this.state.fund.status]}</span><br />
                <span onClick={(e) => this.setState({show_status_screen: true})} className="copy-span">CHANGE</span>
              </div>
            </td>
            {/* Privacy status: add after the migration */}
            {/*<td style={{verticalAlign: 'top'}}>
              <div className="grey-cap-title">
                PRIVACY STATUS
              </div>
              <table><tbody>
                <tr>
                  <td style={{padding: '5px'}}>
                    <Toggle on_toggle={(v) => this.toggle_visibility(v)} init_value={true} />
                  </td>
                  <td style={{padding: '5px', fontSize: '14px'}}>
                    Visible
                  </td>
                </tr>
              </tbody></table>
              {this.state.visibility_changed ? 
                <button className="button-blue">
                  Save
                </button> : null}
              </td>*/}
          </tr>
        </tbody></table>

        <h3>
          Tags
        </h3>
        <div style={{backgroundColor: "#efefef", borderRadius: '6px', margin: '5px', minHeight: '40px', padding: '5px'}}>
          {this.state.fund.tags.map(
            (tag, index) =>
            <div key={index} style={{padding: '6px', paddingLeft: '12px', paddingRight: '12px', fontSize: '13px', margin: '5px', backgroundColor: '#040dba', color: '#fff', display: 'inline-block', borderRadius: '14px', fontWeight: 'bold', fontFamily: 'Roboto Mono'}}>
              {tag} <i style={{marginLeft: '4px', cursor: 'pointer'}} className="fa-solid fa-circle-xmark" onClick={() => this.remove_tag(index)}></i>
            </div>
          )}
          <div style={{padding: '0px', paddingLeft: '12px', paddingRight: '12px', fontSize: '13px', margin: '0px', display: 'inline-block', borderRadius: '14px'}}>
            <input type="text" style={{backgroundColor: '#efefef', border: 'none', fontFamily: 'Roboto Mono'}} id="tag-input" value={this.state.typed_tag} onChange={this.on_type_tag} onKeyDown={this.on_key_down_tag} placeholder="Type tag here..." />
          </div>
        </div>

        <h3>
          Payments
        </h3>
        <table style={{fontSize: '13px', color: 'grey'}} className="admin-payments-table"><tbody>
          <tr>
            <td>
              Paid at
            </td>
            <td>
              Payor email
            </td>
            <td>
              Status
            </td>
            <td>
              Amount
            </td>
          </tr>
        </tbody></table>
        {this.state.fund.transfers.map(
          (transfer, index) =>
          <div style={{margin: '2px', padding: '3px', backgroundColor: '#efefef', borderRadius: '4px', cursor: 'pointer'}} key={index} onClick={() => this.setState({selected_transfer_index: this.state.selected_transfer_index === index ? -1 : index})}>
            <table className="admin-payments-table" style={{fontSize: '13px', fontFamily: 'Roboto Mono'}}><tbody>
              <tr>
                <td>
                  {this.unix_to_formatted_date(transfer.transferred_at).slice(4,20)}
                </td>
                <td>
                  {transfer.payor_email === null ? "Anonymous" : transfer.payor_email}
                </td>
                <td>
                  {transfer.bank_status}
                </td>
                <td style={{textAlign: 'right'}}>
                  {this.amount_to_formatted_string(transfer.amount)}
                </td>
              </tr>
            </tbody></table>
            {this.state.selected_transfer_index === index ? 
            <div>
              <table className="admin-payments-table" style={{fontSize: '13px', fontFamily: 'Roboto Mono'}}><tbody>
                <tr>
                  <td>
                    {this.unix_to_formatted_time(transfer.transferred_at)}
                  </td>
                  <td>
                    
                  </td>
                  <td>
                    
                  </td>
                  <td style={{textAlign: 'right'}}>
                    AUD
                  </td>
                </tr>
              </tbody></table>
            </div> : null}
          </div>
        )}

      {this.state.fund.transfers.length === 0 && !this.state.fund.paid_by_other_source ?
        <div style={{textAlign: 'center', fontSize: '14px', color: 'grey', margin: '20px'}}>
          There are no payments
        </div> : null}

      {this.state.fund.status >= 4 ? null : 
        <table style={{marginTop: '20px'}}><tbody>
          <tr>
            <td>
              <button className="std-button" onClick={() => this.setState({show_complete_screen: true})}>
                Complete
              </button>
            </td>
            <td>
              <button className="std-button" onClick={() => this.setState({show_delete: true})} style={{backgroundColor: 'red'}}>
                Delete
              </button>
            </td>
          </tr>
        </tbody></table>}

      </div>
    : 
    <div>
      Loading...
    </div>);
  }
}

export default AdminFund
