import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
class Login extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      general_messages: [],
      loading: false,
      typed_email: "",
      typed_password: "",
      email_status: 0,
      password_status: 0,
      show_mfa: false,
      typed_mfa: "",
      mfa_status: 0,
      show_email_mfa: false,
      typed_email_mfa: "",
      email_mfa_status: 0,
      success: false
    };

    this.on_type_email = this.on_type_email.bind(this);
    this.on_type_password = this.on_type_password.bind(this);
    this.on_key_down_field = this.on_key_down_field.bind(this);
    this.basic_login = this.basic_login.bind(this);
    this.on_type_mfa = this.on_type_mfa.bind(this);
    this.on_type_email_mfa = this.on_type_email_mfa.bind(this);
    this.mfa_login = this.mfa_login.bind(this);
    this.email_mfa_login = this.email_mfa_login.bind(this);
    this.use_email_mfa = this.use_email_mfa.bind(this);

    this.MESSAGE_TYPE_TO_COLOUR = {'danger':'red','success':'green','info':'blue'};
    this.MESSAGE_TYPE_TO_ICON = {'danger':'circle-exclamation','warning':'circle-exclamation','info':'circle-info','success':'check'}
    this.MFA_LENGTH_LIMIT = 6;
    this.MAX_PASSWORD_LENGTH = 128;
    
  }

  on_type_email(e){
    const typed = e.target.value;
    if ("qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM0123456789_.@+-".includes(typed.slice(-1)) && !this.state.show_mfa && !this.state.show_email_mfa){
      this.setState({
        typed_email: typed
      });
    }
  }

  basic_login(){
    if (this.state.typed_email.length === 0 || this.state.typed_password.length === 0){
      this.setState({
        general_messages: [{type:'danger',content:"You need to enter an email and password"}],
        loading: false,
        email_status: this.state.typed_email.length === 0 ? 3 : 2,
        password_status: this.state.typed_password.length === 0 ? 3 : 2
      });
      return null;
    }
    this.setState({
      loading: true
    });
    fetch("/sessions/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        session: {
          email: this.state.typed_email.toLowerCase(),
          password: this.state.typed_password
        }
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log("Result");
      console.log(json);
      if (json.success){
        this.setState({
          general_messages: [{type:'success',content:json.message}],
          loading: false,
          email_status: 2,
          password_status: 2,
          success: true
        });
        location.href = this.props.continue_url === null ? '/' : this.props.continue_url;
      } else if (!!json.mfa_required){
        this.setState({
          show_mfa: true,
          general_messages: [],
          loading: false
          /*email_status: 2,
          password_status: 2*/
        }, () => {
          let x = document.getElementById('mfa-input');
          if (x != null){
            x.focus();
          }
          this.setState({
            email_status: 2,
            password_status: 2
          });
        });
      } else {
        this.setState({
          general_messages: [{type:'danger',content:json.message}],
          loading: false,
          email_status: 3,
          password_status: 3
        });
      }
    });
  }

  on_type_mfa(e){
    const typed = e.target.value;
    if ("1234567890".includes(typed.slice(-1)) && typed.length <= this.MFA_LENGTH_LIMIT){
      this.setState({
        typed_mfa: typed
      });
      if (typed.length === this.MFA_LENGTH_LIMIT){
        this.mfa_login(typed);
      }
    }
  }

  on_type_email_mfa(e){
    const typed = e.target.value;
    if ("1234567890".includes(typed.slice(-1)) && typed.length <= this.MFA_LENGTH_LIMIT){
      this.setState({
        typed_email_mfa: typed
      });
      if (typed.length === this.MFA_LENGTH_LIMIT){
        this.email_mfa_login(typed);
      }
    }
  }

  on_type_password(e){
    console.log("on_type_password");
    const typed = e.target.value;
    if (typed.length <= this.MAX_PASSWORD_LENGTH && !this.state.show_mfa && !this.state.show_email_mfa){
      this.setState({
        typed_password: typed
      });
    }
  }

  on_key_down_field(e){
    if (e.keyCode === 13){
      this.basic_login();
    }
  }

  email_mfa_login(mfa_code){
    console.log("Trying code "+String(mfa_code));
    this.setState({
      loading: true
    });
    fetch("/sessions/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        session: {
          email: this.state.typed_email.toLowerCase(),
          password: this.state.typed_password,
          email_mfa: mfa_code
        }
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log("Result");
      console.log(json);
      if (json.success){
        this.setState({
          general_messages: [{type:'success',content:json.message}],
          loading: false,
          email_mfa_status: 2,
          success: true
        });
        location.href = this.props.continue_url === null ? '/' : this.props.continue_url;
      } else {
        this.setState({
          general_messages: [{type:'danger',content:json.message}],
          loading: false,
          email_mfa_status: 3
        });
      }
    });
  }

  mfa_login(mfa_code){
    console.log("Doing mfa login");
    this.setState({
      loading: true
    });
    fetch("/sessions/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        session: {
          email: this.state.typed_email.toLowerCase(),
          password: this.state.typed_password,
          mfa_token: mfa_code
        }
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log("Result");
      console.log(json);
      if (json.success){
        this.setState({
          general_messages: [{type:'success',content:json.message}],
          loading: false,
          mfa_status: 2,
          success: true
        });
        location.href = this.props.continue_url === null ? '/' : this.props.continue_url;
      } else {
        this.setState({
          general_messages: [{type:'danger',content:json.message}],
          loading: false,
          mfa_status: 3
        });
      }
    });
  }

  use_email_mfa(){
    // got this far
    this.setState({
      loading: true
    });
    fetch("/sessions/use_email_mfa", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        session: {
          email: this.state.typed_email.toLowerCase(),
          password: this.state.typed_password
        }
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.setState({
          show_email_mfa: true,
          show_mfa: false,
          general_messages: [{type:'info',content:json.message}],
          loading: false
        }, () => {
          let x = document.getElementById('email-mfa-input');
          if (x != null){
            x.focus();
          }
        });
      }
    });
  }

  render () {
    console.log(this.state);
    return (
      <div style={{/*margin: 'auto', backgroundColor: '#fff', padding: '30px', width: '290px', boxShadow: '0px 0px 14px 0px #c9c9c9'*/display: 'table', margin: 'auto'}}>
        {/*this.state.general_messages.map(
          (message, index) =>
          <div style={{margin: '10px', padding: '10px', backgroundColor: this.MESSAGE_TYPE_TO_COLOUR[message.type], color: '#fff'}} key={index}>
            {message.content}
          </div>
        )*/}
        {this.state.general_messages.map(
          (message, index) => 
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <div style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '10px'}}>
          Merchant Login
        </div>

        <div style={{textAlign: 'center'}}>
          {/*<input onChange={this.on_type_email} value={this.state.typed_email} onKeyDown={this.on_key_down_field} placeholder="Email" />*/}
            <MyInput onChange={this.on_type_email} value={this.state.typed_email} status={this.state.email_status} onKeyDown={this.on_key_down_field} label="Email" />
        </div>

        <div style={{textAlign: 'center'}}>
          {/*<input type="password" onChange={this.on_type_password} value={this.state.typed_password} onKeyDown={this.on_key_down_field} placeholder="Password" />*/}
            <MyInput type="password" onChange={this.on_type_password} value={this.state.typed_password} status={this.state.password_status} onKeyDown={this.on_key_down_field} label="Password" />
        </div>
        
        {this.state.show_mfa || this.state.show_email_mfa ? null : 
          <div>
            <button className="std-button" onClick={this.basic_login}>
              {this.state.loading ? "Logging in" : "Log in"}
            </button>
          </div>}

        {this.state.show_mfa ? 
          <div>
            <h4>
              MFA Confirmation
            </h4>
            {/*<input onChange={this.on_type_mfa} value={this.state.typed_mfa} />*/}
            <MyInput onChange={this.on_type_mfa} value={this.state.typed_mfa} style={{textAlign: 'center', letterSpacing: '4px', fontWeight: 'bold'}} status={this.state.mfa_status} id="mfa-input" />
            {this.state.success ? null : 
              <button className="std-button" onClick={this.use_email_mfa}>
                Email MFA instead
              </button>}
          </div> : null}

        {this.state.show_email_mfa ? 
          <div>
            <h4>
              MFA Email Confirmation
            </h4>
            {/*<input onChange={this.on_type_email_mfa} value={this.state.typed_email_mfa} />*/}
            <MyInput onChange={this.on_type_email_mfa} value={this.state.typed_email_mfa} style={{textAlign: 'center', letterSpacing: '4px', fontWeight: 'bold'}} status={this.state.email_mfa_status} id="email-mfa-input" />
          </div> : null}
      </div>
    );
  }
}

export default Login
