import React from "react"
import PropTypes from "prop-types"
class PayorInvoices extends React.Component {
  constructor(props){
		super(props);
    this.state = {
		};
  }

  render () {
    return (
      <div className="form-section">
      </div>
    );
  }
}

export default PayorInvoices
