import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
import ProofOfWork from "./ProofOfWork.js"
import ProofOfWorkChain from "./ProofOfWorkChain.js"
import Overlay from "./Overlay.js"
class NewRedress extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      status: 0,
      messages: [],
      org_bsb: "",
      org_bsb_status: 0,
      org_bsb_message: "",
      org_account_number: "",
      org_account_number_status: 0,
      org_account_number_message: "",
      selected_purpose: -1,
      bsb: "",
      bsb_status: 0,
      bsb_message: "",
      account_number: "",
      account_number_status: 0,
      account_number_message: "",
      account_name: "",
      account_name_status: 0,
      account_name_message: "",
      time: "",
      time_status: 0,
      time_message: "",
      receipt_number: "",
      receipt_number_status: 0,
      amount: 100,
      typed_amount: "$1.00",
      typed_amount_status: 1,
      description: "",
      description_status: 0,
      reference: "",
      reference_status: 0,
      short_url: "invc.me/",
      short_url_status: 0,
      short_url_message: "",
      challenge: "",
      challenge_zeros: Number(this.props.challenge_zeros),
      challenge_body: {foo:'bar'},
      challenge_go: false,
      solutions: [],
      num_challenges: Number(this.props.num_challenges),
      email: "",
      email_status: 0,
      email_message: "",
      loading: false
    };

    this.on_type_org_bsb = this.on_type_org_bsb.bind(this);
    this.on_blur_org_bsb = this.on_blur_org_bsb.bind(this);
    this.on_type_org_account_number = this.on_type_org_account_number.bind(this);
    this.on_blur_org_account_number = this.on_blur_org_account_number.bind(this);
    this.on_type_bsb = this.on_type_bsb.bind(this);
    this.on_blur_bsb = this.on_blur_bsb.bind(this);
    this.on_type_account_number = this.on_type_account_number.bind(this);
    this.on_blur_account_number = this.on_blur_account_number.bind(this);
    this.on_type_account_name = this.on_type_account_name.bind(this);
    this.on_blur_account_name = this.on_blur_account_name.bind(this);
    this.on_change_time = this.on_change_time.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.on_type_amount = this.on_type_amount.bind(this);
    this.on_focus_amount = this.on_focus_amount.bind(this);
    this.on_blur_amount = this.on_blur_amount.bind(this);
    this.is_valid_amount_input = this.is_valid_amount_input.bind(this);
    this.on_type_short_url = this.on_type_short_url.bind(this);
    this.on_blur_short_url = this.on_blur_short_url.bind(this);
    this.valid_for_submission = this.valid_for_submission.bind(this);
    this.on_type_email = this.on_type_email.bind(this);
    this.on_blur_email = this.on_blur_email.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);

    this.MESSAGE_TYPE_TO_COLOUR = {'danger':'red','success':'green','info':'blue'};
    this.MESSAGE_TYPE_TO_ICON = {'danger':'circle-exclamation','warning':'circle-exclamation','info':'circle-info','success':'check'};

    // statuses:
    // initiated, matched to none, matched to 1, matched to many, approved, flagged, disbursed, partial disbursed, reassigned, denied
    // purposes:
    // refund, paid too much invoice, wrong code

    this.PURPOSES = [1,2,3];
    this.PURPOSE_TO_TITLE = {
      '1':'Refund',
      '2':'Paid too much (Invoice)',
      '3':'Wrong code'
    };
    this.PURPOSE_TO_DESCRIPTION = {
      '1':'You accidentally paid money and request a full refund.',
      '2':'You accidentally paid too much for an Invoice object and want a partial refund.',
      '3':'You put the wrong code in the description and want it corrected to the right Invoice, Fund or Customer object.'
    };

  }

  on_type_org_bsb(e){
    const typed = e.target.value.replace('-','');
    if (typed.length <= 6 && /^\d+$/.test(typed)){
      this.setState({
        org_bsb: typed,
        org_bsb_status: typed.length === 6 ? 2 : 1,
        org_bsb_message: ""
      });
    }
  }

  on_type_bsb(e){
    const typed = e.target.value.replace('-','');
    if (typed.length <= 6 && /^\d+$/.test(typed)){
      this.setState({
        bsb: typed,
        bsb_status: typed.length === 6 ? 2 : 1,
        bsb_message: ""
      });
    }
  }

  on_blur_org_bsb(){
    if (this.state.org_bsb.length === 6){
      this.setState({
        org_bsb_status: this.state.org_bsb.length === 6 ? 2 : 3,
        org_bsb_message: this.state.org_bsb.length === 6 ? "" : "Incorrect BSB pattern"
      });
    }
  }

  on_blur_bsb(){
    if (this.state.bsb.length === 6){
      this.setState({
        bsb_status: this.state.bsb.length === 6 ? 2 : 3,
        bsb_message: this.state.bsb.length === 6 ? "" : "Incorrect BSB pattern"
      });
    }
  }

  on_type_org_account_number(e){
    const typed = e.target.value;
    if (typed.length <= 9 && /^\d+$/.test(typed)){
      this.setState({
        org_account_number: typed,
        org_account_number_status: 1
      });
    }
  }

  on_type_account_number(e){
    const typed = e.target.value;
    if (typed.length <= 9 && /^\d+$/.test(typed)){
      this.setState({
        account_number: typed,
        account_number_status: 1
      });
    }
  }

  on_type_account_name(e){
    const typed = e.target.value;
    this.setState({
      account_name: typed,
      account_name_status: 1
    });
  }

  on_blur_org_account_number(){
    if (this.state.org_account_number.length < 32 && /^\d+$/.test(this.state.org_account_number)){
      this.setState({
        org_account_number_status: 2,
        org_account_number_message: ""
      });
    } else {
      this.setState({
        org_account_number_status: 3,
        org_account_number_message: "Incorrect account number format"
      });
    }
  }

  on_blur_account_number(){
    if (this.state.account_number.length < 32 && /^\d+$/.test(this.state.account_number)){
      this.setState({
        account_number_status: 2,
        account_number_message: ""
      });
    } else {
      this.setState({
        account_number_status: 3,
        account_number_message: "Incorrect account number format"
      });
    }
  }

  on_blur_account_name(){
    this.setState({
      account_name_status: this.state.account_name.length > 0 ? 2 : 3,
      account_name_message: "Please enter an account name"
    });
  }

  on_change_time(e){
    // get UTC time from it v: (new Date(v)).toISOString()
    console.log(e.target.value);
    this.setState({
      time: e.target.value,
      time_status: 2,
      time_message: ""
    });
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  on_type_amount(e){
    const typed = e.target.value;
    if (typed === '.' || this.is_valid_amount_input(typed) || typed.length === 0){
      this.setState({
        typed_amount: typed
      });
    }
  }

  on_focus_amount(){
    this.setState({
      typed_amount: this.state.typed_amount.replaceAll('$','').replaceAll(',',''),
      typed_amount_status: 1
    }, () => {
      document.getElementById('amount-input').select();
    });
    setTimeout(() => {
      document.getElementById('amount-input').select();
    }, 50);
  }

  on_blur_amount(){
    let amount = this.state.typed_amount.split('.').map((x,i) => i === 0 ? Number(x) * 100 : Number(x[0])*10+(x[1]===undefined ? 0 : Number(x[1]))).reduce((p,a) => p+a, 0);
    if (this.state.typed_amount === '.'){
      amount = 0;
    }
    const str = String(amount / 100);
    this.setState({
      //typed_amount: '$' + (Number(amount) < 100 ? '0.'+(Number(amount.slice(-2)) < 10 ? '0' : '')+String(amount) : amount.slice(0, amount.length-2)+'.'+(Number(amount.slice(-2)) < 10 ? '0' : '')+Number(amount.slice(-2))),
      typed_amount: '$' + this.amount_to_formatted_string(amount),
      //displayed_amount: '$' + this.amount_to_formatted_string(amount),
      amount: amount/*,
      typed_amount_status: amount < this.MINIMUM_AMOUNT || amount > this.MAXIMUM_AMOUNT ? 3 : 2,
      typed_amount_error: amount < this.MINIMUM_AMOUNT || amount > this.MAXIMUM_AMOUNT ? "The amount must be between $"+(this.MINIMUM_AMOUNT/100)+" and $"+(this.MAXIMUM_AMOUNT/100000000)+" million." : null*/
    });
  }

  is_valid_amount_input(input) {
    if (input === "." || input === "") {
        return true;
    }
    const parts = input.split(".");
    if (parts.length === 1){
      return !isNaN(parts[0]);
    } else if (parts.length > 2){
      return false;
    }
    return !isNaN(parts[0]) && !isNaN(parts[1]) && parts[1].length <= 2;
  }

  on_type_short_url(e){
    const typed = e.target.value;
    if (/^invc\.me\/[a-zA-Z0-9]{0,6}$/.test(typed)){
      console.log("Passed - "+typed);
      this.setState({
        short_url: [typed.split('/')[0],typed.split('/')[1].toUpperCase()].join('/'),
        short_url_status: typed.length >= 14
      });
    } else {
      console.log("Failed - "+typed);
    }
  }

  on_blur_short_url(){
    this.setState({
      short_url_status: /^invc\.me\/[a-zA-Z0-9]{0,6}$/.test(this.state.short_url) && this.state.short_url.length >= 14 ? 2 : 3,
      short_url_message: /^invc\.me\/[a-zA-Z0-9]{0,6}$/.test(this.state.short_url) && this.state.short_url.length >= 14 ? "" : "Incorrect short URL format"
    })
  }

  on_type_email(e){
    const typed = e.target.value;
    this.setState({
      email: typed
    });
  }

  on_blur_email(){
    if (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.email)){
      this.setState({
        email_status: 2,
        email_message: ""
      });
    } else {
      this.setState({
        email_status: 3,
        email_message: "Not a valid email"
      });
    }
  }

  valid_for_submission(){
    return true;
  }

  generate_body(){
    return {
      //redress: {
        bsb: this.state.bsb,
        account_number: this.state.account_number,
        account_name: this.state.account_name,
        transaction_at: (new Date(this.state.time)).toISOString(),
        receipt: this.state.receipt_number,
        amount: this.state.amount,
        description: this.state.description,
        request_type: this.state.selected_purpose,
        email: this.state.email,
        phone_contact: this.state.phone_contact,
        reference: this.state.reference,
        comment: this.state.comment
      //}
    };
  }
// :bsb, :account_number, :account_name, :transaction_at, :receipt_digest, :amount, :description, :request_type, :email, :phone_contact, :reference, :comment
  
  submit_redress(){
    this.setState({
      loading: true
    });
    let body = {};
    body['redress'] = this.state.challenge_body;
    body['proofs'] = this.state.solutions;
    fetch("/redresses/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.setState({
        loading: false,
        status: 0
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.add_message("Encountered error in creating Redress object.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.setState({
        loading: false,
        status: 0
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (json.success){
        this.add_message("Successfully created Redress. Please check your email.",'success');
        window.location.href="/redress/"+json.code;
      } else {
        this.add_message("Error in creating Redress object: "+json.errors,'danger');
      }
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  render () {
    console.log(this.state);
    let timezone_offset = Math.abs(new Date().getTimezoneOffset());
    timezone_offset = (new Date().getTimezoneOffset() > 0 ? '-' : '+') + String(Math.floor(timezone_offset / 60)) + (timezone_offset % 60 < 10 ? '0' : '0') + String(timezone_offset % 60)
    return (
      <div className="form-section">

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        {[1,2,3].includes(this.state.status) ? 
          <Overlay on_cancel={() => this.setState({status: 0})} title="Confirm Redress" width="500px">
            {this.state.status === 1 ? 
              <div>
                <h4>
                  A few more details
                </h4>
                <div className="myinput-wrapper-centre">
                  <MyInput onChange={this.on_type_email} value={this.state.email} status={this.state.email_status} onBlur={this.on_blur_email} onFocus={() => this.setState({email_status: 1})} label="Email" note={this.state.email_status === 3 ? this.state.email_message : null} />
                </div>
                {/*<MyInput onChange={this.on_type_org_bsb} value={this.state.org_bsb.length > 3 ? this.state.org_bsb.slice(0,3)+'-'+this.state.org_bsb.slice(3,6) : this.state.org_bsb} status={this.state.org_bsb_status} onBlur={this.on_blur_org_bsb} onFocus={() => this.setState({org_bsb_status: 1})} label="BSB" note={this.state.org_bsb_status === 3 ? this.state.org_bsb_message : null} />
                <MyInput onChange={this.on_type_org_bsb} value={this.state.org_bsb.length > 3 ? this.state.org_bsb.slice(0,3)+'-'+this.state.org_bsb.slice(3,6) : this.state.org_bsb} status={this.state.org_bsb_status} onBlur={this.on_blur_org_bsb} onFocus={() => this.setState({org_bsb_status: 1})} label="BSB" note={this.state.org_bsb_status === 3 ? this.state.org_bsb_message : null} />*/}
                <button onClick={() => this.setState({status: 2})} className="std-button">
                  Continue
                </button>
              </div> : null}
            {this.state.status === 2 ? 
              <div>
                <table className="redress-confirm-table"><tbody>
                  <tr>
                    <td>
                      Recipient Bank Account
                    </td>
                    <td>
                      {this.state.org_bsb.slice(0,3)}-{this.state.org_bsb.slice(3,6)} {this.state.org_account_number}
                    </td>
                  </tr>
                  {this.state.selected_purpose != 2 ? 
                    <tr>
                      <td>
                        From Bank Account
                      </td>
                      <td>
                        {this.state.account_name}<br />
                        {this.state.bsb.slice(0,3)}-{this.state.bsb.slice(3,6)} {this.state.account_number}
                      </td>
                    </tr> : 
                    <tr>
                      <td>
                        You Account Name
                      </td>
                      <td>
                        {this.state.account_name}
                      </td>
                    </tr>}
                  <tr>
                    <td>
                      Amount
                    </td>
                    <td>
                      AUD {this.amount_to_formatted_string(this.state.amount)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Date of Transaction
                    </td>
                    <td>
                      {(new Date(this.state.time.slice('T'))).toString().slice(4,15)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Time of Transaction
                    </td>
                    <td>
                      {(new Date(this.state.time)).toString().slice(15,21)} {(new Date(this.state.time)).toString().slice(25,34)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Description
                    </td>
                    <td>
                      {this.state.description}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Reference
                    </td>
                    <td>
                      {this.state.reference}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Receipt Number
                    </td>
                    <td>
                      {this.state.receipt_number}
                    </td>
                  </tr>
                  {this.state.selected_purpose === 2 ? 
                    <tr>
                      <td>
                        Intended Invoice
                      </td>
                      <td>
                        {this.state.short_url}
                      </td>
                    </tr> : null}
                </tbody></table>
                <button onClick={() => this.setState({challenge_body: this.generate_body()}, () => {this.setState({status: 3, challenge_go: true})})} className="std-button">
                  Confirm
                </button>
              </div> : null}
            {this.state.status === 3 ?
              <div>
                <p>
                  Let your computer complete the following challenge as a security measure.
                </p>
                <ProofOfWorkChain on_solve={(ss) => this.setState({solutions: ss})} body={this.state.challenge_body} go={this.state.challenge_go} num_challenges={this.state.num_challenges} auth_token={this.props.auth_token} challenge_zeros={this.state.challenge_zeros} />
                <div style={{height: '60px', paddingTop: '15px'}}>
                  {this.state.solutions.length === this.state.num_challenges ? 
                    <button className="std-button" onClick={() => this.state.loading ? null : this.submit_redress()}>
                      {this.state.loading ? "Submitting..." : "Submit"}
                    </button> : null}
                </div>
              </div> : null}
          </Overlay> : null}

        <h2>
          Request a Redress
        </h2>
        <p style={{color: 'grey', fontSize: '14px'}}>
          Use this page if you sent money to an account to pay a Paysolve Invoice, Fund or Customer object, but made a mistake. If you sent money to the wrong account that is not associated with a Paysolve Invoice, Fund or Customer object, you will need to contact your bank to obtain a refund.
        </p>

        <div className={"flash flash-info"}>
          <table><tbody>
            <tr>
              <td style={{fontSize: '18px'}}>
                <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON['info']}></i>
              </td>
              <td>
                Making a false representation to obtain money is fraud, an indictable offence in all Australian States and federally.
              </td>
            </tr>
          </tbody></table>
        </div>

        {/*<ProofOfWork input={this.state.challenge} go={this.state.challenge_go} num_zeros={this.state.challenge_zeros} on_solve={(s) => this.setState({challenge_solution: s})} />*/}
        {/*<button onClick={() => {
          fetch("/redresses/create", {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'x-csrf-token': this.props.auth_token
            },
            body: JSON.stringify({
              proofs: this.state.solutions,
              redress: this.state.challenge_body
            })
          }).then( (response) => {
            if (response.ok){
              return response.json();
            }
            throw new Error('Request fail');
          }).then(json => {
            console.log("Redress creation returned:");
            console.log(json);
          });
        }}>
          Check
        </button>*/}

        <div>
          <h3>
            Which account did you send money to?
          </h3>
          <table className="new-redress-new"><tbody>
            <tr>
              <td>
                BSB Number
              </td>
              <td>
                <MyInput onChange={this.on_type_org_bsb} value={this.state.org_bsb.length > 3 ? this.state.org_bsb.slice(0,3)+'-'+this.state.org_bsb.slice(3,6) : this.state.org_bsb} status={this.state.org_bsb_status} onBlur={this.on_blur_org_bsb} onFocus={() => this.setState({org_bsb_status: 1})} label="BSB" note={this.state.org_bsb_status === 3 ? this.state.org_bsb_message : null} />
              </td>
            </tr>
            <tr>
              <td>
                Account Number
              </td>
              <td>
                <MyInput onChange={this.on_type_org_account_number} value={this.state.org_account_number} status={this.state.org_account_number_status} onBlur={this.on_blur_org_account_number} onFocus={() => this.setState({org_account_number_status: 1})} label="Account Number" note={this.state.org_account_number_status === 3 ? this.state.org_account_number_message : null} />
              </td>
            </tr>
          </tbody></table>
          <br />
        </div>

        {this.state.org_bsb_status * this.state.org_account_number_status === 4 ? 
          <div>
            <h3>
              Why are you seeking a Redress?
            </h3>
            {this.PURPOSES.map(
              (purpose, index) => 
              <div className={this.state.selected_purpose === purpose ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({selected_purpose: purpose})} key={index}>
                <table><tbody>
                  <tr>
                    <td>
                      <div>
                        <div />
                      </div>
                    </td>
                    <td>
                      <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                        {this.PURPOSE_TO_TITLE[purpose]}
                      </div>
                      <p style={{color: "grey", fontSize: "14px"}}>
                        {this.PURPOSE_TO_DESCRIPTION[purpose]}
                      </p>
                    </td>
                  </tr>
                </tbody></table>
              </div>)}
          </div> : null}
        <br />
        {[1,2].includes(this.state.selected_purpose) &&
        this.state.org_bsb_status * this.state.org_account_number_status === 4 ? 
          <div>
            <h3>
              What account was the money sent from?
            </h3>
            <p style={{color: 'grey', fontSize: '14px'}}>
              This is the account where the refund will be sent to.
            </p>
            <table className="new-redress-new"><tbody>
              <tr>
                <td>
                  BSB Number
                </td>
                <td>
                  <MyInput onChange={this.on_type_bsb} value={this.state.bsb.length > 3 ? this.state.bsb.slice(0,3)+'-'+this.state.bsb.slice(3,6) : this.state.bsb} status={this.state.bsb_status} onBlur={this.on_blur_bsb} onFocus={() => this.setState({bsb_status: 1})} label="BSB" note={this.state.bsb_status === 3 ? this.state.bsb_message : null} />
                </td>
              </tr>
              <tr>
                <td>
                  Account Number
                </td>
                <td>
                  <MyInput onChange={this.on_type_account_number} value={this.state.account_number} status={this.state.account_number_status} onBlur={this.on_blur_account_number} onFocus={() => this.setState({account_number_status: 1})} label="Account Number" note={this.state.account_number_status === 3 ? this.state.account_number_message : null} />
                </td>
              </tr>
              <tr>
                <td>
                  Account Name
                  <p>
                    This is important to identify the transaction.
                  </p>
                </td>
                <td>
                  <MyInput onChange={this.on_type_account_name} value={this.state.account_name} status={this.state.account_name_status} onBlur={this.on_blur_account_name} onFocus={() => this.setState({account_name_status: 1})} label="Account Name" note={this.state.account_name_status === 3 ? this.state.account_name_message : null} />
                </td>
              </tr>
            </tbody></table>
          </div> : null}

        {[1,2,3].includes(this.state.selected_purpose) &&
        this.state.org_bsb_status * this.state.org_account_number_status === 4 ? 
          <div>
            <h3>
              Provide some information about the transfer
            </h3>
            <p style={{color: 'grey', fontSize: '14px'}}>
              The more information you can provide, the more quickly and surely we can identify and remedy your transaction.
            </p>
            <table className="new-redress-new"><tbody>
              <tr>
                <td>
                  Time of transaction
                  <p>
                    Look for the precise time on your statement or in your banking application.
                  </p>
                </td>
                <td>
                  <MyInput onChange={this.on_change_time} value={this.state.time} status={this.state.time_status} onFocus={() => this.setState({time_status: 1})} label={"Timezone "+Intl.DateTimeFormat().resolvedOptions().timeZone+" ("+timezone_offset+" GMT)"} type="datetime-local" />
                </td>
              </tr>
              <tr>
                <td>
                  Receipt number
                  <p>
                    Your bank may provide a receipt number value.
                  </p>
                </td>
                <td>
                  <MyInput onChange={(e) => this.setState({receipt_number: e.target.value, receipt_number_status: e.target.value.length > 0 ? 2 : 1})} value={this.state.receipt_number} status={this.state.receipt_number_status} onFocus={() => this.setState({receipt_number_status: 1})} onBlur={() => this.setState({receipt_number_status: this.state.receipt_number.length > 0 ? 2 : 1})} label="Receipt Number" />
                </td>
              </tr>
              <tr>
                <td>
                  Amount transferred
                  <p>
                    If your bank charges a transfer fee, make sure you type the amount that was received.
                  </p>
                </td>
                <td>
                  <MyInput onChange={this.on_type_amount} value={this.state.typed_amount} status={this.state.typed_amount_status} onFocus={this.on_focus_amount} onBlur={this.on_blur_amount} label="Amount" id="amount-input" />
                </td>
              </tr>
              <tr>
                <td>
                  Description
                  <p>
                    Paste the <b>recipient's</b> description verbatim.
                  </p>
                </td>
                <td>
                  <MyInput onChange={(e) => this.setState({description: e.target.value.slice(0,180)})} value={this.state.description} status={this.state.description_status} onFocus={() => this.setState({description_status: 1})} onBlur={() => this.setState({description_status: 0})} label="Description" />
                </td>
              </tr>
              <tr>
                <td>
                  Reference
                  <p>
                    You may have also have entered a reference.
                  </p>
                </td>
                <td>
                  <MyInput onChange={(e) => this.setState({reference: e.target.value.slice(0,180)})} value={this.state.reference} status={this.state.reference_status} onFocus={() => this.setState({reference_status: 1})} onBlur={() => this.setState({reference_status: 0})} label="Reference" />
                </td>
              </tr>
            </tbody></table>
          </div> : null}

        {[2,3].includes(this.state.selected_purpose) &&
        this.state.org_bsb_status * this.state.org_account_number_status === 4 ? 
          <div>
            <h3>
              {['','','What Invoice did you overpay?','What Invoice did you mean to pay for?'][this.state.selected_purpose]}
            </h3>
            <table className="new-redress-new"><tbody>
              <tr>
                <td>
                  Short URL code
                  <p>
                    This is the code that follows the pattern <span style={{fontFamily: 'Roboto Mono'}}>invc.me/XXXXXX</span>
                  </p>
                </td>
                <td>
                  <MyInput onChange={this.on_type_short_url} value={this.state.short_url} status={this.state.short_url_status} onFocus={() => this.setState({short_url_status: 1})} onBlur={this.on_blur_short_url} label="Short URL (invc.me/XXXXXX)" note={this.state.short_url_status === 3 ? this.state.short_url_message : null} />
                </td>
              </tr>
            </tbody></table>
          </div> : null}
        
        {this.valid_for_submission() ? 
          <button onClick={() => this.setState({status: 1})} className="std-button">
            Submit
          </button> : null}

      </div>
    );
  }
}

export default NewRedress
