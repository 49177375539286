import React from "react"
import PropTypes from "prop-types"
class PriceCompare extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loaded: false,
      transactions: 150,
      avg_value: 20000,
      displayed_avg_value: '$200',
      paysolve: []/*[
        {
          base_amount: 0,
          max_transactions: 20,
          max_value: 500000,
          max_users: 1,
          allow_excess_transactions: false
        },{
          base_amount: 1500,
          max_transactions: 50,
          max_value: 2000000,
          max_users: 4,
          allow_excess_transactions: false
        },{
          base_amount: 3000,
          max_transactions: 200,
          max_value: 20000000,
          max_users: 12,
          allow_excess_transactions: false
        },{
          base_amount: 5000,
          max_transactions: 1200,
          max_value: 100000000,
          max_users: 30,
          allow_excess_transactions: false
        },{
          base_amount: 8000,
          max_transactions: 1500,
          max_value: 999999999999,
          allow_excess_transactions: true,
          excess_transaction_rate_1000: 44
        }
      ]*/,
      results: {
        paysolve: 0,
        credit_card: 0,
        bpay: 0
      }
    };

    this.evaluate_all = this.evaluate_all.bind(this);
    this.calculate_paysolve = this.calculate_paysolve.bind(this);
    this.calculate_credit_card = this.calculate_credit_card.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.amount_to_integer_string = this.amount_to_integer_string.bind(this);
    this.handle_transactions_change = this.handle_transactions_change.bind(this);
    this.on_blur_transactions = this.on_blur_transactions.bind(this);
    this.handle_avg_value_change = this.handle_avg_value_change.bind(this);
    this.on_focus_avg_value = this.on_focus_avg_value.bind(this);
    this.on_blur_avg_value = this.on_blur_avg_value.bind(this);
    
  }

  componentDidMount(){
    /*this.setState({
      displayed_avg_value: this.amount_to_formatted_string(this.state.avg_value)
    });*/
    this.setState({
      paysolve: JSON.parse(this.props.billing_plans).billing_plans,
      loaded: true
    }, () => {
      this.evaluate_all(this.state.transactions, this.state.avg_value*this.state.transactions);
    });
  }

  evaluate_all(n, v){
    let results = this.state.results;
    results.paysolve = this.calculate_paysolve(n, v);
    results.credit_card = Math.round(this.calculate_credit_card(n, v));
    results.bpay = Math.round(this.calculate_bpay(n, v));
    this.setState({
      results: results
    });
  }

  calculate_paysolve(n, v){ // n = number of transactions, v = average value
    //console.log("n = "+String(n)+", v = "+String(v));
    let i;
    for (i = 0 ; i < this.state.paysolve.length ; i++){
      if ((n <= this.state.paysolve[i].max_transactions || this.state.paysolve[i].allow_excess_transactions) && (v <= this.state.paysolve[i].max_value || this.state.paysolve[i].max_value === -1)){
        break;
      }
    }
    return this.state.paysolve[i].base_amount + (n > this.state.paysolve[i].max_transactions ? Math.round((this.state.paysolve[i].excess_transaction_rate_1000 / 10.0) * (n - this.state.paysolve[i].max_transactions)) : 0);
  }

  calculate_credit_card(n, v){
    console.log("Credit card n = "+String(n)+", v = "+String(v));
    return 0.0175*v + 30*n;
  }

  calculate_bpay(n, v){
    return 80*n;
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  amount_to_integer_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0].toLocaleString());
  }

  handle_transactions_change(e){
    if (/^[+-]?[1-9][0-9]*|0$/.test(e.target.value)){
      this.setState({
        transactions: Number(e.target.value)
      });
    } else if (e.target.value.length === 0){
      this.setState({
        transactions: ''
      });
    }
  }

  on_blur_transactions(){
    if (this.state.transactions === ''){
      this.setState({
        transactions: 0
      });
      this.evaluate_all(0,0);
    } else {
      this.setState({
        transactions: Number(this.state.transactions)
      });
      this.evaluate_all(Number(this.state.transactions), Number(this.state.transactions) * this.state.avg_value);
    }
  }

  handle_avg_value_change(e){
    if (/^[+-]?[1-9][0-9]*|0$/.test(e.target.value)){
      this.setState({
        displayed_avg_value: e.target.value
      });
    } else if (e.target.value.length === 0){
      this.setState({
        displayed_avg_value: ''
      });
    }
  }

  on_focus_avg_value(){
    this.setState({
      displayed_avg_value: Math.floor(this.state.avg_value / 100)
    });
  }

  on_blur_avg_value(){
    this.evaluate_all(Number(this.state.transactions), Number(this.state.displayed_avg_value)*100*Number(this.state.transactions));
    if (this.state.displayed_avg_value.length === 0){
      this.setState({
        displayed_avg_value: '$1.00',
        avg_value: 100
      });
    } else {
      this.setState({
        displayed_avg_value: '$'+this.amount_to_integer_string(this.state.displayed_avg_value*100),
        avg_value: this.state.displayed_avg_value*100
      });
    }
  }

  render () {
    console.log(this.state);
    return (this.state.loaded ? 
      <div style={{margin: '20px'}}>
        <div style={{verticalAlign: 'top'}}>
          <table><tbody><tr>
          {this.state.paysolve.map(
            (plan, index) =>
            <td key={index}>
              <div className="billing-plan">
                <div>
                  {plan.title}
                </div>
                <div>
                  <span>${Math.floor(plan.base_amount / 100)}</span><span>{plan.base_amount % 100 === 0 ? "" : plan.base_amount % 100}{plan.allow_excess_transactions ? '+' : null}</span><span>/mo</span>
                </div>
                <div>
                  <ul>
                    <li>
                      Up to <b>{plan.max_transactions}</b> transactions
                    </li>
                    {plan.allow_excess_transactions ? <li>
                      {'$' + this.amount_to_formatted_string(Math.floor(plan.excess_transaction_rate_1000 / 10)) + (plan.excess_transaction_rate_1000 % 10) + ' per excess transaction'}
                    </li> : null}
                    <li>
                      {plan.max_value === -1 ? 'Unlimited transaction value' : 'Up to $'+this.amount_to_formatted_string(plan.max_value).split('.')[0]+' in transactions'}
                    </li>
                    <li>
                      {plan.max_created_objects === -1 ? "Unlimited objects created" : "Up to "+String(plan.max_created_objects) + " objects created"}
                    </li>
                    <li>
                      {plan.max_users === -1 ? 'Unlimited Users' : 'Up to '+plan.max_users+' User'+(plan.max_users === 1 ? '' : 's')}
                    </li>
                  </ul>
                </div>
              </div>
            </td>
          )}
          </tr></tbody></table>
        </div>
        <h2>
          Price Compare
        </h2>
        <p>
          Transactions
        </p>
        <input value={this.state.transactions} onChange={this.handle_transactions_change} onBlur={this.on_blur_transactions} />
        <p>
          Avg value
        </p>
        <input value={this.state.displayed_avg_value} onChange={this.handle_avg_value_change} onFocus={this.on_focus_avg_value} onBlur={this.on_blur_avg_value} />
        <div>
          Est. revenue: ${this.amount_to_formatted_string(this.state.avg_value*this.state.transactions)}
        </div>
        <table><tbody>
          <tr>
            <td>
              PaySolve
            </td>
            <td>
              ${this.amount_to_formatted_string(this.state.results.paysolve)}
            </td>
          </tr>
          <tr>
            <td>
              Credit Card
            </td>
            <td>
              ${this.amount_to_formatted_string(this.state.results.credit_card)}
            </td>
          </tr>
          <tr>
            <td>
              BPAY
            </td>
            <td>
              ${this.amount_to_formatted_string(this.state.results.bpay)}
            </td>
          </tr>
        </tbody></table>
      </div>
    : null);
  }
}

export default PriceCompare
