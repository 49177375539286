import React from "react"
import PropTypes from "prop-types"
class ConsentSync extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      init_loaded: false,
      consent: null,
      syncing_consent: false,
      messages: []
		};

    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.sync_consent = this.sync_consent.bind(this);

    this.CONSENT_STATUS_TO_NAME = {'1':'PENDING','2':'CONFIRMED','3':'EXPIRED'};
    this.CONSENT_STATUS_TO_COLOUR = {'1':'#040dba','2':'#32bfb8','3':'#e6a330'};

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  componentDidMount(){
    console.log(this.props);
    this.setState({
      consent: this.props.consent,
      init_loaded: true
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  sync_consent(){
    this.setState({
      syncing_consent: true
    });
    fetch("/api/consent/"+this.state.consent.code+"/sync", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to synchronise Consent with Basiq.",'danger');
      this.setState({
        syncing_consent: false
      });
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        syncing_consent: false
      });
      if (json.success){
        this.setState({
          consent: json.consent
        });
        this.add_message("Consent was successfully synchronised.",'success');
      } else {
        this.add_message("Consent was not successfully synchronised.",'danger');
      }
    });
  }

  render () {
    return (this.state.init_loaded ? 
      <div className="form-section">

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <h2>
          Consent <span style={{fontFamily: "Roboto Mono"}}>{this.state.consent.code}</span>
        </h2>
        <table style={{width: '200px', margin: 'auto'}}><tbody>
          <tr>
            <td style={{color: 'grey', fontSize: '14px'}}>
              Status:
            </td>
            <td>
              <div style={{backgroundColor: this.CONSENT_STATUS_TO_COLOUR[String(this.state.consent.status)]}} className="status-box">
                {this.CONSENT_STATUS_TO_NAME[String(this.state.consent.status)]}
              </div>
            </td>
          </tr>
        </tbody></table>
        {this.state.consent.status === 1 ? 
          <div>
            <h3>
              Synchronise Consent
            </h3>
            <div style={{textAlign: 'center'}}>
              <button className="std-button" onClick={this.sync_consent}>
                {this.state.syncing_consent ? "Syncing..." : "Sync Consent"}
              </button>
            </div>
          </div> : null}
      </div>
    : null);
  }
}

export default ConsentSync
