import React from "react"
import PropTypes from "prop-types"
import Checkbox from "./Checkbox.js"
import MyInput from "./MyInput.js"

class TransferIndex extends React.Component {
  constructor(props){
    super(props);
    this.RESULTS_PER_PAGE = 5;
    this.state = {
      account: this.props.account,
      include_created_at: true,
      created_at_max: Math.round((Date.now() - Date.now() % 86400000 + 86400000 * 1) / 1000),
      displayed_created_at_max: new Date(Date.now() - Date.now() % 86400000 + 86400000 * 1).toISOString().slice(0,10),
      created_at_min: Math.round((Date.now() - Date.now() % 86400000 - 86400000 * 7) / 1000),
      displayed_created_at_min: new Date(Date.now() - Date.now() % 86400000 - 86400000 * 7).toISOString().slice(0,10),
      include_transaction_identifier: false,
      transaction_identifier: "",
      
      show_results: false,
      show_time_local: false,
      show_results: false,
      results: [],
      //offset: 0,
      limit: this.RESULTS_PER_PAGE,
      total_count: null,
      results_per_page: this.RESULTS_PER_PAGE, // should be the same as limit
      max_num_pages: 5,
      num_pages: null,
      showing_page: 0
    };

    this.on_change_created_at_max = this.on_change_created_at_max.bind(this);
    this.on_change_created_at_min = this.on_change_created_at_min.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.search = this.search.bind(this);

    /*# STATUSES
  # 2 - assigned
  # 4 - manually assigned
  # 5 - no matches
  # 6 - multiple matches
  # 7 - excess paid
  # 8 - assigned dispute*/
    this.TRANSFER_STATUS_TO_NAME = {'1':'UNASSIGNED','2':'ASSIGNED','3':'ERROR','4':'MANUAL MATCH','5':'NO MATCH','6':'MULTIPLE MATCHES','7':'EXCESS PAID','8':'ASSIGNED DISPUTE'};

  }

  on_change_created_at_max(e){
    this.setState({
      displayed_created_at_max: e.target.value,
      created_at_max: Math.round((new Date(e.target.value)).getTime() / 1000)
    });
  }

  on_change_created_at_min(e){
    this.setState({
      displayed_created_at_min: e.target.value,
      created_at_min: Math.round((new Date(e.target.value)).getTime() / 1000)
    });
  }

  search(page,limit,req_count){
    console.log("page = "+String(page));
    let body = {transfer:{},offset:page*this.state.results_per_page,limit:limit,req_count:req_count,account_code:this.state.account.internal_code};
    /*
      For each search term in state, only include it in the body if state indicates its inclusion. Separate by whether it has min/max or exact value.
    */
    // min/max keys:
    ['created_at'].forEach((k,i) => this.state['include_'+k] ? body['transfer'][k] = {min:this.state[k+'_min'],max:this.state[k+'_max']} : null);
    // exact keys:
    ['transaction_identifier'].forEach((k,i) => this.state['include_'+k] ? body['transfer'][k] = this.state[k] : null);
    /*if (Object.keys(body['invoice']).length === 0){
      body['invoice']['amount'] = {min:100,max:1000000000};
    }*/
    //body['tags'] = this.state.include_tags ? this.state.tags : [];
    fetch("/api/transfers/search", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.setState({
        show_results: true,
        results: json.transfers,
        total_count: json.count === null ? this.state.total_count : json.count,
        num_pages: Math.ceil((json.count === null ? this.state.total_count : json.count) / this.state.results_per_page),
        showing_page: page
      });
    });
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  render () {
    console.log(this.state);
    return (
      <div className="form-section" style={{width: '800px'}}>
        <h2>
          Search "{this.state.account.name}" Transactions by
        </h2>
        <table id="object-search-table"><tbody>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_created_at: v})} init_value={this.state.include_created_at} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Date created at
              </div>
              {this.state.include_created_at ? 
                <div style={{paddingTop: '15px'}}>
                  <MyInput type="date" onChange={this.on_change_created_at_min} value={this.state.displayed_created_at_min} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="Earliest date" note={null} /><br />
                  <MyInput type="date" onChange={this.on_change_created_at_max} value={this.state.displayed_created_at_max} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="Latest date" note={null} /><br />
                </div> : null}
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_transaction_identifier: v})} init_value={this.state.include_transaction_identifier} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Transaction identifier
              </div>
              {this.state.include_transaction_identifier ? 
                <div style={{paddingTop: '15px'}}>
                  <MyInput onChange={(e) => this.setState({transaction_identifier: e.target.value})} value={this.state.transaction_identifier} status={0} label="Transaction identifier" note={null} /><br />
                </div> : null}
            </td>
          </tr>
        </tbody></table>

        <button onClick={() => this.search(0, this.state.limit, true)} className="std-button">
          Search
        </button>

        {this.state.show_results ? <div>
          <h3>
            Results
          </h3>
          <table><tbody>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({show_time_local: v})} init_value={this.state.show_time_local} />
              </td>
              <td style={{color: 'grey', fontSize: '13px', verticalAlign: 'center', padding: '10px'}}>
                Use local time
              </td>
            </tr>
          </tbody></table>

          <table className="transfer-results-table"><tbody>
            <tr style={{color: 'grey'}}>
              <td>
                Transferred at
              </td>
              <td>
                Reference
              </td>
              <td>
                Description
              </td>
              <td>
                Amount
              </td>
              <td>
                Status
              </td>
              <td>
                Object
              </td>
            </tr>
            {this.state.results.map(
              (result, index) => 
              <tr key={index}>
                <td>
                  {this.state.show_time_local ? (new Date(result.transferred_at)).toLocaleString() : (new Date(result.transferred_at)).toISOString()}
                </td>
                <td>
                  {result.transaction_identifier.slice(0,16) + (result.transaction_identifier.length > 16 ? "..." : "")}
                </td>
                <td>
                  {result.description}
                </td>
                <td style={{textAlign: 'right'}}>
                  {this.amount_to_formatted_string(result.amount)}
                </td>
                <td style={{textAlign: 'center'}}>
                  {this.TRANSFER_STATUS_TO_NAME[String(result.status)]}
                </td>
                <td>
                  {result.invoice_code === null ? null : 
                    <a href={"/invoice/"+result.invoice_code+"/admin"} target="_blank">Invoice</a>}
                  {result.fund_code === null ? null : 
                    <a href={"/fund/"+result.fund_code+"/admin"} target="_blank">Fund</a>}
                  {result.customer_code === null ? null : 
                    <a href={"/customer/"+result.customer_code+"/admin"} target="_blank">Customer</a>}
                </td>
              </tr>
            )}
          </tbody></table>

          {this.state.total_count != null ? 
            <table className="search-pagination-table"><tbody>
              <tr>
                <td>
                  {this.state.showing_page > this.state.max_num_pages / 2 ? 
                    <div onClick={() => this.search(this.state.showing_page - ((this.state.max_num_pages-1)/2) - 1, this.state.limit, false)}>
                      ...
                    </div> : null}
                </td>
                {this.state.num_pages <= this.state.max_num_pages || this.state.showing_page < this.state.max_num_pages / 2 ? 
                  Array(Math.min(this.state.num_pages,this.state.max_num_pages)).fill(0).map((_,index) => 
                    <td key={index}>
                      <div onClick={() => this.search(index,this.state.limit,false)} style={this.state.showing_page === index ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                        {index+1}
                      </div>
                    </td>) : 
                  Array(this.state.max_num_pages).fill(0).map((_,i) => this.state.showing_page - ((this.state.max_num_pages - 1)/2) + i).map((v,index) => 
                    this.state.num_pages - v < ((this.state.max_num_pages - 1) / 2) - 1 ? null : 
                      <td key={index}>
                        <div onClick={() => this.search(v,this.state.limit,false)} style={this.state.showing_page === v ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                          {v + 1}
                        </div>
                      </td>)}
                <td>
                  {this.state.num_pages - this.state.showing_page > this.state.max_num_pages / 2 + 1 ? 
                    <div onClick={() => this.search(Math.max(this.state.showing_page + ((this.state.max_num_pages - 1) / 2) + 1,this.state.max_num_pages),this.state.limit,false)}>
                      ...
                    </div> : null}
                </td>
              </tr>
            </tbody></table> : null}

        </div> : null}
      </div>
    );
  }
}

export default TransferIndex
