import React from "react"
import PropTypes from "prop-types"
class AccountCompany extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null
    };

    this.display_abn = this.display_abn.bind(this);
    this.display_acn = this.display_acn.bind(this);

  }

  componentDidMount(){
    if (![null,undefined].includes(this.props.data)){
      this.setState({
        data: this.props.data,
        loaded: true
      });
    } else {
      this.setState({
        loaded: false
      });
    }
  }

  componentDidUpdate(prev_props){
    if (prev_props.data !== this.props.data){
      this.componentDidMount();
    }
  }

  display_abn(x){
    return x.split('').map((a,i) => [1,4,7].includes(i) ? a+' ' : a).join('');
  }

  display_acn(x){
    return [x.slice(0,3),x.slice(3,6),x.slice(6,9)].join(' ');
  }

  render () {
    console.log(this.state);
    return ([null,undefined].includes(this.state.data) ? 
      <div>

      </div> : 
      <div style={{marginLeft: '25px'}}>
        <table className="account-table"><tbody>
          {[['Name','name'],['ABN','abn'],['ACN','acn'],['Code','code'],['Existing since','created_at']].map(
            (field, index) =>
            (this.state.data[field[1]] === null ? null : 
              <tr key={index}>
                <td><div>{field[0]}</div></td>
                <td style={{fontFamily: "Roboto Mono", fontSize: '16px', textAlign: 'center', paddingTop: '20px'}}>{field[1].includes('_at') ? this.state.data[field[1]].slice(0,10) : (field[1] === 'abn' ? this.display_abn(this.state.data[field[1]]) : (field[1] === 'acn' ? this.display_acn(this.state.data[field[1]]) : this.state.data[field[1]]) )}</td>
              </tr>)
          )}
        </tbody></table>
      </div>
    );
  }
}

export default AccountCompany
