import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
class AuthenticatePasskey extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      typed_passkey: "",
      passkey_status: 0,
      passkey_error: ""
    };

    this.on_type_passkey = this.on_type_passkey.bind(this);
    this.on_key_down_passkey = this.on_key_down_passkey.bind(this);
    this.check_passkey = this.check_passkey.bind(this);

  }

  componentDidMount(){
    setTimeout(() => document.getElementById('passkey-input').focus(), 200);
  }

  on_type_passkey(e){
    const typed = e.target.value;
    this.setState({
      typed_passkey: typed
    });
  }

  check_passkey(){
    fetch("/api/"+this.props.object_name+"/"+this.props.code+"/checkpk?pk="+this.state.typed_passkey, {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.setState({
          passkey_status: 2,
          passkey_error: ""
        });
        location.href = "/"+this.props.object_name+"/"+this.props.code+"?pk="+this.state.typed_passkey;
      } else {
        this.setState({
          passkey_status: 3,
          passkey_error: "Not the correct passkey."
        });
      }
    });
  }

  on_key_down_passkey(e){
    if (e.keyCode === 13){
      // Log in
      this.check_passkey();
    }
  }

  render () {
    return (
      <div style={{width: '420px', backgroundColor: '#fff', padding: '15px', textAlign: 'center', margin: 'auto'}}>
        <h3>
          Passkey required
        </h3>
        <div style={{display: 'inline-block', margin: 'auto'}}>
          <MyInput onChange={this.on_type_passkey} value={this.state.typed_passkey} onKeyDown={this.on_key_down_passkey} status={this.state.passkey_status} note={this.state.passkey_status === 3 ? this.state.passkey_error : null} label="Passkey" style={{textAlign: 'center'}} id="passkey-input" />
        </div>
        <button className="std-button" onClick={this.check_passkey}>
          Confirm
        </button>
      </div>
    );
  }
}

export default AuthenticatePasskey
