import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
import Checkbox from "./Checkbox.js"
class SeriesSearch extends React.Component {
  constructor(props){
    super(props);
    this.RESULTS_PER_PAGE = 5;
    this.state = {
      include_code: true,
      code: "",
      include_tags: false,
      typed_tag: "",
      tags: [],

      include_created_at: false,
      created_at_max: Math.round((Date.now() - Date.now() % 86400000) / 1000),
      displayed_created_at_max: new Date(Date.now() - Date.now() % 86400000).toISOString().slice(0,10),
      created_at_min: Math.round((Date.now() - Date.now() % 86400000 - 86400000 * 7) / 1000),
      displayed_created_at_min: new Date(Date.now() - Date.now() % 86400000 - 86400000 * 7).toISOString().slice(0,10),
      include_billing_email: false,
      billing_email: "",

      //offset: 0,
      results: [],
      limit: this.RESULTS_PER_PAGE,
      total_count: null,
      results_per_page: this.RESULTS_PER_PAGE, // should be the same as limit
      max_num_pages: 5,
      num_pages: null,
      showing_page: 0
    };

    this.on_change_created_at_max = this.on_change_created_at_max.bind(this);
    this.on_change_created_at_min = this.on_change_created_at_min.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.search_to_csv = this.search_to_csv.bind(this);
    this.save_csv_file = this.save_csv_file.bind(this);
    this.as_csv = this.as_csv.bind(this);
    //this.show_page = this.show_page.bind(this);
    this.search = this.search.bind(this);

    //this.BILLING_EMAIL_CHAR_LIMIT = 240;
    //this.FUND_STATUSES = [0,1,2,4];
    //this.FUND_STATUS_TO_NAME = {'0':'Initiated','1':'Collecting','2':'Completed naturally','3':'Suspended','4':'Completed by direction'}; // perhaps add more
    this.MIN_TAG_LENGTH = 4;
    this.SERIES_STATUS_TO_WORD = {'0':'INITIATE','1':'ACTIVE','3':'SUSPEND','-1':'SUSPEND'};

  }

  on_change_created_at_max(e){
    this.setState({
      displayed_created_at_max: e.target.value,
      created_at_max: Math.round((new Date(e.target.value)).getTime() / 1000)
    });
  }

  on_change_created_at_min(e){
    this.setState({
      displayed_created_at_min: e.target.value,
      created_at_min: Math.round((new Date(e.target.value)).getTime() / 1000)
    });
  }

  search_to_csv(){
    if (this.state.num_pages === 1){
      return this.as_csv(this.state.results);
    }
    let body = {invoice:{},req_count:false};
    ['goal','created_at','due_at'].forEach((k,i) => this.state['include_'+k] ? body['invoice'][k] = {min:this.state[k+'_min'],max:this.state[k+'_max']} : null);
    ['billing_email','status'].forEach((k,i) => this.state['include_'+k] ? body['invoice'][k] = this.state[k] : null);
    body['tags'] = this.state.include_tags ? this.state.tags : [];
    fetch("/api/series/search", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      this.as_csv(json.invoices);
    });
  }

  as_csv(results){
    let i, j, row, t;
    let keys = ['code','name','goal','amount','created_at','start_date','end_date'];
    let output = ['Code,Title,Goal,Amount Paid,Created At,Start Date,End Date'];
    for (i = 0 ; i < results.length ; i++){
      row = []
      for (j = 0 ; j < keys.length ; j++){
        if (['name'].includes(keys[j])){
          row.push('"'+String(results[i][keys[j]])+'"');
        } else if (['goal','amount'].includes(keys[j])){
          row.push(String(Number(results[i][keys[j]])/100.0));
        } else if (['created_at','start_date','end_date'].includes(keys[j])){
          t = new Date(results[i][keys[j]]);
          if (results[i][keys[j]] === null || t === "Invalid Date"){
            row.push('');
          } else {
            row.push(t.toLocaleDateString()+' '+t.toLocaleTimeString());
          }
        } else {
          row.push(String(results[i][keys[j]]));
        }
      }
      /*for (const key in keys){
        console.log(key);
        if (['title','description'].includes(key)){
          row.push('"'+String(result[key])+'"');
        } else if (['amount','amount_paid'].includes(key)){
          row.push(String(Number(result[key])/100.0));
        } else {
          row.push(String(result[key]));
        }
      }*/
      output.push(row.join(','));
    }
    /*let encoded_uri = encodeURI("data:text/csv;charset=utf-8,"+output.join("\n"));
    let link = document.createElement('a');
    link.setAttribute("href", encoded_uri);
    link.setAttribute("download",(new Date()).toISOString().slice(0,10).split('-').join('')+String(Math.round((Math.random(0,1000000)+0.1)*1000000)));
    document.body.appendChild(link);
    link.click();*/
    /*console.log("Navigator");
    console.log(navigator);*/
    this.save_csv_file((new Date()).toISOString().slice(0,10).split('-').join('')+String(Math.round((Math.random(0,1000000)+0.1)*1000000))+'.csv', "data:attachment/text", output.join("\n"));
    //return output.join("\n");
  }

  save_csv_file(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    let a = document.createElement('a');
    let url = window.URL.createObjectURL(new Blob([data], {type: type}));
    a.setAttribute("href", url);
    a.setAttribute("download", name);
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  search(page,limit,req_count){
    console.log("page = "+String(page));
    let body = {series:{},offset:page*this.state.results_per_page,limit:limit,req_count:req_count};
    /*
      For each search term in state, only include it in the body if state indicates its inclusion. Separate by whether it has min/max or exact value.
    */
    // min/max keys:
    ['created_at'].forEach((k,i) => this.state['include_'+k] ? body['series'][k] = {min:this.state[k+'_min'],max:this.state[k+'_max']} : null);
    // exact keys:
    ['code','billing_email'].forEach((k,i) => this.state['include_'+k] ? body['series'][k] = this.state[k] : null);
    /*if (Object.keys(body['invoice']).length === 0){
      body['invoice']['amount'] = {min:100,max:1000000000};
    }*/
    body['tags'] = this.state.include_tags ? this.state.tags : [];
    fetch("/api/series/search", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.setState({
        show_results: true,
        results: json.series,
        total_count: json.count === null ? this.state.total_count : json.count,
        num_pages: Math.ceil((json.count === null ? this.state.total_count : json.count) / this.state.results_per_page),
        showing_page: page
      });
    });
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  render () {
    return (
      <div className="form-section" style={{width: '800px'}}>
      <h2>
        Search Series by
      </h2>
      <table id="object-search-table"><tbody>
        <tr>
          <td>
            <Checkbox on_toggle={(v) => this.setState({include_code: v})} init_value={this.state.include_code} />
          </td>
          <td>
            <div style={{fontSize: '16px', fontWeight: 'bold'}}>
              Series code
            </div>
            {this.state.include_code ? 
              <div style={{paddingTop: '15px'}}>
                <MyInput onChange={(e) => this.setState({code: e.target.value})} value={this.state.code} status={0} label="Series code" note={null} />
              </div> : null}
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox on_toggle={(v) => this.setState({include_created_at: v})} init_value={this.state.include_created_at} />
          </td>
          <td>
            <div style={{fontSize: '16px', fontWeight: 'bold'}}>
              Date created at
            </div>
            {this.state.include_created_at ? 
              <div style={{paddingTop: '15px'}}>
                <MyInput type="date" onChange={this.on_change_created_at_min} value={this.state.displayed_created_at_min} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="Earliest date" note={null} /><br />
                <MyInput type="date" onChange={this.on_change_created_at_max} value={this.state.displayed_created_at_max} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="Latest date" note={null} /><br />
              </div> : null}
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox on_toggle={(v) => this.setState({include_billing_email: v})} init_value={this.state.include_billing_email} />
          </td>
          <td>
            <div style={{fontSize: '16px', fontWeight: 'bold'}}>
              Billing email
            </div>
            {this.state.include_billing_email ? 
              <div style={{paddingTop: '15px'}}>
                <MyInput onChange={(e) => this.setState({billing_email: e.target.value})} value={this.state.billing_email} status={0} label="Billing email" note={null} />
              </div> : null}
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox on_toggle={(v) => this.setState({include_tags: v})} init_value={this.state.include_tags} />
          </td>
          <td>
            <div style={{fontSize: '16px', fontWeight: 'bold'}}>
              Tags
            </div>
            {this.state.include_tags ? 
              <div style={{marginTop: '10px'}}>
                <div>
                  {this.state.tags.map(
                    (tag, index) =>
                    <div key={index} style={{padding: '6px', paddingLeft: '12px', paddingRight: '12px', fontSize: '13px', margin: '5px', backgroundColor: '#040dba', color: '#fff', display: 'inline-block', borderRadius: '14px', fontWeight: 'bold'}}>
                      {tag} <i style={{marginLeft: '4px', cursor: 'pointer'}} className="fa-solid fa-circle-xmark" onClick={() => this.setState({tags: this.state.tags.filter((v) => v != tag)})}></i>
                    </div>
                  )}
                </div>
                <MyInput type="text" onChange={(e) => this.setState({typed_tag: e.target.value})} onKeyDown={(e) => e.code === "Enter" && this.state.typed_tag.length >= this.MIN_TAG_LENGTH ? this.setState({tags: this.state.tags.concat([this.state.typed_tag]), typed_tag: ""}) : null} value={this.state.typed_tag} status={0} label="Tag - press Enter to add" note={null} />
              </div> : null}
          </td>
        </tr>
      </tbody></table>

      <button className="std-button" onClick={() => this.search(0,this.state.limit,true)}>
        Search
      </button>

      {this.state.show_results ? 
        <div>
          <table style={{width: '100%'}}><tbody>
            <tr>
              <td>
                <h3>
                  Results ({this.state.total_count})
                </h3>
              </td>
              <td style={{textAlign: 'right'}}>
                <button className="button-blue" onClick={this.search_to_csv}>
                  Download CSV
                </button>
              </td>
            </tr>
          </tbody></table>

          <table id="series-results"><tbody>
            <tr>
              <th>
                Status
              </th>
              <th>
                Title
              </th>
              <th>
                Billing email
              </th>
              <th>
                Total paid
              </th>
            </tr>
            {this.state.results.map(
              (result, index) =>
              <tr key={index} onClick={() => {window.location.href = "/ser/"+result.code+"/admin";}}>
                <td>
                  {this.SERIES_STATUS_TO_WORD[String(result.status)]}
                </td>
                <td>
                  {result.title}
                </td>
                <td>
                  {result.billing_email}
                </td>
                <td>
                  ${this.amount_to_formatted_string(result.total_paid)}
                </td>
              </tr>
            )}
          </tbody></table>
        </div> : null}
      
      {this.state.total_count != null ? 
        <table className="search-pagination-table"><tbody>
          <tr>
            <td>
              {this.state.showing_page > this.state.max_num_pages / 2 ? 
                <div onClick={() => this.search(this.state.showing_page - ((this.state.max_num_pages-1)/2) - 1, this.state.limit, false)}>
                  ...
                </div> : null}
            </td>
            {this.state.num_pages <= this.state.max_num_pages || this.state.showing_page < this.state.max_num_pages / 2 ? 
              Array(Math.min(this.state.num_pages,this.state.max_num_pages)).fill(0).map((_,index) => 
                <td key={index}>
                  <div onClick={() => this.search(index,this.state.limit,false)} style={this.state.showing_page === index ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                    {index+1}
                  </div>
                </td>) : 
              Array(this.state.max_num_pages).fill(0).map((_,i) => this.state.showing_page - ((this.state.max_num_pages - 1)/2) + i).map((v,index) => 
                this.state.num_pages - v < ((this.state.max_num_pages - 1) / 2) - 1 ? null : 
                  <td key={index}>
                    <div onClick={() => this.search(v,this.state.limit,false)} style={this.state.showing_page === v ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                      {v + 1}
                    </div>
                  </td>)}
            <td>
              {this.state.num_pages - this.state.showing_page > this.state.max_num_pages / 2 + 1 ? 
                <div onClick={() => this.search(Math.max(this.state.showing_page + ((this.state.max_num_pages - 1) / 2) + 1,this.state.max_num_pages),this.state.limit,false)}>
                  ...
                </div> : null}
            </td>
          </tr>
        </tbody></table> : null}
    </div>);
  }
}

export default SeriesSearch
