import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
import Checkbox from "./Checkbox.js"
import Overlay from "./Overlay.js"

class NewCustomer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      /*typed_title: "New Fund",
      typed_title_status: 0,
      typed_title_error: null,
      typed_amount: "$1.00",
      displayed_amount: "$1.00",
      amount: 100,
      typed_amount_status: 0,
      typed_amount_error: null,
      typed_description: "",
      has_goal: true,
      start_date: (new Date(Date.now())).toISOString().slice(0,10),
      start_date_status: 0,
      start_date_error: null,
      has_end_date: true,
      end_date: (new Date(Date.now() + 30*86400000)).toISOString().slice(0,10),
      end_date_status: 0,
      end_date_error: null,
      has_passkey: false,
      passkey: "",
      passkey_status: 0,
      passkey_error: "",
      random_passkey_length: 6,
      goal_is_visible: false,
      amount_is_visible: false,
      payments_are_visible: false,
      hide_at_completion: false,
      show_payid: true,
      show_confirmation: false,*/
      ///////////////////////////
      typed_name: "New Customer",
      typed_name_status: 0,
      typed_name_error: null,
      /*total_added_externally: 0,
      total_added_externally_status: 0,
      total_added_externally_error: null,*/
      typed_amount: "$0.00",
      displayed_amount: "$0.00",
      amount: 0,
      typed_amount_status: 0,
      typed_amount_error: null,
      accounts: JSON.parse(this.props.accounts),
      selected_account_index: 0,
      email: "",
      email_status: 0,
      email_error: "",
      ref_preference: 1,
      has_passkey: false,
      passkey: "",
      passkey_status: 0,
      passkey_error: "",
      random_passkey_length: 6,
      balance_visible: true,
      payments_are_visible: false,
      custom_identifier: "",
      custom_identifier_status: 0,
      custom_identifier_error: null,
      show_payid: false
    };
    /*
      SECTIONS
      - Custom Identifier x
      - Name x
      - Email x
      - Original added externally x
      - Deposit Account x
      - Ref Preference x
      - Passkey x
      - Payments are visible
      - Balance visible
    */

    this.on_type_amount = this.on_type_amount.bind(this);
    this.on_focus_amount = this.on_focus_amount.bind(this);
    this.on_blur_amount = this.on_blur_amount.bind(this);
    this.is_valid_amount_input = this.is_valid_amount_input.bind(this);
    /*this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    //this.on_type_title = this.on_type_title.bind(this);
    //this.on_blur_title = this.on_blur_title.bind(this);
    this.on_type_description = this.on_type_description.bind(this);
    this.on_change_start_date = this.on_change_start_date.bind(this);
    this.on_blur_start_date = this.on_blur_start_date.bind(this);
    this.on_change_end_date = this.on_change_end_date.bind(this);
    this.on_blur_end_date = this.on_blur_end_date.bind(this);
    this.random_passkey = this.random_passkey.bind(this);
    this.on_type_passkey = this.on_type_passkey.bind(this);
    this.on_focus_passkey = this.on_focus_passkey.bind(this);
    this.on_blur_passkey = this.on_blur_passkey.bind(this);
    this.can_proceed = this.can_proceed.bind(this);
    this.submit = this.submit.bind(this);*/
    ////////////////
    this.on_type_name = this.on_type_name.bind(this);
    this.on_blur_name = this.on_blur_name.bind(this);
    this.on_type_email = this.on_type_email.bind(this);
    this.on_blur_email = this.on_blur_email.bind(this);
    this.on_focus_email = this.on_focus_email.bind(this);
    this.random_passkey = this.random_passkey.bind(this);
    this.on_type_passkey = this.on_type_passkey.bind(this);
    this.on_focus_passkey = this.on_focus_passkey.bind(this);
    this.on_blur_passkey = this.on_blur_passkey.bind(this);
    this.on_type_custom_identifier = this.on_type_custom_identifier.bind(this);
    this.on_blur_custom_identifier = this.on_blur_custom_identifier.bind(this);
    this.on_focus_custom_identifier = this.on_focus_custom_identifier.bind(this);
    this.can_proceed = this.can_proceed.bind(this);
    this.submit = this.submit.bind(this);

    this.MINIMUM_NAME_LENGTH = 5;
    this.MAXIMUM_NAME_LENGTH = 30;
    this.MINIMUM_AMOUNT = 0;
    this.MAXIMUM_AMOUNT = 1000000000;
    this.MAXIMUM_DESCRIPTION_LENGTH = 500;
    this.MINIMUM_PASSKEY_LENGTH = 4;
    this.MAXIMUM_PASSKEY_LENGTH = 16;
    this.MINIMUM_CUSTOM_IDENTIFIER_LENGTH = 1;
    this.MAXIMUM_CUSTOM_IDENTIFIER_LENGTH = 60;

  }

  on_type_name(e){
    const typed = e.target.value;
    if (typed.length < this.MAXIMUM_NAME_LENGTH){
      this.setState({
        typed_name: typed
      });
    }
  }

  on_blur_name(){
    const typed = this.state.typed_name.trim();
    if (typed.length < this.MINIMUM_NAME_LENGTH || typed.length > this.MAXIMUM_NAME_LENGTH){
      this.setState({
        typed_name: typed,
        typed_name_status: 3,
        typed_name_error: "Title must be between "+String(this.MINIMUM_NAME_LENGTH)+" and "+String(this.MAXIMUM_NAME_LENGTH)+" characters."
      });
    } else {
      this.setState({
        typed_name: typed,
        typed_name_status: 2,
        typed_name_error: null
      });
    }
  }
  
  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }
  

  on_type_amount(e){
    const typed = e.target.value;
    if (typed === '.' || this.is_valid_amount_input(typed) || typed.length === 0){
      this.setState({
        typed_amount: typed
      });
    }
  }

  on_focus_amount(){
    this.setState({
      typed_amount: this.state.typed_amount.replaceAll('$','').replaceAll(',',''),
      typed_amount_status: 1
    }, () => {
      document.getElementById('amount-input').select();
    });
    setTimeout(() => {
      document.getElementById('amount-input').select();
    }, 50);
  }

  on_blur_amount(){
    let amount = this.state.typed_amount.split('.').map((x,i) => i === 0 ? Number(x) * 100 : Number(x[0])*10+(x[1]===undefined ? 0 : Number(x[1]))).reduce((p,a) => p+a, 0);
    if (this.state.typed_amount === '.'){
      amount = 0;
    }
    const str = String(amount / 100);
    console.log("Amount: "+amount);
    this.setState({
      //typed_amount: '$' + (Number(amount) < 100 ? '0.'+(Number(amount.slice(-2)) < 10 ? '0' : '')+String(amount) : amount.slice(0, amount.length-2)+'.'+(Number(amount.slice(-2)) < 10 ? '0' : '')+Number(amount.slice(-2))),
      typed_amount: '$' + this.amount_to_formatted_string(amount),
      displayed_amount: '$' + this.amount_to_formatted_string(amount),
      amount: amount,
      typed_amount_status: amount < this.MINIMUM_AMOUNT || amount > this.MAXIMUM_AMOUNT ? 3 : 2,
      typed_amount_error: amount < this.MINIMUM_AMOUNT || amount > this.MAXIMUM_AMOUNT ? "The amount must be between $"+(this.MINIMUM_AMOUNT/100)+" and $"+(this.MAXIMUM_AMOUNT/100000000)+" million." : null
    });
  }

  is_valid_amount_input(input) {
    console.log("testing "+input);
    if (input === "." || input === "") {
        return true;
    }
    const parts = input.split(".");
    if (parts.length === 1){
      return !isNaN(parts[0]);
    } else if (parts.length > 2){
      return false;
    }
    return !isNaN(parts[0]) && !isNaN(parts[1]) && parts[1].length <= 2;
  }


  on_type_email(e){
    const typed = e.target.value;
    if (/^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~@.]*$/.test(typed)){
      this.setState({
        email: typed
      });
    }
  }

  on_blur_email(){
    if (!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.email)){
      this.setState({
        email_status: 3,
        email_error: "You must enter a valid email."
      });
    } else {
      this.setState({
        email_status: 2,
        email_error: ""
      });
    }
  }

  on_focus_email(){
    this.setState({
      email_status: 1,
      email_error: null
    });
  }


  on_type_custom_identifier(e){
    const typed = e.target.value;
    if (typed.length <= this.MAXIMUM_CUSTOM_IDENTIFIER_LENGTH && !typed.includes(' ')){
      this.setState({
        custom_identifier: typed
      });
    }
  }

  on_blur_custom_identifier(){
    //const typed = e.target.value;
    if (this.state.custom_identifier.length < this.MINIMUM_CUSTOM_IDENTIFIER_LENGTH || this.state.custom_identifier.length > this.MAXIMUM_CUSTOM_IDENTIFIER_LENGTH || this.state.custom_identifier.includes(' ')){
      this.setState({
        custom_identifier_status: 3,
        custom_identifier_error: "The custom identifier must be between "+this.MINIMUM_CUSTOM_IDENTIFIER_LENGTH+" and "+this.MAXIMUM_CUSTOM_IDENTIFIER_LENGTH+" characters."
      });
    } else {
      this.setState({
        custom_identifier_status: 2,
        custom_identifier_error: ""
      });
    }
  }

  on_focus_custom_identifier(){
    this.setState({
      custom_identifier_status: 1,
      custom_identifier_error: null
    });
  }


  random_passkey(){
    // randpk
    fetch("/randpk?n="+String(this.state.random_passkey_length), {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        passkey: json.random,
        passkey_status: 2,
        passkey_error: null
      });
    });
  }

  on_type_passkey(e){
    const typed = e.target.value;
    if (/^[a-zA-Z0-9_\-]+$/.test(typed) && typed.length < this.MAXIMUM_PASSKEY_LENGTH){
      this.setState({
        passkey: typed
      });
    }
  }

  on_focus_passkey(){
    this.setState({
      passkey_status: 1
    });
    setTimeout(() => {
      document.getElementById('passkey-input').select();
    }, 50);
  }

  on_blur_passkey(){
    this.setState({
      passkey_status: this.state.passkey.length < this.MINIMUM_PASSKEY_LENGTH ? 3 : 2,
      passkey_error: this.state.passkey.length < this.MINIMUM_PASSKEY_LENGTH ? "Passkey must be between "+String(this.MINIMUM_PASSKEY_LENGTH)+" and "+String(this.MAXIMUM_PASSKEY_LENGTH)+" characters." : null
    });
  }
  

  can_proceed(){
    return [0,2].includes(this.state.typed_name_status) &&
      [0,2].includes(this.state.typed_amount_status) &&
      this.state.email_status === 2 &&
      (!this.state.has_passkey || this.state.passkey_status === 2) &&
      [2].includes(this.state.custom_identifier_status);
  }


  submit(){
    let body = {
      customer: {
        name: this.state.typed_name,
        custom_identifier: this.state.custom_identifier,
        total_added_externally: this.state.amount,
        balance_visible: this.state.balance_visible,
        payments_are_visible: this.state.payments_are_visible,
        show_payid: this.state.show_payid,
        passkey_ciphertext: this.state.passkey,
        email: this.state.email,
        ref_preference: this.state.ref_preference,
        account_id: this.state.accounts[this.state.selected_account_index].id
      }
    };
    fetch("/customers/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
    });
  }


  render () {
    console.log(this.state);
    return (
      <div className="form-section">

        {this.state.show_confirmation ? 
          <Overlay on_cancel={() => this.setState({show_confirmation: false})}>
            <div>
              <h3 style={{color: 'grey'}}>
                Confirm <span style={{color: 'black'}}>{this.state.typed_name}</span>
              </h3>
              <table id="confirmation-table"><tbody>
                <tr>
                  <td>
                    Name
                  </td>
                  <td>
                    {this.state.typed_name}
                  </td>
                </tr>
                <tr>
                  <td>
                    Email
                  </td>
                  <td>
                    {this.state.email}
                  </td>
                </tr>
                <tr>
                  <td>
                    Custom identifier
                  </td>
                  <td>
                    {this.state.custom_identifier}
                  </td>
                </tr>
                <tr>
                  <td>
                    Starting balance
                  </td>
                  <td>
                    {this.state.displayed_amount}
                  </td>
                </tr>
                <tr>
                  <td>
                    Deposit Account
                  </td>
                  <td>
                    {this.state.accounts[this.state.selected_account_index].name}
                  </td>
                </tr>
                <tr>
                  <td>
                    Passkey
                  </td>
                  <td>
                    {this.state.has_passkey ? this.state.passkey : "No passkey"}
                  </td>
                </tr>
                <tr>
                  <td>
                    Balance is visible
                  </td>
                  <td>
                    {this.state.balance_visible ? "Yes" : "No"}
                  </td>
                </tr>
                <tr>
                  <td>
                    Payments are visible
                  </td>
                  <td>
                    {this.state.payments_are_visible ? "Yes" : "No"}
                  </td>
                </tr>
              </tbody></table>
              <button className="std-button" onClick={() => this.submit()}>
                Confirm
              </button>
              <button className="std-button" onClick={() => this.setState({show_confirmation: false})}>
                Cancel
              </button>
            </div>
          </Overlay> : null}
        
        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <h2>
                {this.state.typed_name}
              </h2>
            </td>
            <td style={{textAlign: 'right'}}>
              <h2 style={{color: 'grey'}}>
                {this.state.displayed_amount}
              </h2>
            </td>
          </tr>
        </tbody></table>

        <div className="form-intra-section">
          <div className="myinput-wrapper-centre">
            <MyInput onChange={this.on_type_name} value={this.state.typed_name} onBlur={this.on_blur_name} status={this.state.typed_name_status} note={this.state.typed_name_status === 3 ? this.state.typed_name_error : null} label="Name of the Customer" />
          </div>
          <div className="myinput-wrapper-centre">
            <MyInput onChange={this.on_type_email} value={this.state.email} status={this.state.email_status} onFocus={this.on_focus_email} onBlur={this.on_blur_email} label="Customer email" note={this.state.email_status === 3 ? this.state.email_error : null} />
          </div>
        </div>

        <div className="form-intra-section">
          <h3>
            Custom identifier
          </h3>
          <div className="myinput-wrapper-centre">
            <MyInput onChange={this.on_type_custom_identifier} value={this.state.custom_identifier} onFocus={this.on_focus_custom_identifier} onBlur={this.on_blur_custom_identifier} status={this.state.custom_identifier_status} note={this.state.custom_identifier_status === 3 ? this.state.custom_identifier_error : null} label="Custom identifier" />
          </div>
        </div>
        
        <div className="form-intra-section">
          <h3>
            Starting balance
          </h3>
          <div className="myinput-wrapper-centre">
            <MyInput onChange={this.on_type_amount} value={this.state.typed_amount} onFocus={this.on_focus_amount} onBlur={this.on_blur_amount} status={this.state.typed_amount_status} note={this.state.typed_amount_status === 3 ? this.state.typed_amount_error : null} label="Starting balance" id="amount-input" style={{textAlign: 'right'}} />
          </div>
        </div>

        <div>
          <h3>
            Deposit Account
          </h3>
          <p style={{color: 'grey', fontSize: '14px'}}>
            This is the account that the payor is told to deposit the funds into.
          </p>
          {this.state.accounts.map(
            (account, index) =>
            <div className={this.state.selected_account_index === index ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({selected_account_index: index})} key={index}>
              <table><tbody>
                <tr>
                  <td>
                    <div>
                      <div />
                    </div>
                  </td>
                  <td>
                    <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                      {account.name}
                    </div>
                    <table style={{color: 'grey', fontSize: '16px', fontFamily: 'Roboto Mono'}}><tbody>
                      <tr>
                        <td style={{}}>
                          BSB:{account.bsb}
                        </td>
                        <td style={{}}>
                          Acct:{account.account_number}
                        </td>
                      </tr>
                    </tbody></table>
                  </td>
                </tr>
              </tbody></table>
            </div>
          )}
        </div>

        <div style={{marginTop: '40px'}}>
          <h3>
            Privacy
          </h3>
          <table className="toggle-table-general"><tbody>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({has_passkey: v})} init_value={this.state.has_passkey} />
              </td>
              <td>
                <h3>
                  Has passkey
                </h3>
                <p>
                  A passkey in addition to a URL is required to view the invoice.
                </p>
              </td>
            </tr>
            {this.state.has_passkey ? 
            <tr>
              <td></td>
              <td>
                {this.state.has_passkey ? 
                  <div>
                    <MyInput onChange={this.on_type_passkey} value={this.state.passkey} status={this.state.passkey_status} id="passkey-input" onFocus={this.on_focus_passkey} onBlur={this.on_blur_passkey} label="Passkey" note={this.state.passkey_status === 3 ? this.state.passkey_error : null} />
                    <table><tbody>
                      <tr>
                        <td>
                          <button className="std-button" onClick={this.random_passkey} style={{marginTop: '10px'}}>
                            Random
                          </button>
                        </td>
                        <td style={{paddingTop: '15px'}}>
                          <div>
                            <input type="range" min="4" max="16" onChange={(e) => this.setState({random_passkey_length: Number(e.target.value)})} value={String(this.state.random_passkey_length)} style={{width: '100%', margin: 'auto'}} />
                          </div>
                          <div style={{textAlign: 'center', fontSize: '13px', color: 'grey'}}>
                            {this.state.random_passkey_length} characters
                          </div>
                        </td>
                      </tr>
                    </tbody></table>
                  </div> : null}
              </td>
            </tr> : null}
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({balance_visible: v})} init_value={this.state.balance_visible} />
              </td>
              <td>
                <h3>
                  Balance is visible
                </h3>
                <p>
                  Any person who can access the customer information can see the balance of the customer (total received plus total added externally minus total debited).
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({payments_are_visible: v})} init_value={this.state.payments_are_visible} />
              </td>
              <td>
                <h3>
                  Payments are visible
                </h3>
                <p>
                  Any person who can access the customer information can see superficial information about transactions affecting the customer's balance with Paysolve (this does not reveal any bank information).
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({show_payid: v})} init_value={this.state.show_payid} />
              </td>
              <td>
                <h3>
                  Show PayID
                </h3>
                <p>
                  Show the PayID of the deposit account to a viewer as a payment method.
                </p>
              </td>
            </tr>
          </tbody></table>
        </div>

        <div style={{marginTop: '40px'}}>
          <h3>
            Customer Reference Preferences
          </h3>
          <p style={{color: 'grey', fontSize: '14px'}}>
            This will determine whether a single reference is generated, or one for each email instance provided by a customer.
          </p>

          <div className={this.state.ref_preference === 0 ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({ref_preference: 0})}>
            <table><tbody>
              <tr>
                <td>
                  <div>
                    <div />
                  </div>
                </td>
                <td>
                  <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                    Single Reference Code
                  </div>
                  <p style={{color: "grey", fontSize: "14px"}}>
                    Payor(s) will not be able to obtain a unique reference.
                  </p>
                </td>
              </tr>
            </tbody></table>
          </div>
          <div className={this.state.ref_preference === 1 ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({ref_preference: 1})}>
            <table><tbody>
              <tr>
                <td>
                  <div>
                    <div />
                  </div>
                </td>
                <td>
                  <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                    Allow email-unique references
                  </div>
                  <p style={{color: "grey", fontSize: "14px"}}>
                    Payors can register an email and obtain a unique reference for payment.
                  </p>
                </td>
              </tr>
            </tbody></table>
          </div>
          <div className={this.state.ref_preference === 2 ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({ref_preference: 2})}>
            <table><tbody>
              <tr>
                <td>
                  <div>
                    <div />
                  </div>
                </td>
                <td>
                  <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                    Require email-unique references
                  </div>
                  <p style={{color: "grey", fontSize: "14px"}}>
                    Payors must register an email and obtain a unique reference for payment.
                  </p>
                </td>
              </tr>
            </tbody></table>
          </div>
        </div>

        {this.can_proceed() ? 
          <button onClick={() => this.setState({show_confirmation: true})} className="std-button">
            Proceed
          </button> : null}

      </div>
    );
  }
}

export default NewCustomer
