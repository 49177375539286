import React from "react"
import PropTypes from "prop-types"
import ProofOfWork from "./ProofOfWork.js"
class ProofOfWorkChain extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      num_challenges: Number(this.props.num_challenges),
      challenges: [],
      solved_challenges: [],
      solving_index: -1,
      body: this.props.body,
      go: this.props.go,
      pow_go: false,
      challenge_zeros: Number(this.props.challenge_zeros),
      time_start: 0,
      time_finish: 0
    };

    this.start = this.start.bind(this);
    this.ensure_solving = this.ensure_solving.bind(this);
    this.on_solve = this.on_solve.bind(this);
    this.get_challenge = this.get_challenge.bind(this);

    this.OBTAIN_CHALLENGE_GAP_MS = 1050;
    
  }

  componentDidMount(){
    this.start();
  }

  start(){
    this.setState({
      num_challenges: Number(this.props.num_challenges),
      challenges: [],
      solved_challenges: [],
      solving_index: -1,
      body: this.props.body,
      go: this.props.go,
      pow_go: false,
      time_start: performance.now(),
      challenge_zeros: Number(this.props.challenge_zeros)
    }, () => {
      this.get_challenge();
    });
  }

  ensure_solving(){
    if (this.state.solving_index < 0){
      this.setState({
        solving_index: this.state.challenges.map((c,i) => c.solved).indexOf(false),
        pow_go: true
      });
    }
  }

  on_solve(s){
    let challenges = this.state.challenges;
    challenges[this.state.solving_index].solved = true;
    let solved_challenges = this.state.solved_challenges;
    solved_challenges.push(s);
    if (solved_challenges.length >= this.state.num_challenges){
      this.setState({
        solved_challenges: solved_challenges,
        challenges: challenges,
        time_finish: performance.now()
      });
      this.props.on_solve(solved_challenges);
    } else {
      this.setState({
        solved_challenges: solved_challenges,
        challenges: challenges,
        solving_index: solved_challenges.map((c,i) => c.solved).indexOf(false)
      });
    }
  }

  get_challenge(){
    console.log("Getting challenge");
    fetch("/pow/getchal", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        body: this.state.body
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      let challenges = this.state.challenges;
      challenges.push({challenge: json.challenge, solved: false});
      this.setState({
        challenges: challenges
      }, () => {
        this.ensure_solving();
        if (this.state.challenges.length < this.state.num_challenges){
          setTimeout(() => {
            this.get_challenge();
          }, this.OBTAIN_CHALLENGE_GAP_MS);
        }
      });
    });
  }

  componentDidUpdate(prev_props){
    if (prev_props.body !== this.props.body || prev_props.go !== this.props.go){
      this.start();
    }
  }

  render () {
    console.log(this.state);
    return (
      <div style={{width: '278px', border: 'solid', borderWidth: '1px', borderColor: 'grey', borderRadius: '4px', padding: '8px'}}>
        <div style={{fontSize: '11px', color: 'grey'}}>
          Proof-of-Work Challenge System
        </div>
        <div style={{fontSize: '14px', color: 'grey', fontFamily: 'Roboto Mono', padding: '5px'}}>
          Solved {this.state.solved_challenges.length}/{this.state.num_challenges} challenges{this.state.solved_challenges.length === this.state.num_challenges ? " in "+((this.state.time_finish - this.state.time_start) / 1000.0).toFixed(2)+"s" : null}
        </div>
        <ProofOfWork input={this.state.solving_index >= 0 ? this.state.challenges[this.state.solving_index].challenge : undefined} go={this.state.pow_go} num_zeros={this.state.challenge_zeros} on_solve={this.on_solve} />
      </div>
    );
  }
}

export default ProofOfWorkChain
