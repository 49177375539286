import React from "react"
import PropTypes from "prop-types"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement, // doughtnut
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';

import Test2 from "./Test2.js";

class Test extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      friends: 0
    };

    this.increase_friend_count = this.increase_friend_count.bind(this);

  }

  increase_friend_count(){
    this.setState({
      friends: this.state.friends+1
    });
  }

  render () {
    return (
      <div>
        <div>
          You currently have {this.state.friends} friend{this.state.friends !== 1 ? 's' : ''}
          
        </div>
        <div>
          <button onClick={this.increase_friend_count}>
            Increase friend count
          </button>
        </div>
      </div>
    );
  }
}

export default Test
