import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"
class AccountBilling extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      loaded: false,
      messages: [],
      show_upgrade: false,
      upgrade_selected_index: -1
    };

    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  componentDidMount(){
    if (![null,undefined].includes(this.props.data)){
      this.setState({
        data: this.props.data,
        loaded: true
      });
    } else {
      this.setState({
        loaded: false
      });
    }
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  componentDidUpdate(prev_props){
    if (prev_props.data !== this.props.data){
      this.componentDidMount();
    }
  }

  render () {
    console.log("Billing state");
    console.log(this.state);
    return (this.state.loaded ? 
      <div style={{marginLeft: '25px'}}>

        {this.state.show_upgrade ? 
          <Overlay on_cancel={() => this.setState({show_upgrade: false})} title="Upgrade Plan" width="480px">
            <div>
              <div style={{display: 'table', margin: 'auto'}}>
                {this.state.data.available_plans.filter((p) => p.base_amount > this.state.data.current_bill.billingplan.base_amount).map(
                  (plan, index) =>
                  <div className={this.state.upgrade_selected_index === index ? "selection-box-selected" : "selection-box"} onClick={() => console.log(index)} key={index}>
                    <table><tbody>
                      <tr>
                        <td>
                          <div style={{margin: 'auto'}}>
                            <div />
                          </div>
                        </td>
                        <td>
                          <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                            {plan.title}
                          </div>
                          <p style={{color: "grey", fontSize: "14px", fontFamily: "Roboto Mono"}}>
                            Base amount: ${this.amount_to_formatted_string(plan.base_amount)}
                          </p>
                        </td>
                      </tr>
                    </tbody></table>
                  </div>
                )}
              </div>
            </div>
          </Overlay> : null}
        
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}
        
        <table><tbody>
          <tr>
            <td>
              <h3>
                Outstanding Bills
              </h3>
            </td>
          </tr>
        </tbody></table>
        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <h3>
                Current Billing Period<br />
                <span style={{color: 'grey'}}>{String(new Date(this.state.data.current_bill.start)).slice(4,15)} - {String(new Date(this.state.data.current_bill.end)).slice(4,15)}</span>
              </h3>
            </td>
            <td style={{textAlign: 'right'}}>
              <h3>
                ${this.amount_to_formatted_string(this.state.data.current_bill.total)}<br />
                <span style={{fontSize: '14px', color: 'grey', fontWeight: 'normal'}}>As at {String(new Date(this.state.data.current_bill.time_now)).slice(4,24)}</span>
              </h3>
            </td>
          </tr>
        </tbody></table>

        {!this.state.data.billing_enabled ?
        <div className={"flash flash-info"}>
          <table><tbody>
            <tr>
              <td style={{fontSize: '18px'}}>
                <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON['info']}></i>
              </td>
              <td>
                You are not able to upgrade your plan beyond the free plan because Billing is not enabled. To enable Billing, you will need to add at least one Card in the Cards section. <a href="/" target="_blank">Click here</a> to learn how Billing works.
              </td>
            </tr>
          </tbody></table>
        </div> : null}

        <div className="billing-tally">
          <div style={{fontSize: '15px', fontFamily: 'Roboto Mono', textDecoration: 'underline'}}>
            Bill # {this.state.data.current_bill.code}
          </div>
          <table><tbody>
            <tr>
              <td>
                Item
              </td>
              <td>
                Price
              </td>
              <td>
                Qty
              </td>
              <td>
                Total
              </td>
            </tr>
            <tr>
              <td>
                Plan base amount
              </td>
              <td>
                {this.amount_to_formatted_string(this.state.data.current_bill.billingplan.base_amount)}
              </td>
              <td>
                1
              </td>
              <td>
                {this.amount_to_formatted_string(this.state.data.current_bill.billingplan.base_amount)}
              </td>
            </tr>
            <tr>
              <td>
                Excess transactions
              </td>
              <td>
                {this.amount_to_formatted_string(Math.floor(this.state.data.current_bill.billingplan.excess_transaction_rate_1000 / 10)) + String(this.state.data.current_bill.billingplan.excess_transaction_rate_1000 % 10)}
              </td>
              <td>
                {Math.max(0,this.state.data.current_bill.transactions - this.state.data.current_bill.billingplan.max_transactions)}
              </td>
              <td>
                {this.amount_to_formatted_string(Math.floor(this.state.data.current_bill.billingplan.excess_transaction_rate_1000 * Math.max(0,this.state.data.current_bill.transactions - this.state.data.current_bill.billingplan.max_transactions) / 10.0))}
              </td>
            </tr>
            <tr>
              <td>
                Total
              </td>
              <td>
              </td>
              <td>
              </td>
              <td style={{borderTop: 'solid', borderTopWidth: '1px', borderTopColor: 'grey'}}>
                {this.amount_to_formatted_string(this.state.data.current_bill.total)}
              </td>
            </tr>
          </tbody></table>
        </div>

        <h3 style={{color: 'grey'}}>
          Usage
        </h3>
        <table className="account-general-table"><tbody>
          {[
            ['Transactions','transactions'],
            ['Peak users','max_users'],
            ['Value transacted','value'],
            ['Objects created','objects_created']
          ].map(
            (line, index) =>
            <tr key={index}>
              <td>
                {line[0]}
              </td>
              <td>
                {this.state.data.current_bill[line[1]]}
              </td>
            </tr>
          )}
        </tbody></table>

        <h3 style={{color: 'grey'}}>
          Plan information
        </h3>
        <table className="account-general-table"><tbody>
          {/* 0:straight number, 1:$ with .00, 2: $ / 1000 */}
          {[['Plan name','title',0],
            ['Code','code',0],
            ['Base cost','base_amount',1],
            ['Max Transactions','max_transactions',0],
            this.state.data.current_bill.billingplan.allow_excess_transactions ? ['Excess transaction rate','excess_transaction_rate_1000',2] : null,
            ['Max value','max_value',1],
            ['Max Users','max_users',0],
            ['Max Objects created', 'max_created_objects',0],
          ].filter(n => n).map(
              (line, index) =>
              <tr key={index}>
                <td>
                  {line[0]}
                </td>
                <td>
                  {this.state.data.current_bill.billingplan[line[1]] === -1 ? 'Unlimited' : 
                  [this.state.data.current_bill.billingplan[line[1]],
                    '$'+this.amount_to_formatted_string(this.state.data.current_bill.billingplan[line[1]]),
                    '$'+this.amount_to_formatted_string(Math.round(this.state.data.current_bill.billingplan[line[1]] / 10.0)) + String(this.state.data.current_bill.billingplan[line[1]] % 10)][line[2]]}
                </td>
              </tr>
            )}
          {/*<tr>
            <td>
              Base cost
            </td>
            <td>
              ${this.amount_to_formatted_string(this.state.data.current_bill.billingplan.base_amount)}
            </td>
          </tr>
          <tr>
            <td>

            </td>
            </tr>*/}
        </tbody></table>
        <div style={{textAlign: 'center'}}>
          <button className="std-button" style={this.state.data.billing_enabled ? {} : {backgroundColor:'#c9c9c9', textDecoration: 'line-through', cursor: 'not-allowed'}} onClick={() => this.state.data.billing_enabled ? this.setState({show_upgrade: true, upgrade_selected_index: this.state.data.available_plans.map((p) => p.code === this.state.data.current_bill.code).indexOf(true)}) : null}>
            Upgrade Plan
          </button>
        </div>
        
      </div>
    : <div>
      Loading...
    </div>);
  }
}

export default AccountBilling
