import React from "react"
import PropTypes from "prop-types"
import FundPayment from "./FundPayment.js"
class ShowFund extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      fund: null,
      init_loaded: false,
      messages: []
		};

    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.click_to_copy = this.click_to_copy.bind(this);

  }

  componentDidMount(){
    fetch("/api/fund/"+this.props.code+(this.props.passkey === null || this.props.passkey === undefined ? "" : "?pk="+this.props.passkey), {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      if ([null,undefined].includes(json.fund)){
        this.add_message("Encountered error obtaining information.",'danger');
        this.setState({
          loaded: true
        });
      } else {
        this.setState({
          fund: json.fund,
          init_loaded: true
        });
      }
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  click_to_copy(e, text){
    navigator.clipboard.writeText(text);
    console.log(e);
    e.target.innerHTML = "COPIED!"
    setTimeout(() => {
      e.target.innerHTML = "COPY";
    }, 1000);
  }

  render () {
    return (this.state.init_loaded ? 
      <div className="form-section">

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td>

                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              {this.state.fund.has_goal ? 
                <div style={{backgroundColor: this.state.fund.amount >= this.state.fund.goal ? '#32bfb8' : '#040dba', color: '#fff', padding: '3px', fontSize: '13px', borderRadius: '4px', width: '80px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer'}}>
                  {this.state.fund.amount >= this.state.fund.goal ? 'REACHED' : 'OPEN'}
                </div> : null}
            </td>
            <td>
              <div style={{fontSize: '16px', color: 'grey', textAlign: 'right', margin: '5px', fontFamily: 'Roboto Mono'}}>
                Fund # {this.state.fund.code}
              </div>
            </td>
          </tr>
        </tbody></table>

        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <h2>
                {this.state.fund.name}
              </h2>
            </td>
            <td style={{textAlign: 'right'}}>
              <h2 style={{color: 'grey'}}>
                ${this.amount_to_formatted_string(this.state.fund.amount) + (this.state.fund.has_goal ? " / $"+this.amount_to_formatted_string(this.state.fund.goal) : '')}
              </h2>
            </td>
          </tr>
        </tbody></table>

        {this.state.fund.has_goal ? 
          <div>
            <div className="progress-bar-shell" style={{margin: 'auto'}}>
              {this.state.fund.amount > 0 ? 
                <div className="progress-bar-line" style={{width: String(Math.min(Math.round((this.state.fund.amount / this.state.fund.goal)*100),100))+'%', backgroundColor: this.state.fund.amount >= this.state.fund.goal ? '#32bfb8' : '#040dba'}}>
                </div> : null}
            </div><br />
            <div style={{textAlign: 'center', color: 'grey', fontSize: '14px', marginTop: '-10px'}}>
              {Math.floor(Math.min(100*this.state.fund.amount / this.state.fund.goal,100))}% complete
            </div>
          </div> : null}

        <div className="invoice-tally" style={{marginTop: '40px', marginBottom: '50px'}}>
          <table><tbody>
            <tr>
              <td>
                Target
              </td>
              <td>
                {this.amount_to_formatted_string(this.state.fund.goal)}
              </td>
            </tr>
            <tr>
              <td>
                Paid
              </td>
              <td>
                {this.amount_to_formatted_string(this.state.fund.amount)}
              </td>
            </tr>
            <tr>
              <td>
                Balance
              </td>
              <td style={{borderTop: 'solid', borderTopWidth: '1px', borderColor: '#c9c9c9'}}>
                {this.amount_to_formatted_string(Math.max(0,this.state.fund.goal - this.state.fund.amount))}
              </td>
            </tr>
          </tbody></table>
        </div>

        <h3>
          Contribute to this fund
        </h3>

        <FundPayment code={this.props.code} passkey={this.props.passkey} auth_token={this.props.auth_token} />

      </div> : 
      <div className="form-section">
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td>

                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}
      </div>
    );
  }
}

export default ShowFund
