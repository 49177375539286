import React from "react"
import PropTypes from "prop-types"
class AdminConsole extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      log: [],
      input: ""
    };

    this.submit_command = this.submit_command.bind(this);

  }

  submit_command(){
    fetch("/api/admin/console", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        command: this.state.input
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to update user.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      let log = this.state.log;
      log.push({input: this.state.input, output: json.result});
      this.setState({
        log: log,
        input: ""
      });
    });
  }

  render () {
    return (
      <div style={{backgroundColor: "black", color: '#fff', width: '800px', height: '400px', borderRadius: '6px', padding: '5px', fontFamily: 'Roboto Mono', position: 'relative', fontSize: '14px', overflow: 'scroll'}}>
        <div style={{position: 'absolute', bottom: '10px', left: '10px'}}>
          {this.state.log.map(
            (l,i) =>
              <div key={i}>
                &gt; {l.input}<br />
                {l.output}
              </div>
          )}
          <table><tbody>
            <tr>
              <td>
                &gt; 
              </td>
              <td>
                <input onChange={(e) => this.setState({input: e.target.value})} onKeyDown={(e) => e.key === 'Enter' ? this.submit_command() : (e.key === "ArrowUp" && this.state.log.length > 0 ? this.setState({input: this.state.log[this.state.log.length-1].input}) : null)} value={this.state.input} style={{border: 'none', backgroundColor: 'black', color: '#fff', fontFamily: 'Roboto Mono', width: '780px', fontSize: '14px'}} />
              </td>
            </tr>
          </tbody></table>
        </div>
      </div>
    );
  }
}

export default AdminConsole
