import React from "react"
import PropTypes from "prop-types"
class MyInput extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      typed: "",
      focused: false,
      status: this.props.status === undefined || this.props.status === null ? 0 : Number(this.props.status),
      value: this.props.value,
      note: this.props.note === undefined || this.props.note === null ? null : this.props.note,
      id: this.props.id === undefined ? Math.random().toString(36).slice(2) : this.props.id
    };

    this.STATUS_COLOURS = ['#040dba','#5158e0','#32bfb8', 'red']; // normal, active, success, error

    this.handle_change = this.handle_change.bind(this);
    this.handle_focus = this.handle_focus.bind(this);
    this.handle_blur = this.handle_blur.bind(this);

  }

  handle_change(e){
    this.setState({
      typed: e.target.value
    });
    if (this.props.onChange != undefined){
      this.props.onChange(e);
    }
  }

  handle_focus(e){
    this.setState({
      focused: true,
      status: this.state.status < 1 ? 1 : this.state.status
    });
    if (this.props.onFocus != undefined){
      this.props.onFocus(e);
    }
  }

  handle_blur(e){
    this.setState({
      focused: false,
      status: this.state.status < 2 ? 0 : this.state.status
    });
    if (this.props.onBlur != undefined){
      this.props.onBlur(e);
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.status !== prevProps.status){
      this.setState({
        status: this.props.status === undefined ? 0 : Number(this.props.status)
      });
    }
    if (this.props.value !== prevProps.value){
      this.setState({
        value: this.props.value
      });
    }
    if (this.props.note !== prevProps.note){
      this.setState({
        note: this.props.note === undefined || this.props.note === null ? null : this.props.note
      });
    }
  } 

  render () {
    //console.log(this.state);
    let style;
    if (this.props.style === undefined){
      style = {borderColor: this.STATUS_COLOURS[this.state.status]};
    } else {
      style = {borderColor: this.STATUS_COLOURS[this.state.status], ...this.props.style};
    }
    return (
      <div style={{height: '80px', width: '292px'}}>
        {this.props.label === null ? null : 
        <div style={{color: 'grey', fontSize: '13px', marginBottom: '4px', textAlign: 'left'}}>
          {this.props.label}
        </div>}
        <input type={this.props.type === undefined ? "text" : this.props.type} 
          onKeyDown={this.props.onKeyDown === undefined ? null : this.props.onKeyDown}
          onChange={this.handle_change}
          value={this.props.value === undefined || this.props.value === null ? this.state.typed : this.state.value}
          onFocus={this.handle_focus}
          onBlur={this.handle_blur}
          onPaste={this.props.onPaste === undefined ? null : this.props.onPaste}
          className="standard-input"
          style={style}
          id={this.state.id}
           />
          {this.state.note === null || this.state.note.length === 0 ? null : 
            <div style={{color: this.STATUS_COLOURS[this.state.status], fontSize: '12px', textAlign: 'left'}}>
              {this.props.note}
            </div>}
      </div>
    );
  }
}

export default MyInput
