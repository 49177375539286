import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"
class UserIndex extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      init_loaded: false,
      users: [],
      new_user_overlay: false,
      typed_email: "",
      typed_email_status: 0,
      typed_email_error: "",
      typed_name: "",
      loading: false,
      messages: []
    };

    this.on_type_email = this.on_type_email.bind(this);
    this.on_blur_email = this.on_blur_email.bind(this);
    this.on_type_name = this.on_type_name.bind(this);
    this.create_new_user = this.create_new_user.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);

    //this.USER_STATUSES = ['Suspended','Unconfirmed by email','Confirmed user','Forgot password - resetting',''];
    this.USER_STATUSES = {
      '-2':'Suspended by PaySolve',
      '-1':'Suspended by administrator',
      '0':'Uninitialised',
      '1':'Unconfirmed by email',
      '2':'Confirmed user',
      '3':'Forgot password - resetting'
    };
    this.MESSAGE_TYPE_TO_COLOUR = {
      'info':'blue',
      'success':'green',
      'error':'red',
      'warning':'orange'
    };

  }

  componentDidMount(){
    fetch("/api/users/index", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        users: json.users,
        init_loaded: true
      });
    });
  }

  on_type_email(e){
    if (!e.target.value.includes(' ')){
      this.setState({
        typed_email: e.target.value,
        typed_email_status: 0
      });
      this.on_blur_email();
    }
  }

  on_blur_email(){
    if (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.typed_email)){
      this.setState({
        typed_email_status: 2
      });
    } else {
      this.setState({
        typed_email_status: 1,
        typed_email_error: "Please type a valid email."
      });
    }
  }

  on_type_name(e){
    if (/^[A-Za-z][A-Za-z -]*$/.test(e.target.typed_name) && e.target.value.length < 100){
      this.setState({
        typed_name: e.target.value
      });
    }
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  create_new_user(){
    this.setState({
      loading: true
    });
    // /api/users/create
    fetch("/api/users/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        user: {
          email: this.state.typed_email,
          name: this.state.typed_name
        }
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message('Error in creating user.','error');
      this.setState({
        new_user_overlay: false,
        loading: false
      });
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.add_message('User created successfully.','success');
      } else {
        this.add_message('Error in creating user: '+json.error,'error');
      }
      this.setState({
        new_user_overlay: false,
        loading: false
      });
    });
  }

  render () {
    return (this.state.init_loaded ? 
      <div>
        {this.state.new_user_overlay ? 
          <Overlay on_cancel={() => this.setState({new_user_overlay: false})}>
            {this.state.loading ? 
            <div>
              Loading...
            </div> : 
            <div>
              <h3>
                Add another user
              </h3>
              {this.state.typed_email_status === 1 ? 
                <div>
                  {this.state.typed_email_error}
                </div> : null}
              <div>
                Email
              </div>
              <input onChange={this.on_type_email} value={this.state.typed_email} type="text" placeholder="Email" />
              <div>
                Name
              </div>
              <input onChange={this.on_type_name} value={this.state.typed_name} type="text" placeholder="Name" /><br />
              <button onClick={() => this.create_new_user()}>
                Confirm
              </button>
            </div>}
          </Overlay> : null}
        
        {this.state.messages.map(
          (message, index) => 
          <div key={index} style={{margin: '5px', padding: '10px', backgroundColor: this.MESSAGE_TYPE_TO_COLOUR[message.type], color: '#fff'}}>
            {message.id} - {message.content}
          </div>
        )}

        <div>
          <button onClick={() => this.add_message("Hello!", 'error')}>
            Add message
          </button>
        </div>

        <h3>
          Users ({this.state.users.length - 1})
        </h3>
        <div>
          <button onClick={() => this.setState({new_user_overlay: true})}>
            New user
          </button>
        </div>
        {this.state.users.map(
          (user, index) => user.email === this.props.email ? null : 
          <div key={index} style={{backgroundColor: "#efefef", padding: "10px", borderRadius: "6px", margin: "2px"}}>
            <p>
              <b>{user.name}</b>
            </p>
            <table><tbody>
              <tr>
                <td>
                  Email
                </td>
                <td>
                  {user.email}
                </td>
              </tr>
              <tr>
                <td>
                  Admin?
                </td>
                <td>
                  {user.admin ? "Yes" : "No"}
                </td>
              </tr>
              <tr>
                <td>
                  Status
                </td>
                <td>
                  {this.USER_STATUSES[Number(user.status)]}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}
      </div> : 
      <div>
        Loading users...
      </div>
    );
  }
}

export default UserIndex
