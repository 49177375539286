import React from "react"
import PropTypes from "prop-types"
import Checkbox from "./Checkbox.js"
import Overlay from "./Overlay.js"
import MyInput from "./MyInput.js"

class AccountSecurity extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      max_mins_inactive: 5,
      max_mins_inactive_changed: false,
      max_mins_inactive_loading: false,
      messages: [],
      mfa_preference: 0,
      mfa_preference_changed: false,
      mfa_preference_loading: false,
      show_secret: false,
      secret: null,
      typed_mfa: "",
      typed_mfa_status: 0,
      typed_mfa_message: ""
    };

    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.update_user = this.update_user.bind(this);
    this.retrieve_mfa_secret = this.retrieve_mfa_secret.bind(this);
    this.generate_mfa_secret = this.generate_mfa_secret.bind(this);
    this.on_type_mfa_code = this.on_type_mfa_code.bind(this);
    //this.set_mfa_preference = this.set_mfa_preference.bind(this);
    this.click_to_copy = this.click_to_copy.bind(this);

    this.MINS_INACTIVE_OPTIONS = [5,30,60,120];
    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    this.MFA_LENGTH_LIMIT = 6;

  }

  /*
    On this page: session timeout, 2FA

    TO DO on 2FA:
     - 2 sections to 2FA: existing setup (if any), new setup
     - New setup gives guidelines (download GA, ensure nobody watching etc.), has button to generate
       - Calls API to user endpoint to generate and save TOTP to user, returns uri as both text and SVG if possible
       - Is displayed by front-end, can be sync'd with app
     - Existing setup can call to user endpoint to get current TOTP value (with credentials)
  */

  componentDidMount(){
    if (![null,undefined].includes(this.props.data)){
      this.setState({
        data: this.props.data,
        loaded: true,
        max_mins_inactive: this.props.data.max_mins_inactive,
        mfa_preference: this.props.data.mfa_preference
      });
    } else {
      this.setState({
        loaded: false
      });
    }
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  update_user(params){
    this.setState({
      max_mins_inactive_loading: params.max_mins_inactive !== null,
      mfa_preference_loading: params.mfa_preference !== null
    });
    fetch("/api/user", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({user: params})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Password was incorrect.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.props.on_successful_update('general');
        this.setState({
          max_mins_inactive_loading: false,
          max_mins_inactive_changed: false,
          mfa_preference_loading: false
        });
        this.add_message("Successfully updated.",'success');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        this.add_message("Could not successfully update.",'danger');
      }
    });
  }

  retrieve_mfa_secret(){
    fetch("/api/user/mfa", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to obtain data.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        show_secret: true,
        secret: json.secret
      });
    });
  }

  on_type_mfa_code(e){
    const typed = e.target.value;
    if ("1234567890".includes(typed.slice(-1)) && typed.length <= this.MFA_LENGTH_LIMIT){
      this.setState({
        typed_mfa: typed,
        typed_mfa_status: 1,
        typed_mfa_message: ""
      });
      if (typed.length === this.MFA_LENGTH_LIMIT){
        this.test_mfa(typed);
      }
    }
  }

  test_mfa(code){
    // /api/user/testmfa
    fetch("/api/user/testmfa?code="+String(code), {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to obtain data.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      console.log("Test MFA JSON:");
      console.log(json);
      this.setState({
        typed_mfa_status: json.success ? 2 : 3,
        typed_mfa_message: json.success ? "MFA code was correct." : "MFA code was incorrect."
      });
    });
  }

  generate_mfa_secret(){
    // /api/user/generatemfa
    fetch("/api/user/generatemfa", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Could not successfully generate MFA secret.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      /*this.setState({
        mfa_preference: 1
      }).then(() => {
        document.getElementById('mfa-secret-target').innerHTML = json.svg;
      });*/
      //document.getElementById('mfa-secret-target').innerHTML = json.svg;
      if (json.success){
        this.setState({
          show_secret: true,
          secret: json.secret
        });
        this.props.on_successful_update('general');
        this.add_message("Successfully generated.",'success');
      } else {
        this.add_message("Failed to generate.",'danger');
      }
    });
  }

  click_to_copy(e, text){
    navigator.clipboard.writeText(text);
    e.target.innerHTML = "COPIED!"
    setTimeout(() => {
      e.target.innerHTML = "COPY";
    }, 1000);
  }

  /*set_mfa_preference(value){
    fetch("/api/user/setmfapreference", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({mfa_preference: value})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Could not successfully set MFA preference.",'danger');
      throw new Error('Request fail');
    }).then(json => {
    });
  }*/

  componentDidUpdate(prev_props){
    if (prev_props.data !== this.props.data){
      this.componentDidMount();
    }
  }

  render () {
    return (this.state.loaded ? 
      <div style={{marginLeft: '25px'}}>

        {this.state.show_secret ? 
          <Overlay on_cancel={() => this.setState({show_secret: false, secret: null})} title="MFA Secret" width="600px">
            <div>
              <div style={{color: 'grey', fontWeight: 'bold', fontSize: '16px', cursor: 'pointer', textAlign: 'center'}}>
                {this.state.secret.provisioning_uri}
              </div>
              <div style={{textAlign: 'center'}}>
                <span onClick={(e) => this.click_to_copy(e, this.state.secret.provisioning_uri)} className="copy-span">COPY</span>
              </div>
              <div dangerouslySetInnerHTML={{__html: this.state.secret.svg}} style={{display: "table", margin: "auto"}} id="mfa-secret-target"></div>
            </div>
          </Overlay> : null}

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <h3>
          Maximum minutes of inactivity
        </h3>
        <p className="subtle-p">
          Minutes of inactivity before a session is terminated and you must log in again.
        </p>
        <div style={{display: 'table'}}>
          {this.MINS_INACTIVE_OPTIONS.map(
            (mins, index) =>
            <div className={this.state.max_mins_inactive === mins ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({max_mins_inactive: mins, max_mins_inactive_changed: mins !== this.state.data.max_mins_inactive})} key={index}>
              <table><tbody>
                <tr>
                  <td>
                    <div style={{margin: 'auto'}}>
                      <div />
                    </div>
                  </td>
                  <td>
                    <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                      {mins < 60 ? mins + " minutes" : mins / 60 + " hour" + (mins < 120 ? "" : "s")}
                    </div>
                    {/*<table style={{color: 'grey', fontSize: '16px', fontFamily: 'Roboto Mono'}}><tbody>
                      <tr>
                        <td style={{}}>
                          BSB:{account.bsb}
                        </td>
                        <td style={{}}>
                          Acct:{account.account_number}
                        </td>
                      </tr>
                    </tbody></table>*/}
                  </td>
                </tr>
              </tbody></table>
            </div>
          )}
          {this.state.max_mins_inactive_changed ? 
            <button className="std-button" onClick={() => this.update_user({max_mins_inactive: this.state.max_mins_inactive})}>
              {this.state.max_mins_inactive_loading ? "Saving..." : "Save"}
            </button> : null}
        </div><br />

        <h3>
          Two-Factor Authentication
        </h3>
        <table className="toggle-table-general"><tbody>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({mfa_preference: v ? 1 : 0, mfa_preference_changed: true})} init_value={this.state.mfa_preference} />
            </td>
            <td>
              <h3>
                Two-Factor Authentication <span style={{color: 'grey'}}>(recommended)</span>
              </h3>
              <p>
                Establish a TOTP time-based authentication in addition to a password to login. Mobile authentication applications such as Google Authenticator will be able to calculate the password at login once the secret is set up and received.
              </p>
            </td>
          </tr>
        </tbody></table>
        {this.state.mfa_preference > 0 ?
          <div>
            <h4>
              Existing secret
            </h4>
            <h4>
              <span style={{color: 'grey'}}>PaySolve {this.state.data.code}</span>
            </h4>
            {this.state.data.mfa_ciphertext_hash_first_8 === null ? 
              <div>
                <p className="general-p">
                  There is no existing secret. You can generate one below.
                </p>
              </div> : 
              <div>
                <table className="account-table"><tbody>
                  <tr>
                    <td style={{color: 'black', fontSize: '14px'}}>
                      <b>Secret ciphertext hash</b><br />
                      <span style={{color: 'grey'}}>First 32 bits</span>
                    </td>
                    <td style={{verticalAlign: 'top'}}>
                      <div style={{fontFamily: "Roboto Mono", fontSize: '16px', textAlign: 'center', marginTop: '12px'}}>{this.state.data.mfa_ciphertext_hash_first_8}...</div>
                    </td>
                  </tr>
                </tbody></table>
                <div style={{textAlign: 'center', marginTop: '15px'}}>
                  <button className="std-button" onClick={this.retrieve_mfa_secret}>
                    Retrieve Secret
                  </button>
                </div>
                <div style={{textAlign: 'center'}}>
                  <p className="general-p">
                    You can test the current code here:
                  </p>
                  <div style={{display: 'table', margin: 'auto'}}>
                    <MyInput onChange={this.on_type_mfa_code} value={this.state.typed_mfa} style={{textAlign: 'center', letterSpacing: '4px', fontWeight: 'bold'}} status={this.state.typed_mfa_status} note={this.state.typed_mfa_message} id="mfa-input" />
                  </div>
                </div>
              </div>}
            <h4>
              Set new secret
            </h4>
            <p className="general-p">
              Steps to generate:
            </p>
            <ol className="general-p">
              <li style={{margin: '5px'}}>Download a two-factor authentication app on a device that supports the TOTP algorithm specified in <a href="https://datatracker.ietf.org/doc/html/rfc6238" target="_blank">RFC 6238</a>. PaySolve recommends Google Authenticator.</li>
              <li style={{margin: '5px'}}><b>Ensure that you cannot be seen, either by others or by a camera.</b> Click 'Generate Secret'. If successful, a QR code and character string will appear on your screen. This is your secret.</li>
              <div style={{textAlign: 'center'}}>
                <button onClick={this.generate_mfa_secret} className="std-button">
                  Generate Secret
                </button>
              </div>
              <li style={{margin: '5px'}}>Open the app downloaded in (1.). Add a new login. You can add it by using the QR code. If you cannot use the camera, you may need to manually enter the character string.</li>
              <li style={{margin: '5px'}}>Once it has been successfully added, it should appear as:<div><b>PaySolve {this.state.data.code}</b></div></li>
              <li style={{margin: '5px'}}>Close the window with the QR code. You can reopen it again under 'Existing Secret'.</li>
            </ol>
          </div> : null}
        {this.state.data.mfa_preference !== this.state.mfa_preference ? 
          <button onClick={() => this.update_user({mfa_preference: this.state.mfa_preference})} className="std-button">
            {this.state.mfa_preference_loading ? "Saving... " : "Save"}
          </button> : null}
      </div> : 
      <div>
        Loading...
      </div>
    );
  }
}

export default AccountSecurity
