import React from "react"
import PropTypes from "prop-types"
class TextBox extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    };

    this.click_to_copy = this.click_to_copy.bind(this);

  }

  click_to_copy(e, text){
    navigator.clipboard.writeText(text);
    console.log(e);
    /*e.target.innerHTML = "COPIED!"
    setTimeout(() => {
      e.target.innerHTML = "COPY";
    }, 1000);*/
  }

  render () {
    return (
      <div className="text-box">
        <table><tbody>
          <tr>
            <td>
              {this.props.title}
            </td>
            <td>
              <i onClick={(e) => this.click_to_copy(e, this.props.content)} className="fa-regular fa-copy"></i>
            </td>
          </tr>
        </tbody></table>
        <div style={{minHeight: [null,undefined].includes(this.props.style) || [null,undefined].includes(this.props.style.minHeight) ? '200px' : this.props.style.minHeight}}>
          {this.props.content}
        </div>
      </div>
    );
  }
}

export default TextBox
