import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
import Checkbox from "./Checkbox.js"
import Overlay from "./Overlay.js"
class NewSeries extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      loading: false,
      title: "New Series",
      title_status: 0,
      title_error: "",
      description: "",
      recipient: "",
      billing_name: "",
      billing_name_status: 0,
      billing_name_error: "",
      include_email: false,
      billing_email: "",
      billing_email_status: 0,
      billing_email_error: "",
      privacy_status: 1,
      passkey: this.props.original_random.slice(0,6),
      passkey_length: 6,
      messages: [],
      custom_identifier: "",
      custom_identifier_status: 0,
      show_confirmation: false
		};

    this.random_passkey = this.random_passkey.bind(this);
    this.on_type_passkey = this.on_type_passkey.bind(this);
    this.on_focus_passkey = this.on_focus_passkey.bind(this);
    this.on_blur_passkey = this.on_blur_passkey.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.on_type_title = this.on_type_title.bind(this);
    this.on_blur_title = this.on_blur_title.bind(this);
    this.on_type_description = this.on_type_description.bind(this);
    this.on_type_billing_name = this.on_type_billing_name.bind(this);
    this.on_blur_billing_name = this.on_blur_billing_name.bind(this);
    this.on_type_billing_email = this.on_type_billing_email.bind(this);
    this.on_blur_billing_email = this.on_blur_billing_email.bind(this);
    this.on_focus_billing_email = this.on_focus_billing_email.bind(this);
    this.on_type_custom_identifier = this.on_type_custom_identifier.bind(this);
    this.list_errors = this.list_errors.bind(this);
    this.on_click_create = this.on_click_create.bind(this);
    this.create_series = this.create_series.bind(this);

    this.MINIMUM_TITLE_LENGTH = 5;
    this.MAXIMUM_TITLE_LENGTH = 30;
    this.MINIMUM_PASSKEY_LENGTH = 4;
    this.MAXIMUM_PASSKEY_LENGTH = 16;
    this.MAXIMUM_DESCRIPTION_LENGTH = 100;
    this.MAXIMUM_BILLING_NAME_LENGTH = 32;
    this.MESSAGE_TYPE_TO_COLOUR = {'danger':'red','success':'green','info':'blue'};
    this.MESSAGE_TYPE_TO_ICON = {'danger':'circle-exclamation','warning':'circle-exclamation','info':'circle-info','success':'check'}

  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  random_passkey(){
    // randpk
    fetch("/randpk?n="+String(this.state.passkey_length), {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        passkey: json.random
        //passkey_status: 2,
        //passkey_error: null
      });
    });
  }

  on_type_passkey(e){
    const typed = e.target.value;
    if (/^[a-zA-Z0-9_\-]+$/.test(typed) && typed.length < this.MAXIMUM_PASSKEY_LENGTH){
      this.setState({
        passkey: typed
      });
    }
  }

  on_focus_passkey(){
    this.setState({
      passkey_status: 1
    });
    setTimeout(() => {
      document.getElementById('passkey-input').select();
    }, 50);
  }

  on_blur_passkey(){
    this.setState({
      passkey_status: this.state.passkey.length < this.MINIMUM_PASSKEY_LENGTH ? 3 : 2,
      passkey_error: this.state.passkey.length < this.MINIMUM_PASSKEY_LENGTH ? "Passkey must be between "+String(this.MINIMUM_PASSKEY_LENGTH)+" and "+String(this.MAXIMUM_PASSKEY_LENGTH)+" characters." : null
    });
  }

  on_type_title(e){
    const typed = e.target.value;
    if (typed.length < this.MAXIMUM_TITLE_LENGTH){
      this.setState({
        title: typed
      });
    }
  }

  on_blur_title(){
    const typed = this.state.title.trim();
    if (typed.length < this.MINIMUM_TITLE_LENGTH || typed.length > this.MAXIMUM_TITLE_LENGTH){
      this.setState({
        title: typed,
        title_status: 3,
        title_error: "Title must be between "+String(this.MINIMUM_TITLE_LENGTH)+" and "+String(this.MAXIMUM_TITLE_LENGTH)+" characters."
      });
    } else {
      this.setState({
        title: typed,
        title_status: 2,
        title_error: null
      });
    }
  }

  on_type_description(e){
    const typed = e.target.value;
    if (typed.length <= this.MAXIMUM_DESCRIPTION_LENGTH){
      this.setState({
        description: typed
      });
    }
  }
  

  on_type_billing_name(e){
    const typed = e.target.value;
    if (typed.length < this.MAXIMUM_BILLING_NAME_LENGTH && /^[a-zA-Z0-9\s\-']*$|^$/.test(typed)){
      this.setState({
        billing_name: typed
      });
    }
  }

  on_blur_billing_name(){
    const typed = this.state.billing_name.trim();
    if (typed.length < this.MINIMUM_BILLING_NAME_LENGTH || typed.length > this.MAXIMUM_BILLING_NAME_LENGTH){
      this.setState({
        billing_name_status: 3,
        billing_name_error: "Name must be A-Z, spaces, and "+String(this.MINIMUM_BILLING_NAME_LENGTH)+"-"+String(this.MAXIMUM_BILLING_NAME_LENGTH)+" characters.",
        billing_name: typed
      });
    } else {
      this.setState({
        billing_name_status: 2,
        billing_name_error: "",
        billing_name: typed
      });
    }
  }

  on_type_billing_email(e){
    const typed = e.target.value;
    if (/^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~@.]*$/.test(typed)){
      this.setState({
        billing_email: typed
      });
    }
  }

  on_blur_billing_email(){
    if (!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.billing_email)){
      this.setState({
        billing_email_status: 3,
        billing_email_error: "You must enter a valid email."
      });
    } else {
      this.setState({
        billing_email_status: 2,
        billing_email_error: ""
      });
    }
  }

  on_focus_billing_email(){
    this.setState({
      billing_email_status: 1,
      billing_email_error: null
    });
  }

  on_type_custom_identifier(e){
    const typed = e.target.value;
    if (!typed.includes(" ") || true){
      this.setState({
        custom_identifier: typed
      });
    }
  }

  list_errors(){
    let errors = [];
    if (![0,2].includes(this.state.title_status)){
      errors.push("Title is not valid");
    }
    if (![0,2].includes(this.state.billing_name_status)){
      errors.push("Billing name is not valid");
    }
    if (this.state.billing_email_status != 2 && this.state.include_email){
      errors.push("Billing email is not valid");
    }
    if ((this.state.passkey.length < this.MINIMUM_PASSKEY_LENGTH || this.state.passkey_length > this.MAXIMUM_PASSKEY_LENGTH) && this.state.privacy_status === 2){
      errors.push("Passkey is not valid");
    }
    return errors;
  }

  on_click_create(){
    const errors = this.list_errors();
    if (errors.length > 0){
      this.add_message("The following errors were found: "+errors.join(', '),'danger');
      window.scrollTo(0, 0);
      return null;
    }
    this.setState({
      show_confirmation: true
    });
  }

  create_series(){
    // params.require(:series).permit(:title, :description, :recipient, :billing_name, :billing_email, :passkey_ciphertext, :privacy_status)
    const body = {
      series: {
        title: this.state.title,
        description: this.state.description,
        recipient: this.state.custom_identifier,
        billing_name: this.state.billing_name,
        billing_email: this.state.billing_email,
        passkey_ciphertext: this.state.passkey,
        privacy_status: this.state.privacy_status
      },
      tags: [this.state.custom_identifier]
    };
    fetch("/series/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Encountered error in creating Series object.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      if (json.success){
        this.add_message("Successfully created Series object.",'success');
      } else {
        this.add_message("Error in creating Series object: "+json.error,'danger');
      }
    });
  }

  render () {
    return (
      <div className="form-section">

        {this.state.show_confirmation ? 
          <Overlay on_cancel={() => this.setState({show_confirmation: false})}>
            <div>
              <h3 style={{color: 'grey'}}>
                Confirm <span style={{color: 'black'}}>{this.state.title}</span>
              </h3>
              <table id="confirmation-table"><tbody>
                <tr>
                  <td>
                    Description
                  </td>
                  <td>
                    {this.state.description.slice(0,100) + (this.state.description.length > 100 ? "..." : "")}
                  </td>
                </tr>
                <tr>
                  <td>
                    Billing name
                  </td>
                  <td>
                    {this.state.billing_name}
                  </td>
                </tr>
                <tr>
                  <td>
                    Billing email
                  </td>
                  <td>
                    {this.state.billing_email}
                  </td>
                </tr>
                <tr>
                  <td>
                    Passkey
                  </td>
                  <td>
                    {this.state.privacy_status === 2 ? this.state.passkey : "No passkey"}
                  </td>
                </tr>
              </tbody></table>
              <button className="std-button" onClick={() => this.create_series()}>
                Confirm
              </button>
              <button className="std-button" onClick={() => this.setState({show_confirmation: false})}>
                Cancel
              </button>
            </div>
          </Overlay> : null}

        {this.state.messages.map(
          (message, index) => 
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <h2>
          {this.state.title}
        </h2>

        <div className="form-intra-section">
          <div className="myinput-wrapper-centre">
            <MyInput onChange={this.on_type_title} value={this.state.title} onBlur={this.on_blur_title} status={this.state.title_status} note={this.state.title_status === 3 ? this.state.title_error : null} label="Title of the Series" />
          </div><br />
          <div className="myinput-wrapper-centre">
            <div style={{color: 'grey', fontSize: '13px', marginBottom: '4px', textAlign: 'left'}}>
              Description
            </div>
            <textarea onChange={this.on_type_description} value={this.state.description} className="standard-textarea" />
            <div style={{color: 'grey', fontSize: '13px', marginBottom: '4px', textAlign: 'left'}}>
              {this.MAXIMUM_DESCRIPTION_LENGTH - this.state.description.length}
            </div>
          </div>
        </div>

        <h3>
          Payor
        </h3>
        <table className="toggle-table"><tbody>
          <tr>
            <td></td>
            <td>
              <h4>
                Payor name
              </h4>
              <p>
                This could be an individual or an entity.
              </p>
            </td>
            <td>
              <MyInput onChange={this.on_type_billing_name} value={this.state.billing_name} status={this.state.billing_name_status} onBlur={this.on_blur_billing_name} label="Payor name" note={this.state.billing_name_status === 3 ? this.state.billing_name_error : null} />
            </td>
          </tr>
        </tbody></table>
        <table className="toggle-table"><tbody>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_email: v})} init_value={this.state.include_email} />
            </td>
            <td>
              <h4>
                Include email
              </h4>
              <p>
                We will send a copy of this invoice to this address.
              </p>
            </td>
            {this.state.include_email ? 
            <td>
              <MyInput onChange={this.on_type_billing_email} value={this.state.billing_email} status={this.state.billing_email_status} onFocus={this.on_focus_billing_email} onBlur={this.on_blur_billing_email} label="Payor email" note={this.state.billing_email_status === 3 ? this.state.billing_email_error : null} />
            </td> : null}
          </tr>
        </tbody></table>

        <h3>
          Custom identifier
        </h3>
        <div className="myinput-wrapper-centre">
          <MyInput onChange={this.on_type_custom_identifier} value={this.state.custom_identifier} status={this.state.custom_identifier_status} onFocus={() => this.setState({custom_identifier_status: 1})} />
        </div>

        <h3>
          Privacy
        </h3>
        <table className="toggle-table-general"><tbody>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({has_passkey: v, privacy_status: v ? 2 : 1})} init_value={this.state.has_passkey} />
            </td>
            <td>
              <h3>
                Has passkey
              </h3>
              <p>
                A passkey in addition to a URL is required to view the invoice.
              </p>
            </td>
          </tr>
          {this.state.has_passkey ? 
          <tr>
            <td></td>
            <td>
              {this.state.has_passkey ? 
                <div>
                  <MyInput onChange={this.on_type_passkey} value={this.state.passkey} status={this.state.passkey_status} id="passkey-input" onFocus={this.on_focus_passkey} onBlur={this.on_blur_passkey} label="Passkey" note={this.state.passkey_status === 3 ? this.state.passkey_error : null} />
                  <table><tbody>
                    <tr>
                      <td>
                        <button className="std-button" onClick={this.random_passkey} style={{marginTop: '10px'}}>
                          Random
                        </button>
                      </td>
                      <td style={{paddingTop: '15px'}}>
                        <div>
                          <input type="range" min="4" max="16" onChange={(e) => this.setState({passkey_length: Number(e.target.value)})} value={String(this.state.passkey_length)} style={{width: '100%', margin: 'auto'}} />
                        </div>
                        <div style={{textAlign: 'center', fontSize: '13px', color: 'grey'}}>
                          {this.state.passkey_length} characters
                        </div>
                      </td>
                    </tr>
                  </tbody></table>
                </div> : null}
            </td>
          </tr> : null}
        </tbody></table>

        <button onClick={this.on_click_create} className="std-button">
          {this.state.loading ? "Creating..." : "Create"}
        </button>

      </div>
    );
  }
}

export default NewSeries
