import React from "react"
import PropTypes from "prop-types"
import Toggle from "./Toggle.js"
import Overlay from "./Overlay.js"
import MyInput from "./MyInput.js"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement, // doughtnut
} from 'chart.js';
import { Line } from 'react-chartjs-2';

class AdminCustomer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      messages: [],
      customer: null,
      selected_transfer_index: -1,
      init_loaded: false,
      payment_history: {
        show: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: false,
              text: 'Payments',
            }
          },
          scales: {
            yAxis1: {
              title: {
                display: true,
                text: '$ payment'
              },
              suggestedMin: 0,
              suggestedMax: 10
            }
          }
        },
        data: {
          labels: [],
          datasets: [
            {
              label: 'Payment',
              data: [],
              borderColor: '#32bfb8',
              backgroundColor: '#efefef',
              yAxisID: 'yAxis1'
            }
          ]
        }
      },
      show_complete_screen: false,
      show_status_screen: false,
      selected_status: 0,
      show_value_screen: false,
      value_screen_field: '',
      value_screen_delta_type: 1, // 0 = change value, 1 = add value
      typed_amount: "$0.00",
      displayed_amount: "$0.00",
      amount: 0,
      typed_amount_status: 0,
      typed_amount_error: null,
      loading: false,
      visibility_changed: false,
      show_delete: false,
      typed_delete_code: "",
      typed_delete_code_status: 0,
      typed_delete_code_message: "",
      typed_tag: ""
    };

    this.retrieve_details = this.retrieve_details.bind(this);
    this.time_values_to_chart_data = this.time_values_to_chart_data.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.unix_to_formatted_date = this.unix_to_formatted_date.bind(this);
    this.unix_to_formatted_time = this.unix_to_formatted_time.bind(this);
    this.on_type_tag = this.on_type_tag.bind(this);
    this.on_key_down_tag = this.on_key_down_tag.bind(this);
    this.update_tags = this.update_tags.bind(this);
    this.remove_tag = this.remove_tag.bind(this);
    this.admin_update = this.admin_update.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.click_to_copy = this.click_to_copy.bind(this);
    this.on_type_amount = this.on_type_amount.bind(this);
    this.on_focus_amount = this.on_focus_amount.bind(this);
    this.on_blur_amount = this.on_blur_amount.bind(this);
    this.is_valid_amount_input = this.is_valid_amount_input.bind(this);
    this.value_admin_update = this.value_admin_update.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    this.CUSTOMER_STATUS_TO_WORD = {'0':'INITIATE','1':'ACTIVE','-1':'SUSPEND','-2':'SUSPEND'};
    this.CUSTOMER_STATUS_TO_TITLE = {'0':'Customer Initiated','1':'Customer Collecting Payments','-1':'Customer Suspended by User','-2':'Customer Suspended by Force'};
    this.CUSTOMER_STATUS_TO_DESCRIPTION = {'0':'Customer has been initiated but has not been opened yet to contributions.',
      '1':'Customer is open to collecting payments for anyone with the link and credentials (if applicable).',
      '-1':'Customer is suspended by the User (merchant).'};
    this.CUSTOMER_STATUS_TO_COLOUR = {'0':'#040dba','1':'#040dba','-1':'#e6a330','-2':'#e6a330'};
    this.CUSTOMER_STATUS_TO_ADMIN_ACTION = {'1':'OPEN','-1':'SUSPEND'};
    this.CUSTOMER_FIELD_TO_TITLE = {'total_added_externally':'Total added externally',
      'total_debited':'Total debited',
      'total_received':'Total received',
      'balance':'Balance'};
    this.MINIMUM_AMOUNT = 0;
    this.MAXIMUM_AMOUNT = 1000000000;
    
  }

  componentDidMount(){
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend,
      ArcElement
    );
    this.retrieve_details();
  }

  retrieve_details(){
    // /api/fund/:code
    fetch("/api/customer/"+this.props.customer_code+"/admin", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to obtain data.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      console.log(json.customer);
      this.setState({
        customer: json.customer,
        selected_status: json.customer === undefined ? this.state.selected_status : json.customer.status,
        init_loaded: true
      });
      let pc_data = this.time_values_to_chart_data(json.customer.transfers.map((t,i) => ({time:t.transferred_at,value:t.amount / 100})), json.customer.start_date_int, Math.round(Date.now() / 1000));
      let pc_state = this.state.payment_history;
      pc_state.show = true;
      pc_state.data.labels = pc_data.labels;
      pc_state.data.datasets[0].data = pc_data.data;
      this.setState({
        payment_history: pc_state,
        init_loaded: true
      });
    });
  }

  time_values_to_chart_data(values, earliest_unix, latest_unix){
    //let prelabels = transfers.map((t,i) => t.transferred_at / 86400000);
    /*console.log("Values");
    console.log(values);
    console.log("Earliest UNIX");
    console.log(earliest_unix);
    console.log("Latest UNIX");
    console.log(latest_unix);*/
    let prelabels = Array(Math.round((latest_unix - earliest_unix) / 86400)).fill(0).map((x,i) => i);
    let unixlabels = prelabels.map((l,i) => l*86400 + earliest_unix);
    let labels = unixlabels.map((l,i) => (new Date(l * 1000)).toDateString().slice(4,10));//prelabels.map((l,i) => (new Date((l*86400 + earliest_unix) * 1000)).toDateString().slice(4,10));
    /*console.log(earliest_unix);
    console.log(prelabels);
    console.log(unixlabels);
    console.log(labels);*/
    let time2val = {};
    let i;
    for (i = 0 ; i < values.length ; i++){
      if (time2val[String(values[i].time - values[i].time % 86400)] === undefined){
        time2val[String(values[i].time - values[i].time % 86400)] = values[i].value;
      } else {
        time2val[String(values[i].time - values[i].time % 86400)] += values[i].value;
      }
    }
    let data = unixlabels.map((t,i) => time2val[String(t)] === undefined ? 0 : time2val[String(t)]);
    let data_cumul = Array(data.length).fill(0);
    for (i = 0 ; i < data_cumul.length ; i++){
      data_cumul[i] = (i === 0 ? 0 : data_cumul[i-1]) + data[i];
    }
    console.log('data');
    console.log(data_cumul);
    return {
      labels: labels,
      data: data,
      data_cumul: data_cumul
    };
  }


  on_type_amount(e){
    const typed = e.target.value;
    /*if (typed === '.' || /^\d{0,3}(?:\.\d{1,2})?$|^\d{1,3}(?:\.\d{0,1})?$|^\d{1}(?:\.\d{0,2})?$|^\.\d{1,3}$/.test(typed) || typed.length === 0){
      this.setState({
        typed_amount: typed
      });
    }*/
    if (typed === '.' || this.is_valid_amount_input(typed) || typed.length === 0){
      this.setState({
        typed_amount: typed
      });
    }
  }

  on_focus_amount(){
    this.setState({
      typed_amount: this.state.typed_amount.replaceAll('$','').replaceAll(',',''),
      typed_amount_status: 1
    }, () => {
      document.getElementById('amount-input').select();
    });
    setTimeout(() => {
      document.getElementById('amount-input').select();
    }, 50);
  }

  on_blur_amount(){
    let amount = this.state.typed_amount.split('.').map((x,i) => i === 0 ? Number(x) * 100 : Number(x[0])*10+(x[1]===undefined ? 0 : Number(x[1]))).reduce((p,a) => p+a, 0);
    if (this.state.typed_amount === '.'){
      amount = 0;
    }
    const str = String(amount / 100);
    console.log("Amount: "+amount);
    if ((this.state.value_screen_delta_type === 0 && amount < 0) || (this.state.value_screen_delta_type === 2 && this.state.customer[this.state.value_screen_field] - amount < 0)){
      this.setState({
        typed_amount: '$' + this.amount_to_formatted_string(amount),
        displayed_amount: '$' + this.amount_to_formatted_string(amount),
        amount: amount,
        typed_amount_status: 3,
        typed_amount_error: "The resulting amount must be non-negative."
      });
    } else {
      this.setState({
        typed_amount: '$' + this.amount_to_formatted_string(amount),
        displayed_amount: '$' + this.amount_to_formatted_string(amount),
        amount: amount,
        typed_amount_status: amount < this.MINIMUM_AMOUNT || amount > this.MAXIMUM_AMOUNT ? 3 : 2,
        typed_amount_error: amount < this.MINIMUM_AMOUNT || amount > this.MAXIMUM_AMOUNT ? "The typed amount must be between $"+(this.MINIMUM_AMOUNT/100)+" and $"+(this.MAXIMUM_AMOUNT/100000000)+" million." : null
      });
    }
  }

  is_valid_amount_input(input) {
    console.log("testing "+input);
    if (input === "." || input === "") {
        return true;
    }
    const parts = input.split(".");
    if (parts.length === 1){
      return !isNaN(parts[0]);
    } else if (parts.length > 2){
      return false;
    }
    return !isNaN(parts[0]) && !isNaN(parts[1]) && parts[1].length <= 2;
  }


  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  unix_to_formatted_date(unix){
    return (new Date(unix * 1000)).toDateString();
  }

  unix_to_formatted_time(unix){
    return (new Date(unix * 1000)).toTimeString().slice(0,17);
  }

  on_type_tag(e){
    this.setState({
      typed_tag: e.target.value
    });
  }

  on_key_down_tag(e){
    if (e.key === 'Enter' && !this.state.customer.tags.includes(this.state.typed_tag)){
      this.update_tags([this.state.typed_tag],[]);
    }
  }

  update_tags(add_terms, remove_terms){
    fetch("/api/customer/"+this.state.customer.code+"/tag", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        add: add_terms,
        remove: remove_terms
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      let customer = this.state.customer;
      customer.tags.filter((e) => !remove_terms.includes(e));
      customer.tags = this.state.customer.tags.concat(add_terms);
      this.setState({
        customer: customer,
        typed_tag: ""
      });
    });
  }

  remove_tag(index){
    this.update_tags([], [this.state.customer.tags[index]]);
    let customer = this.state.customer;
    customer.tags.splice(index, 1);
  }

  value_admin_update(field, value){
    this.setState({
      loading: true
    });
    fetch("/api/customer/"+this.props.customer_code+"/admin",{
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept':'application/json',
        'Content-Type':'application/json',
        'x-csrf-token':this.props.auth_token
      },
      body: JSON.stringify({
        'action_name':field,
        'value':[this.state.amount,this.state.customer[this.state.value_screen_field]+this.state.amount,this.state.customer[this.state.value_screen_field]-this.state.amount][this.state.value_screen_delta_type]
      })
    }).then((response) => {
      this.setState({
        loading: false
      });
      if (response.ok){
        return response.json();
      }
      this.add_message("Could not update "+this.CUSTOMER_FIELD_TO_TITLE[this.state.value_screen_field],'danger');
      this.setState({
        show_value_screen: false
      });
      throw new Error('Request fail');
    }).then(json => {
      console.log("JSON");
      if (json.success){
        this.add_message("Successfully updated "+this.CUSTOMER_FIELD_TO_TITLE[this.state.value_screen_field],'success');
        this.setState({
          show_value_screen: false,
          customer: json.customer,
          typed_amount: "$0.00",
          displayed_amount: "$0.00",
          amount: 0,
          typed_amount_status: 0,
          typed_amount_error: null
        });
      }
    })
  }

  admin_update(action){
    this.setState({
      loading: true
    });
    // /api/invoice/:code/admin
    fetch("/api/customer/"+this.props.customer_code+"/admin", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        'action_name': action
      })
    }).then( (response) => {
      this.setState({
        loading: false
      });
      if (response.ok){
        return response.json();
      }
      this.add_message("An error was encountered", 'danger');
      this.setState({
        show_status_screen: false
      });
      throw new Error('Request fail');
    }).then(json => {
      console.log("JSON");
      console.log(json);
      console.log("Action");
      console.log(action);
      if (json.success){
        if (action === "DELETE"){
          this.add_message("Successfully deleted.", 'success');
          // do stuff here
        } else if (action === "SUSPEND"){
          this.add_message("Successfully suspended Customer.",'success');
        } else if (action === "OPEN"){
          this.add_message("Successfully opened Customer.",'success');
        }
      } else {
        this.add_message("An error was encountered", 'danger');
      }
      this.setState({
        show_status_screen: false,
        customer: json.customer
      });
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  click_to_copy(e, text){
    navigator.clipboard.writeText(text);
    console.log(e);
    e.target.innerHTML = "COPIED!"
    setTimeout(() => {
      e.target.innerHTML = "COPY";
    }, 1000);
  }


  render () {
    return (this.state.init_loaded ? 
      <div className="form-section">

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        {this.state.show_value_screen ? 
          <Overlay on_cancel={() => this.setState({show_value_screen: false})} title={"Change "+this.CUSTOMER_FIELD_TO_TITLE[this.state.value_screen_field]} width="460px">
            <div>
              {/*[0,1].map(
                (delta_type, index) =>
                <div className={this.state.value_screen_delta_type === delta_type ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({value_screen_delta_type: delta_type})} key={index}>
                  <table><tbody>
                    <tr>
                      <td>
                        <div>
                          <div />
                        </div>
                      </td>
                      <td>
                        <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                          {delta_type === 0 ? "Set the value" : "Add value"}
                        </div>
                      </td>
                    </tr>
                  </tbody></table>
                </div>
              )*/}
              <div>
                <table className="payment-header"><tbody>
                  <tr>
                    <td onClick={() => this.setState(this.state.value_screen_delta_type === 0 ? {} : {value_screen_delta_type: 0, typed_amount: "$0.00", displayed_amount: "$0.00", amount: 0, typed_amount_status: 0, typed_amount_error: null})} style={{color: this.state.value_screen_delta_type === 0 ? '#040dba' : 'grey', textAlign: 'center', width: '33.33%'}}>
                      Set value
                    </td>
                    <td onClick={() => this.setState(this.state.value_screen_delta_type === 1 ? {} : {value_screen_delta_type: 1, typed_amount: "$0.00", displayed_amount: "$0.00", amount: 0, typed_amount_status: 0, typed_amount_error: null})} style={{color: this.state.value_screen_delta_type === 1 ? '#040dba' : 'grey', textAlign: 'center', width: '33.33%'}}>
                      Add
                    </td>
                    <td onClick={() => this.setState(this.state.value_screen_delta_type === 2 ? {} : {value_screen_delta_type: 2, typed_amount: "$0.00", displayed_amount: "$0.00", amount: 0, typed_amount_status: 0, typed_amount_error: null})} style={{color: this.state.value_screen_delta_type === 2 ? '#040dba' : 'grey', textAlign: 'center', width: '33.33%'}}>
                      Subtract
                    </td>
                  </tr>
                </tbody></table>
                <div className="payment-slider" style={{borderTop: 'solid', borderColor: '#040dba', width: '151px', borderWidth: '3px', marginLeft: (this.state.value_screen_delta_type * 151.33) + 'px'}} />
              </div>

              {this.state.value_screen_delta_type === 0 ? 
                <div>
                  <div style={{display: 'table', margin: 'auto', marginTop: '20px'}}>
                    <MyInput onChange={this.on_type_amount} value={this.state.typed_amount} onFocus={this.on_focus_amount} onBlur={this.on_blur_amount} status={this.state.typed_amount_status} note={this.state.typed_amount_status === 3 ? this.state.typed_amount_error : null} label="Set value to" id="amount-input" style={{textAlign: 'right'}} />
                  </div>
                  <table style={{borderTop: 'solid', borderTopWidth: '1px', borderTopColor: '#c9c9c9', marginTop: '20px', width: '100%', fontFamily: 'Roboto Mono'}}><tbody>
                    <tr>
                      <td>
                        {this.CUSTOMER_FIELD_TO_TITLE[this.state.value_screen_field]}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {this.state.typed_amount}
                      </td>
                    </tr>
                  </tbody></table>
                </div> : null}
              
              {this.state.value_screen_delta_type === 1 ?
                <div style={{marginTop: '30px'}}>
                  <table style={{fontFamily: 'Roboto Mono', marginTop: '20px', margin: 'auto'}}><tbody>
                    <tr>
                      <td>
                        Current value
                      </td>
                      <td style={{textAlign: 'right', paddingRight: '15px'}}>
                        {this.amount_to_formatted_string(this.state.customer[this.state.value_screen_field])}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Add
                      </td>
                      <td>
                        <MyInput onChange={this.on_type_amount} value={this.state.typed_amount} onFocus={this.on_focus_amount} onBlur={this.on_blur_amount} status={this.state.typed_amount_status} note={this.state.typed_amount_status === 3 ? this.state.typed_amount_error : null} label="" id="amount-input" style={{textAlign: 'right'}} />
                      </td>
                    </tr>
                  </tbody></table>
                  <table style={{borderTop: 'solid', borderTopWidth: '1px', borderTopColor: '#c9c9c9', marginTop: '20px', width: '100%', fontFamily: 'Roboto Mono'}}><tbody>
                    <tr>
                      <td>
                        {this.CUSTOMER_FIELD_TO_TITLE[this.state.value_screen_field]}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {this.amount_to_formatted_string(this.state.customer[this.state.value_screen_field] + this.state.amount)}
                      </td>
                    </tr>
                  </tbody></table>
                </div> : null}

                {this.state.value_screen_delta_type === 2 ?
                <div style={{marginTop: '30px'}}>
                  <table style={{fontFamily: 'Roboto Mono', marginTop: '20px', margin: 'auto'}}><tbody>
                    <tr>
                      <td>
                        Current value
                      </td>
                      <td style={{textAlign: 'right', paddingRight: '15px'}}>
                        {this.amount_to_formatted_string(this.state.customer[this.state.value_screen_field])}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Subtract
                      </td>
                      <td>
                        <MyInput onChange={this.on_type_amount} value={this.state.typed_amount} onFocus={this.on_focus_amount} onBlur={this.on_blur_amount} status={this.state.typed_amount_status} note={this.state.typed_amount_status === 3 ? this.state.typed_amount_error : null} label="" id="amount-input" style={{textAlign: 'right'}} />
                      </td>
                    </tr>
                  </tbody></table>
                  <table style={{borderTop: 'solid', borderTopWidth: '1px', borderTopColor: '#c9c9c9', marginTop: '20px', width: '100%', fontFamily: 'Roboto Mono'}}><tbody>
                    <tr>
                      <td>
                        {this.CUSTOMER_FIELD_TO_TITLE[this.state.value_screen_field]}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {this.amount_to_formatted_string(this.state.customer[this.state.value_screen_field] - this.state.amount)}
                      </td>
                    </tr>
                  </tbody></table>
                </div> : null}
                <table><tbody>
                  <tr>
                    <td>
                      <button className="std-button" onClick={() => this.setState({show_value_screen: false})}>
                        Cancel
                      </button>
                    </td>
                    <td>
                      {[0,2].includes(this.state.typed_amount_status) ? 
                        <button className="std-button" onClick={() => this.value_admin_update(this.state.value_screen_field, this.state.amount)}>
                          {this.state.loading ? "Updating..." : "Update"}
                        </button> : null}
                    </td>
                  </tr>
                </tbody></table>
            </div>
          </Overlay> : null}
        
        {this.state.show_status_screen ? 
          <Overlay on_cancel={() => this.setState({show_status_screen: false})} title="Change Customer Status" width="460px">
            <div>

              {[-1,1].map(
                (status, index) =>
                <div className={this.state.selected_status === status ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({selected_status: status})} key={index}>
                  <table><tbody>
                    <tr>
                      <td>
                        <div>
                          <div />
                        </div>
                      </td>
                      <td>
                        <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                          {this.CUSTOMER_STATUS_TO_TITLE[String(status)]}
                        </div>
                        <p style={{color: "grey", fontSize: "14px"}}>
                          {this.CUSTOMER_STATUS_TO_DESCRIPTION[String(status)]}
                        </p>
                      </td>
                    </tr>
                  </tbody></table>
                </div>
              )}
              <button className="std-button" onClick={() => this.admin_update(this.CUSTOMER_STATUS_TO_ADMIN_ACTION[this.state.selected_status])}>
                {this.state.loading ? "Saving..." : "Save"}
              </button>

            </div>
          </Overlay> : null}

        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <h2>
                <span style={{color:'grey'}}>Customer </span>{this.state.customer.name}
              </h2>
            </td>
            <td style={{textAlign: 'right'}}>
              <h2 style={{color: 'grey'}}>
                ${this.amount_to_formatted_string(this.state.customer.balance)}
              </h2>
            </td>
          </tr>
        </tbody></table>

        <table className="admin-top-table"><tbody>
          <tr>
            <td>
              <div className="grey-cap-title">
                STATUS
              </div>
              <div style={{backgroundColor: this.CUSTOMER_STATUS_TO_COLOUR[this.state.customer.status], fontFamily: 'Roboto Mono', color: '#fff', padding: '3px', fontSize: '13px', borderRadius: '4px', width: '80px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', margin: 'auto'}}>
                {this.CUSTOMER_STATUS_TO_WORD[this.state.customer.status]}
              </div>
            </td>
            <td>
              <div className="grey-cap-title">
                BALANCE
              </div>
              <div style={{fontSize: '18px', fontWeight: 'bold', textAlign: 'center'}}>
                ${this.amount_to_formatted_string(this.state.customer.balance)}
              </div>
            </td>
            <td>
              <div className="grey-cap-title">
                CUMULATIVE RECEIPTS
              </div>
              <div style={{fontSize: '18px', fontWeight: 'bold', textAlign: 'center'}}>
                ${this.amount_to_formatted_string(this.state.customer.total_received)}
              </div>
            </td>
          </tr>
        </tbody></table>

        <table style={{marginTop: '20px'}}><tbody>
          <tr>
            <td style={{width: '180px', verticalAlign: 'top'}}>
              <h4>
                <span style={{fontFamily: 'Roboto Mono', color: 'grey'}}>Cust. # {this.state.customer.code}</span>
              </h4>
              {this.state.customer.status < 0 ? 
                <div style={{fontSize: '13px',margin: '10px'}}>
                  Customer is suspended.
                </div> : 
                <table><tbody>
                  {[0,1].map(
                    (status, index) =>
                    <tr className={this.state.customer.status >= status ? "admin-progression-complete" : "admin-progression-incomplete"} key={index}>
                      <td>
                        {index != 0 ? <span style={{fontWeight: 'normal'}}><i className="fa-solid fa-arrow-down"></i><br /></span> : null}{this.state.customer.status >= status ? <i className="fa-regular fa-circle-check"></i> : <i className="fa-regular fa-circle"></i>}
                      </td>
                      <td>
                        {['Issued','Collecting'][index]}
                      </td>
                    </tr>)}
                </tbody></table>}
              <div style={{marginTop: '10px', textAlign: 'center'}}>
                <table><tbody>
                  <tr>
                    <td>
                      <button className="button-blue" onClick={() => location.href = '/customer/'+this.props.customer_code}>
                        See
                      </button>
                    </td>
                    {this.state.customer.status > 0 ? 
                      <td>
                        <button className="button-blue" onClick={() => location.href = '/customer/'+this.props.customer_code+'/edit'}>
                          Edit
                        </button>
                      </td> : null}
                  </tr>
                </tbody></table>
              </div>
            </td>
            <td style={{verticalAlign: 'top'}}>
              <div style={{width: '400px', height: '180px'}}>
                {this.state.payment_history.show ? 
                  <Line options={this.state.payment_history.options} data={this.state.payment_history.data} /> : null}
              </div>
            </td>
          </tr>
        </tbody></table>

        <table style={{marginTop: '20px'}}><tbody>
          <tr>
            <td style={{width: '300px', verticalAlign: 'top', textAlign: 'center'}}>
              <div className="grey-cap-title">
                SHORT URL
              </div>
              <div style={{fontWeight: 'bold', fontSize: '18px', fontFamily: 'Roboto Mono'}}>
                invc.me/{this.state.customer.short_url}
              </div>
              <div>
                <span onClick={(e) => this.click_to_copy(e, 'invc.me/'+this.state.customer.short_url)} className="copy-span">COPY</span>
              </div>
            </td>
            <td style={{width: '250px', verticalAlign: 'top', textAlign: 'center'}}>
              <div className="grey-cap-title">
                STATUS
              </div>
              <div>
                <span style={{fontSize: '15px',fontWeight:'bold'}}>{this.CUSTOMER_STATUS_TO_TITLE[this.state.customer.status]}</span><br />
                <span onClick={(e) => this.setState({show_status_screen: true})} className="copy-span">CHANGE</span>
              </div>
            </td>
          </tr>
        </tbody></table>

        <h3>
          Value
        </h3>
        <table id="customer-amount-tally"><tbody>
          <tr>
            <th>
              Value Type
            </th>
            <th>
              Amount
            </th>
            <th>
              Change
            </th>
          </tr>
          {['total_received','total_added_externally','total_debited','balance'].map((value_type,index) => 
          <tr>
            <td>
              {this.CUSTOMER_FIELD_TO_TITLE[value_type]}
            </td>
            <td style={{borderTop: index === 3 ? 'solid' : 'none', borderTopWidth: '1px', borderTopColor: '#c9c9c9'}}>
              {index === 2 ? '-' : ''}{this.amount_to_formatted_string(this.state.customer[value_type])}
            </td>
            <td>
              {[1,2].includes(index) ? <button onClick={() => this.setState({show_value_screen: true, value_screen_field: value_type, value_screen_value: 0, value_screen_delta_type: 1})} className="button-blue">Change</button> : null}
            </td>
          </tr>)}
        </tbody></table>

        <h3>
          Tags
        </h3>
        <div style={{backgroundColor: "#efefef", borderRadius: '6px', margin: '5px', minHeight: '40px', padding: '5px'}}>
          {this.state.customer.tags.map(
            (tag, index) =>
            <div key={index} style={{padding: '6px', paddingLeft: '12px', paddingRight: '12px', fontSize: '13px', margin: '5px', backgroundColor: '#040dba', color: '#fff', display: 'inline-block', borderRadius: '14px', fontWeight: 'bold', fontFamily: 'Roboto Mono'}}>
              {tag} <i style={{marginLeft: '4px', cursor: 'pointer'}} className="fa-solid fa-circle-xmark" onClick={() => this.remove_tag(index)}></i>
            </div>
          )}
          <div style={{padding: '0px', paddingLeft: '12px', paddingRight: '12px', fontSize: '13px', margin: '0px', display: 'inline-block', borderRadius: '14px'}}>
            <input type="text" style={{backgroundColor: '#efefef', border: 'none', fontFamily: 'Roboto Mono'}} id="tag-input" value={this.state.typed_tag} onChange={this.on_type_tag} onKeyDown={this.on_key_down_tag} placeholder="Type tag here..." />
          </div>
        </div>

        <h3>
          Payments
        </h3>
        <table style={{fontSize: '13px', color: 'grey'}} className="admin-payments-table"><tbody>
          <tr>
            <td>
              Paid at
            </td>
            <td>
              Payor email
            </td>
            <td>
              Status
            </td>
            <td>
              Amount
            </td>
          </tr>
        </tbody></table>
        {this.state.customer.transfers.map(
          (transfer, index) =>
          <div style={{margin: '2px', padding: '3px', backgroundColor: '#efefef', borderRadius: '4px', cursor: 'pointer'}} key={index} onClick={() => this.setState({selected_transfer_index: this.state.selected_transfer_index === index ? -1 : index})}>
            <table className="admin-payments-table" style={{fontSize: '13px', fontFamily: 'Roboto Mono'}}><tbody>
              <tr>
                <td>
                  {this.unix_to_formatted_date(transfer.transferred_at).slice(4,20)}
                </td>
                <td>
                  {transfer.payor_email === null ? "Anonymous" : transfer.payor_email}
                </td>
                <td>
                  {transfer.bank_status}
                </td>
                <td style={{textAlign: 'right'}}>
                  {this.amount_to_formatted_string(transfer.amount)}
                </td>
              </tr>
            </tbody></table>
            {this.state.selected_transfer_index === index ? 
            <div>
              <table className="admin-payments-table" style={{fontSize: '13px', fontFamily: 'Roboto Mono'}}><tbody>
                <tr>
                  <td>
                    {this.unix_to_formatted_time(transfer.transferred_at)}
                  </td>
                  <td>
                    
                  </td>
                  <td>
                    
                  </td>
                  <td style={{textAlign: 'right'}}>
                    AUD
                  </td>
                </tr>
              </tbody></table>
            </div> : null}
          </div>
        )}

      {this.state.customer.transfers.length === 0 && !this.state.customer.paid_by_other_source ?
        <div style={{textAlign: 'center', fontSize: '14px', color: 'grey', margin: '20px'}}>
          There are no payments
        </div> : null}
        
      </div>
    : <div>
      Loading...
    </div>);
  }
}

export default AdminCustomer
