import React from "react"
import PropTypes from "prop-types"
class AccountSessions extends React.Component {
  constructor(props){
    super(props);
    this.RESULTS_PER_PAGE = 10;
    this.state = {
      data: null,
      messages: [],
      loaded: false,
      limit: this.RESULTS_PER_PAGE,
      offset: 0,
      total_count: null,
      results_per_page: this.RESULTS_PER_PAGE, // should be the same as limit
      max_num_pages: 10,
      num_pages: null,
      showing_page: 0
    };

    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.search = this.search.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  componentDidMount(){
    if (![null,undefined].includes(this.props.data)){
      console.log("Loading AccountSessions data:");
      console.log(this.props.data);
      let data = this.props.data;
      this.setState({
        data: data,
        loaded: true,
        total_count: data.count === null ? this.state.total_count : data.count,
        num_pages: Math.ceil((data.count === null ? this.state.total_count : data.count) / this.state.results_per_page),
        showing_page: 0
      });
    } else {
      this.setState({
        loaded: false
      });
    }
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  search(page,limit,req_count){
    console.log("page = "+String(page));
    //let body = {invoice:{},offset:page*this.state.results_per_page,limit:limit,req_count:req_count};
    /*
      For each search term in state, only include it in the body if state indicates its inclusion. Separate by whether it has min/max or exact value.
    */
    // min/max keys:
    //['amount','created_at','due_at'].forEach((k,i) => this.state['include_'+k] ? body['invoice'][k] = {min:this.state[k+'_min'],max:this.state[k+'_max']} : null);
    // exact keys:
    //['billing_email','status'].forEach((k,i) => this.state['include_'+k] ? body['invoice'][k] = this.state[k] : null);
    /*if (Object.keys(body['invoice']).length === 0){
      body['invoice']['amount'] = {min:100,max:1000000000};
    }*/
    //body['tags'] = this.state.include_tags ? this.state.tags : [];
    fetch("/api/account/sessions?offset="+String(page*this.state.results_per_page)+"&limit="+String(limit)+"&no_count="+(req_count ? 0 : 1), {
      method: 'GET',
      credentials: 'include',
      /*headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        offset: page*this.state.results_per_page,
        limit: limit,
        no_count: !req_count
      })*/
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      let data = this.state.data;
      data.sessions = json.data.sessions;
      this.setState({
        //show_results: true,
        data: data,
        total_count: json.data.count === null ? this.state.total_count : json.data.count,
        num_pages: Math.ceil((json.data.count === null ? this.state.total_count : json.data.count) / this.state.results_per_page),
        showing_page: page
      });
    });
  }

  render () {
    console.log("AccountSessions state");
    console.log(this.state);
    return (this.state.loaded ? 
      <div>
        
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <table className="session-table"><tbody>
          <tr style={{color: 'grey'}}>
            <td>
              Created at
            </td>
            <td>
              Session ID
            </td>
            <td>
              Browser
            </td>
            <td>
              Device
            </td>
            <td>
              Platform
            </td>
          </tr>
          {this.state.data.sessions.map(
            (session, index) =>
            <tr key={index}>
              <td>
                {(new Date(session.created_at * 1000)).toLocaleString()}
              </td>
              <td>
                {session.code}
              </td>
              <td>
                {session.browser_name}
              </td>
              <td>
                {session.device_name}
              </td>
              <td>
                {session.platform_name}
              </td>
            </tr>
          )}
        </tbody></table>

        {this.state.total_count != null ? 
          <table className="search-pagination-table"><tbody>
            <tr>
              <td>
                {this.state.showing_page > this.state.max_num_pages / 2 ? 
                  <div onClick={() => this.search(this.state.showing_page - ((this.state.max_num_pages-1)/2) - 1, this.state.limit, false)}>
                    ...
                  </div> : null}
              </td>
              {this.state.num_pages <= this.state.max_num_pages || this.state.showing_page < this.state.max_num_pages / 2 ? 
                Array(Math.min(this.state.num_pages,this.state.max_num_pages)).fill(0).map((_,index) => 
                  <td key={index}>
                    <div onClick={() => this.search(index,this.state.limit,false)} style={this.state.showing_page === index ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                      {index+1}
                    </div>
                  </td>) : 
                Array(this.state.max_num_pages).fill(0).map((_,i) => this.state.showing_page - ((this.state.max_num_pages - 1)/2) + i).map((v,index) => 
                  this.state.num_pages - v < ((this.state.max_num_pages - 1) / 2) - 1 ? null : 
                    <td key={index}>
                      <div onClick={() => this.search(v,this.state.limit,false)} style={this.state.showing_page === v ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                        {v + 1}
                      </div>
                    </td>)}
              <td>
                {this.state.num_pages - this.state.showing_page > this.state.max_num_pages / 2 + 1 ? 
                  <div onClick={() => this.search(Math.max(this.state.showing_page + ((this.state.max_num_pages - 1) / 2) + 1,this.state.max_num_pages),this.state.limit,false)}>
                    ...
                  </div> : null}
              </td>
            </tr>
          </tbody></table> : null}
        
      </div> : 
      <div>
        Loading...
      </div>
    );
  }
}

export default AccountSessions
