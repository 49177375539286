import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"
class AccountCards extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      loaded: false,
      show_card_entry: false,
      sp_state: null,
      messages: [],
      adding_card: false,
      show_card: false,
      selected_index: -1,
      card_window_status: 0,
      deleting_card: false
    };

    this.update_sp_state = this.update_sp_state.bind(this);
    this.open_card_entry = this.open_card_entry.bind(this);
    this.load_securepay = this.load_securepay.bind(this);
    this.tokenise_card = this.tokenise_card.bind(this);
    this.try_if_tokenised = this.try_if_tokenised.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.add_card = this.add_card.bind(this);
    this.delete_card = this.delete_card.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    this.CARD_STATUSES = {'-1':'Suspended by PaySolve','1':'Created','2':'Created and charged','3':'Error encountered in last charge'};

  }

  componentDidMount(){
    this.update_sp_state();
    if (![null,undefined].includes(this.props.data)){
      this.setState({
        data: this.props.data,
        loaded: true
      });
    } else {
      this.setState({
        loaded: false
      });
    }
  }

  update_sp_state(){
    console.log("Update SP State");
    const sp_state = JSON.parse(document.getElementById('securepay-state').innerHTML)
    console.log(sp_state);
    this.setState({
      sp_state: sp_state
    });
    if (sp_state.lastAction === "loadError"){
      this.add_message("Failed to load SecurePay UI component",'danger');
    }
  }

  open_card_entry(){
    this.setState({
      show_card_entry: true
    }, () => {
      this.load_securepay();
      this.update_sp_state();
    });
  }

  load_securepay(){
    document.getElementById('securepay-load').click();
  }

  tokenise_card(){
    this.setState({
      adding_card: true
    });
    document.getElementById('securepay-tokenise').click();
    this.try_if_tokenised();
  }

  try_if_tokenised(){
    setTimeout(() => {
      const sp_state = JSON.parse(document.getElementById('securepay-state').innerHTML);
      if (sp_state.lastAction.includes("tokenise")){
        console.log("ADD CARD");
        this.add_card();
      } else {
        console.log("Trying...");
        this.try_if_tokenised();
      }
    }, 500);
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  add_card(){
    if (this.state.sp_state === null || !["tokeniseSuccess","tokeniseError"].includes(this.state.sp_state.lastAction)){
      this.add_message("Card tokenisation failed.",'danger');
      return null;
    } else if (this.state.sp_state.lastAction === "tokeniseError"){
      this.add_message("Card tokenisation encountered error.",'danger');
      return null;
    }
    
    let sp_state = JSON.parse(document.getElementById('securepay-state').innerHTML)
    sp_state.lastAction = "addingCard";
    document.getElementById('securepay-state').innerHTML = JSON.stringify(sp_state);

    fetch("/api/cards/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        token: this.state.sp_state.tokenisedCard.token
      })
    }).then( (response) => {
      if (response.ok || true){
        return response.json();
      }
      this.add_message("Could not successfully add a Card.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        sp_state.lastAction = "addedCard";
        document.getElementById('securepay-state').innerHTML = JSON.stringify(sp_state);
        this.props.on_successful_update(['company','billing']);
        this.add_message("Successfully added Card ending with "+json.card.last_digits+".",'success');
      } else {
        sp_state.lastAction = "failedAddedCard";
        document.getElementById('securepay-state').innerHTML = JSON.stringify(sp_state);
        this.add_message("Encountered error adding Card." + (json.error === undefined ? "" : " The following error was encountered: "+json.error),'danger');
      }
      this.setState({
        adding_card: false,
        show_card_entry: false
      });
    });
  }

  delete_card(){
    this.setState({
      deleting_card: true
    });
    fetch("/api/cards/delete", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        code: this.state.data.cards[this.state.selected_index].customer_code
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Could not successfully add a Card.",'danger');
      this.setState({
        deleting_card: false,
        show_card_entry: false
      });
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.props.on_successful_update('company');
        this.add_message("Successfully removed Card",'success');
      } else {
        this.add_message("Encountered error adding Card.",'danger');
      }
      this.setState({
        deleting_card: false,
        show_card: false
      });
    });
  }

  componentDidUpdate(prev_props){
    if (prev_props.data !== this.props.data){
      this.componentDidMount();
    }
  }

  render () {
    console.log("Account Cards");
    console.log(this.state);
    return (this.state.loaded ? 
      <div>
        {this.state.show_card_entry ? 
          <Overlay on_cancel={() => this.setState({show_card_entry: false})} title="Add a Card" width="400px">
            <div>
              <p style={{fontSize: '13px', color: 'grey'}}>PaySolve uses SecurePay, a fully PCI DSS-compliant payment gateway owned by the Australian federal government, to receive, store and process card information.</p>
              <div id="securepay-ui-container" style={{display: 'table', margin: 'auto'}}></div>
              <button onClick={() => this.state.adding_card ? null : this.tokenise_card()} className="std-button">
                {this.state.adding_card ? "Adding..." : "Add"}
              </button>
            </div>
          </Overlay> : null}
        
        {this.state.show_card ? 
          <Overlay on_cancel={() => this.setState({show_card: false, card_window_status: 0})} title={"Card ending in "+this.state.data.cards[this.state.selected_index].last_digits} width="480px">
            <div style={{minHeight: '240px', position: 'relative'}}>
              {[
                <div>
                  <table className="account-user-table"><tbody>
                    <tr>
                      <td>
                        Status
                      </td>
                      <td>
                        {this.CARD_STATUSES[String(this.state.data.cards[this.state.selected_index].status)]}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Last digits
                      </td>
                      <td>
                        {this.state.data.cards[this.state.selected_index].last_digits}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Scheme
                      </td>
                      <td>
                        {this.state.data.cards[this.state.selected_index].scheme}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Expiry
                      </td>
                      <td>
                        {this.state.data.cards[this.state.selected_index].expiry_month < 10 ? '0' : ''}{this.state.data.cards[this.state.selected_index].expiry_month} / {this.state.data.cards[this.state.selected_index].expiry_year}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Created at
                      </td>
                      <td>
                        {(new Date(this.state.data.cards[this.state.selected_index].created_at)).toString().slice(4,15)}
                      </td>
                    </tr>
                  </tbody></table>
                  <button style={{backgroundColor: 'red'}} onClick={() => this.setState({card_window_status: 1})} className="std-button">
                    Delete
                  </button>
                </div>,
                <div style={{position: 'absolute', height: '100%'}}>
                  <p className="general-p" style={{paddingLeft: '20px', paddingRight: '20px', textAlign: 'center'}}>
                    Are you sure you wish to <b>permanently delete</b> the {this.state.data.cards[this.state.selected_index].scheme} Card ending in <b>{this.state.data.cards[this.state.selected_index].last_digits}</b>?
                  </p>
                  {this.state.deleting_card ? 
                  <div>
                    Deleting Card...
                  </div> : 
                  <div style={{position: 'absolute', bottom: '0px', left: '0px'}}>
                    <button className="std-button" onClick={() => this.delete_card()}>
                      Yes
                    </button>
                    <button className="std-button" onClick={() => this.setState({card_window_status: 0})}>
                      No
                    </button>
                  </div>}
                </div>
              ][this.state.card_window_status]}
            </div>
          </Overlay> : null}
        
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}
        
        {this.state.data.cards.length === 0 ?
        <div className={"flash flash-info"}>
          <table><tbody>
            <tr>
              <td style={{fontSize: '18px'}}>
                <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON['info']}></i>
              </td>
              <td>
                Billing is not enabled for your Organisation. You will need to add at least one Card to enable billing. <a href="/" target="_blank">More on Billing</a>
              </td>
            </tr>
          </tbody></table>
        </div> : null}

        {this.state.data.cards.map(
          (card, index) =>
          <div key={index} className="account-user-card" onClick={() => this.setState({show_card: true, selected_index: index})}>
            <div style={{fontSize: '18px', fontWeight: 'bold'}}>
              Card ending in {card.last_digits}
            </div>
            <table><tbody>
              <tr>
                <td>
                  Expiry
                </td>
                <td>
                  {[null,'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'][card.expiry_month]} {card.expiry_year}
                </td>
              </tr>
              <tr>
                <td>
                  Scheme
                </td>
                <td>
                  {card.scheme}
                </td>
              </tr>
              <tr>
                <td>
                  Total charged
                </td>
                <td>
                  ${this.amount_to_formatted_string(card.total_charged)}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}
        <div className="account-user-card" style={{textAlign: 'center', fontSize: '13px', color: 'grey'}} onClick={this.open_card_entry}>
          <i className="fa-solid fa-plus"></i> Add Card
        </div>

        <div style={{visibility: 'hidden'}}>
          <button onClick={this.update_sp_state} id="sync-sp-react">
            Update state
          </button>
        </div>
      </div> : <div>
        Loading...
      </div>);
  }
}

export default AccountCards
